import { NgModule }                    from '@angular/core';
import { CommonModule }                from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule }               from '../../../commons/commons.module';
import { GroupesRoutingModule }        from './groupes.routing';

import { ComponentsModule } from '../../../components/components.module';

import { GroupeService }           from './groupe.service';
import { BreadcrumbsService }       from 'ng2-breadcrumbs';

import { GroupesComponent }        from './groupes.component';
import { CreerModifierGroupeComponent } from './creer-modifier-groupe.component';

@NgModule({
  declarations: [
    GroupesComponent,
    CreerModifierGroupeComponent,
  ],
  exports: [
    GroupesComponent,
    CreerModifierGroupeComponent,
  ],
  imports: [
    CommonModule,
    GroupesRoutingModule,
    CommonsModule,
    FormsModule,
    ReactiveFormsModule,
    ComponentsModule,
  ],
  providers: [
    GroupeService,
  ],
  bootstrap: []
})
export class GroupesModule {
  public constructor(private breadcrumbService: BreadcrumbsService) {
    breadcrumbService.store([{label: 'Gestion des groupes', url: '/administrateur/groupes', params: []}]);
  }
}
