import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';

@Component({
  selector: 'in-haut-de-page',
  templateUrl: './haut-de-page.component.html',
  styles: [
    '.to-top { opacity: .7; position: fixed; top: inherit; right: -4px; bottom: 0; z-index: 101; }',
  ],
})
export class HautDePageComponent implements OnInit {

  public urlHautDePage: string;
  public visible: boolean;

  constructor(
    private router: Router) {
  }

  ngOnInit() {

    window.addEventListener('scroll', (e) => {
      const scroll = window.scrollY || window.pageYOffset || document.documentElement.scrollTop;
      this.visible = scroll > 32;
    });

    this.router.events
      .filter(event => event instanceof NavigationEnd)
      .map(() => this.router.routerState.root.firstChild)
      .flatMap(r => r.data)
      .subscribe(data => {
        this.urlHautDePage = this.router.url.replace(/#.*/, '') + '#hautDePage';
      });
  }

}
