import { Component, Input, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";
import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/utilisateur";
import { ListeFiltreeComponent } from "../../commons/components/liste-filtree.component";
import { SuiviDemandeTitreValideAnswer } from "../dto/suivi-demande-titre-valide-answer";
import { SuiviDemandeTitreValideData } from "../dto/suivi-demande-titre-valide-data";

@Component({
  selector: "in-demande-titre-valide",
  templateUrl: "./demande-titre-valide.component.html",
})
export class DemandeTitreValideComponent
  extends ListeFiltreeComponent
  implements OnInit, OnDestroy
{
  @Input()
  suiviDemandeTitreValide: SuiviDemandeTitreValideAnswer;

  data: SuiviDemandeTitreValideData[];
  rechercheSoumise = false;
  public utilisateur: Utilisateur = null;

  utilisateurSubscription: Subscription;

  public constructor(private authentificationService: AuthentificationService) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public extraireDonnees(e, settings, json) {
    // This is intentional
  }

  public initialiserFiltre(): void {
    //pas de filres
    this.filtreRequis = false;
  }

  public initialiserColumnsOrder(): void {
    // Pas de tri par défaut, on conserve le tri spécifique défini dans le composant parent (Etranger en premier, puis tri croissant des codes postaux)
    this.optionsListeFiltree.ordering = false;
  }

  public lancerRecherche(): void {
    //on ne filtre pas donc pas de recherche
    this.reinitDataTable();

    // On met à jour les commandes
    this.data = this.suiviDemandeTitreValide.data;
    this.rechercheSoumise = true;
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
