import { Component, OnDestroy, OnInit } from "@angular/core";

import { FormBuilder, Validators } from "@angular/forms";
import { ListeFiltreeComponent } from "../../commons/components/liste-filtree.component";
import { Doublon } from "./doublon";
import { DoublonService } from "./doublon.service";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/utilisateur";
import { CsvService } from "../../commons/csv.service";
import { SpinnerGeneralService } from "../../spinner-general.service";

import { Nombre } from "../../commons/dtos/nombre";
import { libellesActions } from "./doublons.constants";

import * as moment from "moment";
import { Subscription } from "rxjs";

declare var $: any;

@Component({
  selector: "in-doublons",
  templateUrl: "./doublons.component.html",
})
export class DoublonsComponent
  extends ListeFiltreeComponent
  implements OnInit, OnDestroy
{
  public utilisateur: Utilisateur = null;
  public libellesActions = libellesActions;
  public decision: string = "";
  public dateDebut: Date;
  public dateFin: Date;
  public doublons: Doublon[] = [];

  public doublonsOrder = [];
  public rechercheSoumise: boolean = false;

  utilisateurSubscription: Subscription;

  public constructor(
    private doublonService: DoublonService,
    private csvService: CsvService,
    private formBuilder: FormBuilder,
    private authentificationService: AuthentificationService,
    private spinner: SpinnerGeneralService
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public lancerRecherche(): void {
    console.log(`Validation de la recherche`);
    this.spinner.show();
    this.doublonService
      .doublons(this.filtresForm.value)
      .subscribe((doublons) => {
        // Il faut mettre à jour la table, donc on détruit
        this.dataTable.destroy();
        this.dataTable = null;

        // On met à jour les doublons
        this.doublons = doublons;

        this.rechercheSoumise = true;

        //On masque le spinner
        this.spinner.hide();
      });
  }

  public tout(traite: boolean): void {
    if (this.doublons) {
      for (const doublon of this.doublons) {
        if (!doublon.decision) {
          doublon.selectionne = traite;
        }
      }
    }
  }

  public extraireDonnees(e, settings, json) {
    // This is intentional
  }

  /* Avant que le tableau soit re géréné on réinitialise l'ordre des éléments (car peut potentiellement changer) */
  public onPreDrawCallback(settings) {
    this.doublonsOrder = Array();
  }

  /* A chaque ligne générée on stocke comme élément suivant le numéro de l'identifiant en doublon (on connait l'ordre des doublons comme ça ) */
  public onRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
    this.doublonsOrder[this.doublonsOrder.length] = aData[15];
  }

  public selectionnerDoublon(doublon: Doublon, selectionne: boolean): void {
    console.log(
      `[DoublonComponent] Changement d'état de sélection d'un doublon.`,
      doublon,
      selectionne
    );
    doublon.selectionne = selectionne;
    this.doublons
      .filter((d) => d.idDemande === doublon.idDemande)
      .forEach((df) => (df.selectionne = selectionne));

    this.refreshDataTableSorts();
  }

  public prendreDecision(doublon: Doublon, decision: string): void {
    doublon.decision = decision;
    doublon.selectionne = false;
    this.doublonService.saveDoublon(doublon);
  }

  public poursuivre(doublon: Doublon): void {
    this.prendreDecision(doublon, "POURSUIVRE");
  }

  public annuler(doublon: Doublon): void {
    this.prendreDecision(doublon, "ANNULER");
  }

  public valider(): void {
    // tableau avec les idDemande pour ne pas doubler les requêtes
    const dedoublonnageIdDemande = new Array<number>();
    if (this.decision !== "" && this.doublons) {
      let nombreDoublonsSelectionnes = 0;
      for (const doublon of this.doublons) {
        console.log(
          "[DoublonsComponent][Valider] Doublon selectionne ",
          doublon.idDemande,
          doublon.selectionne
        );
        if (doublon.selectionne) {
          if (dedoublonnageIdDemande.indexOf(doublon.idDemande) < 0) {
            nombreDoublonsSelectionnes++;
            console.log(
              "[DoublonsComponent][Valider] Doublon envoye ",
              doublon.idDemande
            );
            this.prendreDecision(doublon, this.decision);
            dedoublonnageIdDemande.push(doublon.idDemande);
          }
        }
      }

      this.lancerRecherche();
    }
  }

  showModalAucunDoublonCoche(): void {
    $("#popinAucunDoublonCoche").dialog();
  }

  showModalAucuneActionCochee(): void {
    $("#popinAucuneActionCochee").dialog();
  }

  public initialiserFiltre(): void {
    // Au moins un filtre est requis
    this.filtreRequis = true;

    // On va chercher les valeurs des filtres (en session par exemple)
    const values: any = this.getFiltreValues();

    // On rempli les valeurs par défaut
    const commandeValue: string = values ? values.commande : null;
    const statutValue: string = values ? values.statut : null;
    const typeTitre: string = values ? values.typeTitre : null;
    const action: string = values ? values.action : "TRAITER";
    const raisonSociale: string = values ? values.raisonSociale : null;

    // On créé le formulaire
    this.filtresForm = this.formBuilder.group({
      commande: [commandeValue],
      statut: [statutValue],
      typeTitre: [typeTitre],
      action: [action],
      raisonSociale: [
        raisonSociale,
        Validators.compose([
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘’“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ]),
      ],
    });
  }

  public initialiserColumnsOrder(): void {
    this.optionsListeFiltree.order = [[2, "asc"]];
  }

  public print(): void {
    window.print();
  }

  public download(): void {
    const data: any[][] = [];

    /* Tableau temporaire des doublons triés, pour ne pas toucher a this.doublons */
    const orderedDoublon: Doublon[] = Array();

    /* On parcourt les doublons dans l'ordre ou ils sont affichés */
    for (const oc of this.doublonsOrder) {
      for (const com of this.doublons) {
        /* On retrouve le doublon correspondant pour le placer comme élément suivant de notre tableau ordonné */
        if (com.idDemande === Number(oc)) {
          orderedDoublon[orderedDoublon.length] = com;
        }
      }
    }

    if (this.utilisateur.personnePhysique) {
      data.push([
        "Commande",
        "Numéro Identifiant",
        "Nom d'usage",
        "Nom de naissance",
        "Prénom",
        "Date de naissance",
        "Lieu de naissance",
        "Mention",
        "Sous-mention",
        "Organisme en doublon",
        "Numéro Identifiant associé",
        "Actions",
      ]);
    } else {
      data.push([
        "Commande",
        "Numéro Identifiant",
        "Raison sociale",
        "Immatriculation",
        "Organisme en doublon",
        "Numéro Identifiant associé",
        "Actions",
      ]);
    }

    if (this.utilisateur.personnePhysique) {
      for (const doublon of orderedDoublon) {
        data.push([
          doublon.numeroCommande,
          new Nombre(doublon.numeroId),
          doublon.nomUsage,
          doublon.nomNaissance,
          doublon.prenom,
          doublon.dateNaissance
            ? doublon.dateNaissance.substring(6, 10) +
              "-" +
              doublon.dateNaissance.substring(3, 5) +
              "-" +
              doublon.dateNaissance.substring(0, 2)
            : "",
          doublon.lieuNaissance,
          doublon.typeTitre === null ? "" : doublon.typeTitre.valeur,
          doublon.sousMention,
          doublon.doublonOrganisme,
          new Nombre(doublon.numeroIdAssocie),
          doublon.decision
            ? doublon.decision === "ANNULER"
              ? "Demande annulée"
              : "Demande poursuivie"
            : "",
        ]);
      }
    } else {
      for (const doublon of orderedDoublon) {
        data.push([
          doublon.numeroCommande,
          new Nombre(doublon.numeroId),
          doublon.raisonSociale,
          doublon.immatriculation,
          doublon.doublonOrganisme,
          new Nombre(doublon.numeroIdAssocie),
          doublon.decision
            ? doublon.decision === "ANNULER"
              ? "Demande annulée"
              : "Demande poursuivie"
            : "",
        ]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `doublons_${horodateFichierCsv}.csv`
    );
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
