import { Component, OnInit } from "@angular/core";

import { AideService } from "./aide.service";
import { LienAide } from "./lienAide";

@Component({
  selector: "in-aide",
  templateUrl: "./aide.component.html",
})
export class AideComponent implements OnInit {
  public liensModesOperatoiresOrganismePP: LienAide[];
  public liensModesOperatoiresOrganismePM: LienAide[];
  public liensModesOperatoiresBeneficiairePP: LienAide[];
  public liensModesOperatoiresBeneficiairePM: LienAide[];
  public liensModelesCourrier: LienAide[];

  constructor(private aideService: AideService) {}

  ngOnInit() {
    this.aideService.liens().subscribe((liens) => {
      this.liensModesOperatoiresOrganismePP = liens.filter(
        (a) => a.categorie === "ORGANISME_PERSONNE_PHYSIQUE"
      );
      this.liensModesOperatoiresOrganismePM = liens.filter(
        (a) => a.categorie === "ORGANISME_PERSONNE_MORALE"
      );
      this.liensModesOperatoiresBeneficiairePP = liens.filter(
        (a) => a.categorie === "BENEFICIAIRE_PERSONNE_PHYSIQUE"
      );
      this.liensModesOperatoiresBeneficiairePM = liens.filter(
        (a) => a.categorie === "BENEFICIAIRE_PERSONNE_MORALE"
      );
      this.liensModelesCourrier = liens.filter(
        (a) => a.categorie === "MODELE_COURRIER"
      );
    });
  }
}
