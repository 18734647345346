import { Component, OnInit } from '@angular/core';

import { SpinnerService } from './spinner.service';

@Component({
  selector: 'in-spinner',
  templateUrl: './spinner.component.html'
})
export class SpinnerComponent implements OnInit {
  visible: boolean;

  constructor(private spinnerService: SpinnerService) {
  }

  ngOnInit() {
    this.spinnerService.visible.subscribe(v => {
      this.visible = v.show;
    });
  }

}
