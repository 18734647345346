import { FormControl } from '@angular/forms';
import { NG_VALIDATORS } from '@angular/forms';
import * as moment from 'moment';
import { Directive } from '@angular/core';

export function validDate(c: FormControl): { [key: string]: boolean } {
  if (!c.value) {
    return null;
  }
  const value = <moment.Moment> c.value;
  return value.isValid() ? null : {
    validDate: false,
  };
}

@Directive({
  selector: '[validDate][formControlName],[validDate][formControl],[validDate][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useValue: validDate, multi: true }
  ],
})
export class ValidDateValidator {}
