import { NgModule }                from '@angular/core';
import { Routes, RouterModule }    from '@angular/router';
import { UtilisateursComponent }   from './utilisateurs.component';
import { CanActivateADroitGuard }  from '../../authentification/a-droit.guard';

export const routes: Routes = [
  {
    path: '',
    component: UtilisateursComponent
  },
  {
    path: 'groupes',
    loadChildren: './groupes/groupes.module#GroupesModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      breadcrumb: 'Gestion des groupes'
    }
  },
  {
    path: 'profils',
    loadChildren: './profils/profils.module#ProfilsModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      breadcrumb: 'Gestion des utilisateurs'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class UtilisateursRoutingModule { }
