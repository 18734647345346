import { AfterViewChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { Utilisateur } from "../../authentification/utilisateur";
import { Commande } from "./commande";
import { CommandeService } from "./commande.service";
import { Erreur } from "./erreur";

import { AuthentificationService } from "../../authentification/authentification.service";
import { dataTablesOptions } from "../../commons/configuration";
import { CsvService } from "../../commons/csv.service";
import { PropertiesService } from "../../commons/properties.service";

import { BreadcrumbsService } from "ng2-breadcrumbs";

import * as moment from "moment";
import { Subscription } from "rxjs";

declare var $: any;

@Component({
  selector: "in-detail-commande",
  templateUrl: "./detail.component.html",
})
export class DetailComponent implements OnInit, AfterViewChecked, OnDestroy {
  public erreurs: Erreur[];
  public commande: Commande;
  public titre: string;
  public utilisateur: Utilisateur = null;

  private dataTable: DataTables.DataTables = null;

  utilisateurSubscription: Subscription;

  public constructor(
    private commandeService: CommandeService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private title: Title,
    private csvService: CsvService,
    private authentificationService: AuthentificationService,
    private props: PropertiesService
  ) {}

  public ngOnInit(): void {
    const numeroCommande = this.route.snapshot.params["id"];

    this.commandeService.erreurs(numeroCommande).subscribe((erreurs) => {
      this.erreurs = erreurs;
    });

    this.commandeService.detail(numeroCommande).subscribe((commande) => {
      this.commande = commande;
      const title = this.getNameForNumberCommande(
        commande.nomFichierIntegration
      );
      this.breadcrumbService.store([
        { label: "Suivi des commandes", url: "/commandes", params: [] },
        { label: title, url: "/commandes/[0-9]*", params: [] },
      ]);
    });

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public print(): void {
    window.print();
  }

  public download(): void {
    const data: any[][] = [];

    if (this.utilisateur.personnePhysique) {
      data.push([
        "Numéro de ligne",
        "Motif d'erreur",
        "Numéro identifiant",
        "Nom",
        "Prénom",
      ]);
      for (const erreur of this.erreurs) {
        data.push([
          erreur.numeroLigne,
          erreur.motifs,
          erreur.numeroIdentifiant,
          erreur.nom,
          erreur.prenom,
        ]);
      }
    } else {
      data.push([
        "Numéro de ligne",
        "Motif d'erreur",
        "Numéro identifiant",
        "Raison sociale",
      ]);
      for (const erreur of this.erreurs) {
        data.push([
          erreur.numeroLigne,
          erreur.motifs,
          erreur.numeroIdentifiant,
          erreur.raisonSociale,
        ]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `erreurs_${this.commande.numeroCommande}_${horodateFichierCsv}.csv`
    );
  }

  public ngAfterViewChecked(): void {
    // Si on a les données et que le tableau n'est pas chargé
    if (this.erreurs && !this.dataTable) {
      // Initialisation d'un composant jQuery
      this.dataTable = $("#details").DataTable(dataTablesOptions);
    }
  }

  private getNameForNumberCommande(id: string): string {
    return "Détail de la commande " + id;
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
