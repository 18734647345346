import { Injectable } from '@angular/core';
import { CodePostal } from './dtos/code-postal';
import { Nombre } from './dtos/nombre';
import * as moment from 'moment';

@Injectable()
export class CsvService {

  private createRow(data: any[]): string {
    for (let j = 0; j < data.length; j++) {
      if (typeof data[j] === 'string') {
        const r = data[j].replace(/"/g, '""');
        if (r.search(/("|,|\n)/g) >= 0) {
          data[j] = '"' + r + '"';
        }
      } else if (data[j] instanceof Date) {
        data[j] = moment(data[j]).format('YYYY-MM-DD');
      } else if (data[j] instanceof CodePostal) {
        data[j] = '"=""' + data[j].code + '"""';
      } else if (data[j] instanceof Nombre) {
        data[j] = '"=""' + (data[j].nombre === null ? '' : data[j].nombre) + '"""';
      } else if (typeof data[j] === 'number') {
      } else if (!data[j]) {
        data[j] = '';
      }
    }
    return data.join(';') + '\n';
  }

  public creerTelecharger(data: any[][], filename: string): void {
    let csv = '';
    for (const d of data) {
        csv += this.createRow(d);
    }
    return this.telecharger(csv, filename);
  }

  public telecharger(csv: string, filename: string): void {

    if (navigator.msSaveBlob) { // IE 10+
      const blob = new Blob(['\ufeff', csv], { type: 'text/csv' });
      navigator.msSaveBlob(blob, filename);
    } else {
      const link = document.createElement('a');
      if (link.download !== undefined) {
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    }
  }
}
