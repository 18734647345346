import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { emailValidator } from "../commons/validators/email.validator";
import { AuthentificationService } from "./authentification.service";
import { Utilisateur } from "./utilisateur";

@Component({
  selector: "in-login-oublie",
  templateUrl: "./login-oublie.component.html",
})
export class LoginOublieComponent implements OnInit {
  public utilisateur: Utilisateur;

  public soumis: boolean = false;
  public succes: boolean = true;
  public emailInconnu: boolean = false;
  public compteBloque: boolean = false;

  public loginOublieForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.loginOublieForm = this.formBuilder.group({
      email: ["", Validators.compose([Validators.required, emailValidator()])],
    });
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();

    if (this.loginOublieForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.loginOublieForm.controls) {
        if (typeof control === "string") {
          this.loginOublieForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  valider(): void {
    this.emailInconnu = false;
    this.compteBloque = false;

    this.authentificationService
      .loginOublie(this.loginOublieForm.controls["email"].value)
      .then((succes) => {
        this.soumis = true;
        this.succes = succes;
      })
      .catch((err) => {
        this.soumis = true;
        this.succes = false;
        if (err.status === 404 && err._body === "MSG_ERR_EMAIL_INCONNU") {
          this.emailInconnu = true;
        }
        if (err.status === 401 && err._body === "COMPTE_BLOQUE") {
          this.compteBloque = true;
        }
      });
  }
}
