import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { AuthentificationService } from './authentification/authentification.service';

import { DROITS } from './authentification/droits.constants';

@Component({
  selector: 'in-home-app',
  template: `
          <div class="container">
          </div>
        `
})

export class HomeComponent implements OnInit {

  constructor(private authentificationService: AuthentificationService, private router: Router) {
  }

  ngOnInit(): void {
    if (this.authentificationService.getDroitSync(DROITS.TRANSFERT)) {
      this.router.navigate(['transfert']);
    } else if (this.authentificationService.getDroitSync(DROITS.COMMANDES)) {
      this.router.navigate(['commandes']);
    } else if (this.authentificationService.getDroitSync(DROITS.DEMANDES)) {
      this.router.navigate(['suivi/demandes']);
    } else if (this.authentificationService.getDroitSync(DROITS.DOUBLONS)) {
      this.router.navigate(['suivi/doublons']);
    } else if (this.authentificationService.getDroitSync(DROITS.BENEFICIAIRE)) {
      this.router.navigate(['beneficiaire']);
    } else if (this.authentificationService.getDroitSync(DROITS.STATNATIONALES)) {
      this.router.navigate(['statnationales']);
    } else if (this.authentificationService.getDroitSync(DROITS.STATISTIQUES)) {
      this.router.navigate(['statistiques']);
    } else if (this.authentificationService.getDroitSync(DROITS.ADMINISTRATEUR)) {
      this.router.navigate(['administrateur']);
    } else {
      this.router.navigate(['authentification']);
    }
  }

}
