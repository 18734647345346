import { NgModule }                from '@angular/core';
import { Routes, RouterModule }    from '@angular/router';

import { AdministrateurComponent } from './administrateur.component';
import { DonneesComponent }        from './donnees/donnees.component';
import { RessourcesComponent }     from './ressources/ressources.component';

import { CanActivateADroitGuard }  from '../authentification/a-droit.guard';
import { DROITS }                  from '../authentification/droits.constants';

export const routes: Routes = [
  {
    path: '',
    component: AdministrateurComponent
  },
  {
    path: 'donnees',
    component: DonneesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Mes données',
      breadcrumb: 'Mes données',
      menu: 'mesdonnees',
      droit: DROITS.MESDONNEES,
    },
  },
  {
    path: 'ressources',
    component: RessourcesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Ressources Organisme',
      breadcrumb: 'Ressources Organisme',
      menu: 'ressourcesorganisme',
      droit: DROITS.ADMINISTRATEUR,
    },
  },
  {
    path: 'utilisateurs',
    loadChildren: './utilisateurs/utilisateurs.module#UtilisateursModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      breadcrumb: 'Administration'
    }
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class AdministrateurRoutingModule { }
