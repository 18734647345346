import { Component, Input } from '@angular/core';

@Component({
  selector: 'in-tab',
  template: `
  <div [class.undisplayable]="!active" class="in-tab">
      <ng-content></ng-content>
  </div>
  `,
  styles: [
  ],
})

export class TabComponent {
    active: boolean;
    past: boolean;
    @Input() titre: string;
}
