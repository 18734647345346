import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonsModule } from '../commons/commons.module';
import { AuthentificationToolsModule } from '../authentification/authentification-tools.module';

import { CommandeService } from './commandes/commande.service';
import { DoublonService } from './doublons/doublon.service';
import { DemandeService } from './demandes/demande.service';
import { SuiviRoutingModule } from './suivi.routing';
import { CommandesComponent } from './commandes/commandes.component';
import { DetailComponent } from './commandes/detail.component';
import { MenuComponent } from './menu.component';
import { DoublonsComponent } from './doublons/doublons.component';
import { DemandesComponent } from './demandes/demandes.component';
import { DetailDemandeComponent } from './demandes/detaildemande.component';

@NgModule({
	exports: [CommandesComponent, DetailComponent, MenuComponent, DoublonsComponent, DemandesComponent, DetailDemandeComponent],
	imports: [CommonModule, SuiviRoutingModule, CommonsModule, FormsModule, ReactiveFormsModule, AuthentificationToolsModule],
	declarations: [CommandesComponent, MenuComponent, DetailComponent, DoublonsComponent, DemandesComponent, DetailDemandeComponent],
	providers: [CommandeService, DoublonService, DemandeService],
})
export class SuiviModule {}
