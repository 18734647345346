import { NgModule }      from '@angular/core';
import { AccessibiliteComponent } from './accessibilite.component';
import { RouterModule }       from '@angular/router';

@NgModule({
  declarations: [AccessibiliteComponent],
  exports: [AccessibiliteComponent],
  imports: [RouterModule],
  bootstrap: []
})
export class AccessibiliteModule {  }
