import { Injectable } from '@angular/core';
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthentificationService } from './authentification.service';

@Injectable()
export class CanActivateADroitGuard implements CanActivate {
	constructor(private authService: AuthentificationService, private router: Router) {}

	canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
		if (!route.data['droit']) {
			console.log(`[CanActivateViaAuthGuard] Pas de droit defini pour ${state.url}`);
			return true;
		} else {
			console.log(`[CanActivateViaAuthGuard] Droit defini pour ${state.url} : ${route.data['droit']}`);
		}

		const droit = this.authService.getDroitSync(route.data['droit']);
		console.log('[CanActivateViaAuthGuard] Verification du droit.', droit);

		if (!droit) {
			console.log(`[CanActivateViaAuthGuard] Redirection vers la home.`);
			this.router.navigate(['/']);
		}

		return droit;
	}
}
