
export const dataTablesOptions: DataTables.Settings = {
  paging: true,
  pagingType: 'full_numbers',
  ordering: true,
  autoWidth: false,
  info: true,
  searching: false,
  pageLength: 20,
  lengthMenu: [20, 50, 100],
  stateSave: true,
  language: {
    url: '/assets/language/dataTables.french.json',
  },
};

export const titreSite = 'Portail Carte Mobilité Inclusion Organisme';
export const nomDomaineSite = 'organisme.carte-mobilite-inclusion.fr';

export const listeDroits = [
  { code: 'TRANSFERT', libelle: 'Transfert fichier', visible: true, defaut: false },
  { code: 'COMMANDE', libelle: 'Suivi des commandes', visible: true, defaut: false },
  { code: 'DOUBLON', libelle: 'Gestion des doublons', visible: true, defaut: false },
  { code: 'DEMANDE', libelle: 'Gestion des demandes', visible: true, defaut: false },
  { code: 'BENEFICIAIRE', libelle: 'Bénéficiaires', visible: true, defaut: false },
  { code: 'STATISTIQUES', libelle: 'Statistiques', visible: true, defaut: false },
  { code: 'ADMINISTRATEUR', libelle: 'Administration', visible: true, defaut: false },
  { code: 'MESDONNEES', libelle: 'Mes informations', visible: false, defaut: false },
  { code: 'CONTACT', libelle: 'Contact', visible: false, defaut: true },
  { code: 'AIDE', libelle: 'Aide', visible: false, defaut: true },
  { code: 'FAQ', libelle: 'FAQ', visible: false, defaut: true },
];
