import { AfterViewChecked, Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import * as moment from "moment";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/utilisateur";
import { ListeFiltreeComponent } from "../../commons/components/liste-filtree.component";
import { dataTablesOptions } from "../../commons/configuration";
import { CsvService } from "../../commons/csv.service";
import { Nombre } from "../../commons/dtos/nombre";
import { MomentPipe } from "../../commons/pipes/moment.pipe";
import { validDate } from "../../commons/validators/valid-date.validator";
import { DemandeTitre } from "./demande-titre";
import { DemandeService } from "./demande.service";

declare var $: any;

@Component({
  selector: "in-demandes",
  templateUrl: "./demandes.component.html",
})
export class DemandesComponent
  extends ListeFiltreeComponent
  implements AfterViewChecked, OnInit, OnDestroy
{
  public dateDebut: Date;
  public dateFin: Date;
  public demandes: DemandeTitre[] = [];
  public utilisateur: Utilisateur = null;

  public demandeValue: string;
  public nomValue: string;
  public raisonSocialeValue: string;
  public immatriculationValue: string;
  public typeTitreValue: string;
  public statutDemandeValue: string;
  public statutTitreValue: string;
  public finValiditeValue: moment.Moment;
  public rechercheSoumise: boolean = false;

  utilisateurSubscription: Subscription;

  public constructor(
    private demandeService: DemandeService,
    private authentificationService: AuthentificationService,
    private momentPipe: MomentPipe,
    private formBuilder: FormBuilder,
    private csvService: CsvService
  ) {
    super();
  }

  public ngOnInit(): void {
    super.ngOnInit();
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  public initOptions() {
    this.optionsListeFiltree = Object.assign({}, dataTablesOptions);

    this.optionsListeFiltree.processing = true;
    this.optionsListeFiltree.createdRow = function (row) {
      $(row).addClass("toDelete");
    };
    this.optionsListeFiltree.columnDefs = [
      {
        targets: 0,
        data: "identifiantBeneficiaire",
      },
      {
        targets: 1,
        data: "nom",
      },
      {
        targets: 2,
        data: "prenom",
      },
      {
        targets: [3],
        data: "mention",
      },
      {
        targets: 4,
        data: "raisonSociale",
      },
      {
        targets: 5,
        data: "immatriculation",
      },
      {
        targets: 6,
        data: "statutProduction",
      },
      {
        targets: 7,
        data: "statutTitre",
        width: "65px",
      },
      {
        targets: 8,
        data: "dateDebutValidite",
      },
      {
        targets: 9,
        data: "dateFinValidite",
      },
      {
        targets: 10,
        data: "numeroTitre",
      },
      {
        targets: 11,
        data: "commentaire",
        width: "200px",
      },
      {
        targets: 12,
        data: "traitePNDPhoto",
      },
    ];
  }

  private initOptionsBeforeSearch() {
    this.optionsListeFiltree.ajax = {
      url: "/api/organismeBeneficiaire/titre/demandeTitre",
      data: {},
      headers: { Authorization: "Bearer " + sessionStorage.getItem("token") },
    };
    this.optionsListeFiltree.serverSide = true;
  }

  public zoneCommentaireBlur(demande) {
    if (demande.commentaire.length > 70) {
      demande.commentaire = demande.tmpCom;
      return false;
    } else {
      this.demandeService
        .saveDemande(demande)
        .then((v) => {
          // This is intentional
        })
        .catch((e) => {
          demande.commentaire = demande.tmpCom;
        });
    }
  }

  public zoneCommentaireChange(demande) {
    if (demande.commentaire.length > 70) {
      demande.erreurLgCom = true;
    } else {
      demande.erreurLgCom = false;
    }
  }

  public zoneCommentaireFocus(demande) {
    demande.tmpCom = demande.commentaire;
    demande.erreurLgCom = false;
  }

  public initialiserFiltre(): void {
    // Au moins un filtre est requis
    this.filtreRequis = true;

    // On va chercher les valeurs des filtres (en session par exemple)
    const values: any = this.getFiltreValues();

    // On rempli les valeurs par défaut
    this.demandeValue = values ? values.numeroDemande : null;
    this.nomValue = values ? values.nom : null;
    this.raisonSocialeValue = values ? values.raisonSociale : null;
    this.immatriculationValue = values ? values.immatriculation : null;
    this.typeTitreValue = values ? values.typeTitre : null;
    this.statutDemandeValue = values ? values.statutDemande : null;
    this.statutTitreValue = values ? values.statutTitre : null;
    this.finValiditeValue =
      values && values.finValidite ? moment(values.finValidite) : null;

    // On créé le formulaire
    this.filtresForm = this.formBuilder.group({
      numeroDemande: [
        this.demandeValue,
        Validators.compose([
          Validators.maxLength(15),
          Validators.pattern("[0-9]*"),
        ]),
      ],
      nom: [
        this.nomValue,
        Validators.compose([
          Validators.maxLength(49),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘’“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ]),
      ],
      raisonSociale: [
        this.raisonSocialeValue,
        Validators.compose([
          Validators.maxLength(38),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘’“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ]),
      ],
      immatriculation: [
        this.immatriculationValue,
        Validators.compose([
          Validators.maxLength(11),
          Validators.pattern(
            "[0-9A-Za-z! \"$%&'()*+,-./:<=>?@[\\]\\\\^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘’“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]*"
          ),
        ]),
      ],
      typeTitre: [this.typeTitreValue],
      statutDemande: [this.statutDemandeValue],
      statutTitre: [this.statutTitreValue],
      finValidite: [this.finValiditeValue, validDate],
    });
  }

  public initialiserColumnsOrder(): void {
    this.optionsListeFiltree.order = [];
  }

  public switchFiltreVisible() {
    this.filtreVisible = !this.filtreVisible;
  }

  public lancerRecherche(): void {
    console.log(
      `[CommandesComponent] Lancement de la recherche`,
      this.filtresForm.value
    );

    this.initOptionsBeforeSearch();

    if (this.demandeValue) {
      this.optionsListeFiltree.ajax["data"]["demande"] = this.demandeValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["demande"];
    }

    if (this.nomValue) {
      this.optionsListeFiltree.ajax["data"]["nom"] = this.nomValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["nom"];
    }

    if (this.raisonSocialeValue) {
      this.optionsListeFiltree.ajax["data"]["raisonSociale"] =
        this.raisonSocialeValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["raisonSociale"];
    }

    if (this.immatriculationValue) {
      this.optionsListeFiltree.ajax["data"]["immatriculation"] =
        this.immatriculationValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["immatriculation"];
    }

    if (this.typeTitreValue) {
      this.optionsListeFiltree.ajax["data"]["typeTitre"] = this.typeTitreValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["typeTitre"];
    }

    if (this.statutDemandeValue) {
      this.optionsListeFiltree.ajax["data"]["statutDemande"] =
        this.statutDemandeValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["statutDemande"];
    }

    if (this.statutTitreValue) {
      this.optionsListeFiltree.ajax["data"]["statutTitre"] =
        this.statutTitreValue;
    } else {
      delete this.optionsListeFiltree.ajax["data"]["statutTitre"];
    }

    if (this.getFiltreValues().finValidite) {
      this.optionsListeFiltree.ajax["data"]["finValidite"] = moment(
        this.getFiltreValues().finValidite
      ).format("YYYY-MM-DD");
    } else {
      delete this.optionsListeFiltree.ajax["data"]["finValidite"];
    }

    //// On reinitialise le tableau
    this.reinitDataTable();

    this.rechercheSoumise = true;
  }

  public extraireDonnees(e, settings, json): void {
    this.demandes = json.data;
  }

  public traiterPhotoDemande(demande: DemandeTitre, traite: boolean): void {
    console.log(
      `[CommandesComponent] Changement d'état d'une demande (PND photo).`,
      demande,
      traite
    );
    demande.traitePNDPhoto = traite;
    this.demandeService.saveDemande(demande);

    this.refreshDataTableSorts();
  }
  public traiterTitreDemande(demande: DemandeTitre, traite: boolean): void {
    console.log(
      `[CommandesComponent] Changement d'état d'une demande (PND titre).`,
      demande,
      traite
    );
    demande.traitePNDTitre = traite;
    this.demandeService.saveDemande(demande);

    this.refreshDataTableSorts();
  }

  public print(): void {
    window.print();
  }

  public download(): void {
    const data: any[][] = [];

    if (this.utilisateur.personnePhysique) {
      data.push([
        "Numéro identifiant",
        "Nom",
        "Prénom",
        "Mention",
        "Statut de production",
        "Statut du titre",
        "Date de début de validité",
        "Date de fin de validité",
        "Numéro de titre",
        "Commentaire",
        "PND traité",
      ]);
    } else {
      data.push([
        "Numéro identifiant",
        "Raison sociale",
        "Immatriculation",
        "Statut de production",
        "Statut du titre",
        "Date de début de validité",
        "Date de fin de validité",
        "Numéro de titre",
        "Commentaire",
        "PND traité",
      ]);
    }

    if (this.utilisateur.personnePhysique) {
      for (const demande of this.demandes) {
        data.push([
          new Nombre(demande.identifiantBeneficiaire),
          demande.nom,
          demande.prenom,
          demande.mention === null ? "" : demande.mention.valeur,
          demande.statutProduction === null
            ? ""
            : demande.statutProduction.libelleCourt,
          demande.statutTitre === null ? "" : demande.statutTitre.valeur,
          this.momentPipe.transform(
            demande.dateDebutValidite,
            "dd/MM/yyyy",
            "yyyy-MM-dd"
          ),
          demande.permanent
            ? "PERMANENT"
            : this.momentPipe.transform(
                demande.dateFinValidite,
                "dd/MM/yyyy",
                "yyyy-MM-dd"
              ),
          demande.numeroTitre,
          demande.commentaire,
          demande.traitePNDPhoto || demande.traitePNDTitre ? "O" : "N",
        ]);
      }
    } else {
      for (const demande of this.demandes) {
        data.push([
          new Nombre(demande.identifiantBeneficiaire),
          demande.raisonSociale,
          demande.immatriculation,
          demande.statutProduction === null
            ? ""
            : demande.statutProduction.libelleCourt,
          demande.statutTitre === null ? "" : demande.statutTitre.valeur,
          this.momentPipe.transform(
            demande.dateDebutValidite,
            "dd/MM/yyyy",
            "yyyy-MM-dd"
          ),
          demande.permanent
            ? "PERMANENT"
            : this.momentPipe.transform(
                demande.dateFinValidite,
                "dd/MM/yyyy",
                "yyyy-MM-dd"
              ),
          demande.numeroTitre,
          demande.commentaire,
          demande.traitePNDPhoto || demande.traitePNDTitre ? "O" : "N",
        ]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `demandes_${horodateFichierCsv}.csv`
    );
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
