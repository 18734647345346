import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
	selector: 'in-footer',
	templateUrl: './footer.component.html',
})
export class FooterComponent {
	constructor(private router: Router) {}

	afficherBordureFooterIdentification(): boolean {
		const route: string = this.router.url.split('#')[0];
		return route.endsWith('/authentification') || route.endsWith('/authentification/expired');
	}
}
