import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

/*
 * Méthode de validation.
 */
export function emailValidator(): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    if (v) {
        let incorrect = false;
        
        /* regexp email apache commons validator 1.6 */
        if(!v.match(/^\s*?(.+)@(.+?)\s*$/)) {
            incorrect = true;
        }else {
            const user = v.match(/^\s*?(.+)@(.+?)\s*$/)[1];
            const domain = v.match(/^\s*?(.+)@(.+?)\s*$/)[2];

            /* regexp email user apache commons validator 1.6 */
            if(!user.match(/^\s*(((\.)|[^\s\x00-\x1F\x7F\(\)<>@,;:'\\"\.\[\]]|')+|(\"(\"|[^\"])*\"))(\.(((\.)|[^\s\x00-\x1F\x7F\(\)<>@,;:'\\"\.\[\]]|')+|(\"(\"|[^\"])*\")))*$/)) {
                incorrect = true;
            }

            /* regexp email domain apache commons validator 1.6 */
            if(!domain.match(/^(\[[^\[\]]*\]|(?:[A-Za-z0-9]([A-Za-z0-9\-]{0,61}[A-Za-z0-9])?\.)+([A-Za-z]([A-Za-z0-9\-]{0,61}[A-Za-z0-9])?)\.?)$/)) {
                incorrect = true;
            }
        }


        if(incorrect) {
            return {
              validateEmail: false,
            };
        }
    }
    return null;
  };
}

@Directive({
  selector: '[email][formControlName],[email][formControl],[email][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EmailValidator), multi: true }
  ]
})
export class EmailValidator implements Validator {
  constructor( @Attribute('equals') public equals: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return emailValidator()(c);
  }
}
