import { NgModule }                from '@angular/core';
import { Routes, RouterModule }    from '@angular/router';

import { TransfertComponent }      from './transfert.component';
import { ResultatControleTransfertComponent } from './resultats/resultat-controle-transfert.component';

import { CanActivateADroitGuard }  from '../authentification/a-droit.guard';
import { DROITS }                  from '../authentification/droits.constants';

export const routes: Routes = [
  {
    path: '',
    component: TransfertComponent
  },
  {
    path: 'resultatsControle',
    component: ResultatControleTransfertComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Résultats',
      breadcrumb: 'Résultat Contrôle Fichier Commande',
      menu: 'resultatsControle',
      droit: DROITS.TRANSFERT,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class TransfertRoutingModule { }
