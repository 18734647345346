import { Injectable } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Groupe } from './groupe';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

export class RechercheGroupe {
    nom: string;
    droitGroupe: string;
}

@Injectable()
export class GroupeService {

  constructor(private http: Http) {
  }

  groupes(recherche: RechercheGroupe): Observable<Groupe[]> {
    console.log(`Recherche des groupes`);

    const parameters: URLSearchParams = new URLSearchParams();

    if (recherche.nom) {
      parameters.set('groupeName', recherche.nom);
    }

    return this.http.get('/api/authentification/groupe/admin/getGroupes', {
      search: parameters,
    }).map(r => r.json() as Groupe[]);
  }

  public detailGroupe(numero: string): Observable<Groupe> {
    console.log(`[GroupeService] Lecture du groupe numero `, numero);
    return this.http.get(`/api/authentification/groupe/admin/getGroupe/${numero}`)
      .map(r => r.json() as Groupe);
  }

  public createGroupe(groupe: Groupe): Promise<boolean> {
    console.log(`[GroupeService] Creation du groupe `, groupe);
    return new Promise((resolve, reject) => {
      this.http
        .post('/api/authentification/groupe/admin/createGroupe', groupe)
        .toPromise()
        .then((response) => resolve(response.status === 200 || response.status === 201))
        .catch(reject);
    });
  }

  public modifyGroupe(groupe: Groupe): Promise<boolean> {
    console.log(`[GroupeService] Modification du groupe ${groupe.numeroId}`, groupe);
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/authentification/groupe/admin/updateGroupe/${groupe.numeroId}`, groupe)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  public deleteGroupe(groupe: Groupe): Promise<boolean> {
    console.log(`[GroupeService] Suppression du groupe ${groupe.numeroId}`, groupe);
    return new Promise((resolve, reject) => {
      const options = new RequestOptions({ body: groupe });
      this.http
        .delete(`/api/authentification/groupe/admin/delGroupe/${groupe.numeroId}`, options)
        .toPromise()
        .then((response) => resolve(response.status === 200 || response.status === 204))
        .catch(reject);
    });
  }
}
