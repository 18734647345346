import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import * as moment from "moment";
import { Subscription } from "rxjs";

import { AuthentificationService } from "../authentification/authentification.service";
import { Utilisateur } from "../authentification/utilisateur";
import { CsvService } from "../commons/csv.service";
import { CodePostal } from "../commons/dtos/code-postal";
import { dateAfterOrEqualsValidator } from "../commons/validators/date-after-or-equals.validator";
import { dateAfterValidator } from "../commons/validators/date-after.validator";
import { dateBeforeOrEqualsValidator } from "../commons/validators/date-before-or-equals.validator";
import { validDate } from "../commons/validators/valid-date.validator";
import { DelaisMoyensAnswer } from "./dto/delais-moyens-answer";
import { DelaisMoyensData } from "./dto/delais-moyens-data";
import { StatutAppelPhotoAnswer } from "./dto/statut-appel-photo-answer";
import { StatutAppelPhotoData } from "./dto/statut-appel-photo-data";
import { StatutDemandesTitreData } from "./dto/statut-demandes-titre-data";
import { SuiviConnexionsAnswer } from "./dto/suivi-connexions-answer";
import { SuiviDemandeTitreValideAnswer } from "./dto/suivi-demande-titre-valide-answer";
import { SuiviDemandeTitreValideData } from "./dto/suivi-demande-titre-valide-data";
import { SuiviTitreAnswer } from "./dto/suivi-titre-answer";
import { SuiviTitreData } from "./dto/suivi-titre-data";
import { StatistiquesService } from "./statistiques.service";

declare var $: any;

@Component({
  selector: "in-statistiques",
  templateUrl: "./statistiques.component.html",
})
export class StatistiquesComponent implements OnInit, OnDestroy {
  private dateDuJour: moment.Moment = null;
  private dateDuJourMoins14Mois: moment.Moment = null;

  public statutDemandesTitre: StatutDemandesTitreData[] = null;
  public suiviTitre: SuiviTitreAnswer = null;
  public suiviDemandeTitreValide: SuiviDemandeTitreValideAnswer = null;
  public statutAppelPhoto: StatutAppelPhotoAnswer = null;
  public suiviConnexions: SuiviConnexionsAnswer = null;
  public delaisMoyens: DelaisMoyensAnswer = null;
  private periodeSuiviTitre: string = null;
  private periodeStatutAppelPhoto: string = null;
  private periodeSuiviConnexions: string = null;
  public filtreVisible: boolean = false;
  public filtresForm: FormGroup;
  public utilisateur: Utilisateur = null;

  utilisateurSubscription: Subscription;

  public constructor(
    private statistiquesService: StatistiquesService,
    private formBuilder: FormBuilder,
    private authentificationService: AuthentificationService,
    private csvService: CsvService
  ) {}

  public ngOnInit(): void {
    this.dateDuJour = moment();
    this.dateDuJourMoins14Mois = this.getDateMoinsLimite(this.dateDuJour);
    this.initialiserFiltre();
    this.filtreVisible = true;

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  private getDateMoinsLimite(dateIn: moment.Moment): moment.Moment {
    let dateMoins = moment(dateIn).add(-14, "month").add(1, "day");
    if (dateMoins.isBefore(moment("2017-01-01"))) {
      dateMoins = moment("2017-01-01");
    }
    return dateMoins;
  }

  public initialiserFiltre(): void {
    // On va chercher les valeurs des filtres (en session par exemple)
    const values: any = this.getFiltreValues();

    // On rempli les valeurs par défaut
    console.log("[StatistiquesComponent] initialiserFiltre", values);
    const typeStatistiquesValue: string = values ? values.typeStatistiques : "";
    const dateDebutValue: moment.Moment =
      values && values.dateDebut ? moment(values.dateDebut) : null;
    const dateFinValue: moment.Moment =
      values && values.dateFin ? moment(values.dateFin) : null;

    // On créé le formulaire
    this.filtresForm = this.formBuilder.group({
      typeStatistiques: [
        typeStatistiquesValue,
        Validators.compose([Validators.required]),
      ],
      dateDebut: [
        dateDebutValue,
        Validators.compose([validDate, dateAfterValidator("limite")]),
      ],
      dateFin: [
        dateFinValue,
        Validators.compose([
          validDate,
          dateAfterOrEqualsValidator("dateDebut"),
          dateAfterValidator("limite"),
          dateBeforeOrEqualsValidator("limiteIntervalle"),
        ]),
      ],
      limite: ["2016-12-31", null],
      limiteIntervalle: [
        dateDebutValue === null
          ? null
          : moment(dateDebutValue).add(14, "month").add(-1, "day"),
        null,
      ],
    });

    // Sur changement de la date de début
    const that = this;
    this.filtresForm.controls["dateDebut"].valueChanges.subscribe((data) => {
      // Recalcule la limite de l'intervalle
      that.recalculerLimiteIntervalle();

      // Rafraichissement date de fin = date de début pour la statistique Suivi des demandes de titres valides
      if (
        this.filtresForm.controls["typeStatistiques"].value ===
        "SUIVI_DEMANDE_TITRE_VALIDE"
      ) {
        this.filtresForm.controls["dateFin"].setValue(
          this.filtresForm.controls["dateDebut"].value
        );
      }

      // Rafraichissement pour les validations entre 2 champs
      this.filtresForm.controls["dateFin"].updateValueAndValidity();
    });
  }

  recalculerLimiteIntervalle() {
    // Recalcule la limite de l'intervalle
    if (this.filtresForm.controls["dateDebut"].value) {
      const newLimite = moment(this.filtresForm.controls["dateDebut"].value)
        .add(14, "month")
        .add(-1, "day");
      this.filtresForm.controls["limiteIntervalle"].setValue(newLimite);
    } else {
      this.filtresForm.controls["limiteIntervalle"].setValue(null);
    }
  }

  private activerFiltreParDateDebut(): boolean {
    return (
      this.filtresForm.controls["typeStatistiques"].value !== "" &&
      (this.filtresForm.controls["typeStatistiques"].value === "SUIVI_TITRE" ||
        this.filtresForm.controls["typeStatistiques"].value ===
          "STATUT_APPEL_PHOTO" ||
        this.filtresForm.controls["typeStatistiques"].value ===
          "SUIVI_CONNEXIONS")
    );
  }
  private activerFiltreParDateFin(): boolean {
    return (
      this.filtresForm.controls["typeStatistiques"].value !== "" &&
      (this.filtresForm.controls["typeStatistiques"].value === "SUIVI_TITRE" ||
        this.filtresForm.controls["typeStatistiques"].value ===
          "STATUT_APPEL_PHOTO" ||
        this.filtresForm.controls["typeStatistiques"].value ===
          "SUIVI_CONNEXIONS")
    );
  }

  private getFiltreKey(): string {
    return `filtres.${this.constructor.name}`;
  }

  private getFiltreValues(): any {
    return JSON.parse(sessionStorage.getItem(this.getFiltreKey()));
  }

  private changeTypeStatistique(): void {
    this.razResultats();
    if (
      this.filtresForm.controls["typeStatistiques"].value === "STATUT_DEMANDES"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(this.dateDuJour);
      this.filtresForm.controls["dateFin"].setValue(this.dateDuJour);
    } else if (
      this.filtresForm.controls["typeStatistiques"].value === "SUIVI_TITRE"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(null);
      this.filtresForm.controls["dateFin"].setValue(null);
    } else if (
      this.filtresForm.controls["typeStatistiques"].value ===
      "SUIVI_DEMANDE_TITRE_VALIDE"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(this.dateDuJour);
      this.filtresForm.controls["dateFin"].setValue(this.dateDuJour);
    } else if (
      this.filtresForm.controls["typeStatistiques"].value ===
      "STATUT_APPEL_PHOTO"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(null);
      this.filtresForm.controls["dateFin"].setValue(null);
    } else if (
      this.filtresForm.controls["typeStatistiques"].value === "SUIVI_CONNEXIONS"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(null);
      this.filtresForm.controls["dateFin"].setValue(null);
    } else if (
      this.filtresForm.controls["typeStatistiques"].value === "DELAIS_MOYENS"
    ) {
      this.filtresForm.controls["dateDebut"].setValue(this.dateDuJour);
      this.filtresForm.controls["dateFin"].setValue(this.dateDuJour);
    }
  }

  private razResultats(): void {
    this.statutDemandesTitre = null;
    this.suiviTitre = null;
    this.suiviDemandeTitreValide = null;
    this.statutAppelPhoto = null;
    this.suiviConnexions = null;
    this.delaisMoyens = null;
    this.periodeSuiviTitre = null;
    this.periodeStatutAppelPhoto = null;
    this.periodeSuiviConnexions = null;
  }

  // EA19: Valeurs par défaut si au moins une des dates n'est pas renseignée
  // Date de fin=date du jour
  // Date de début=date de fin - 14 mois, minimum 01/01/2017
  private updateDatesDebutFinEA19(): void {
    let estDateDebutModifiee: boolean = false;
    let estDateFinModifiee: boolean = false;
    if (!this.filtresForm.controls["dateFin"].value) {
      this.filtresForm.controls["dateFin"].setValue(this.dateDuJour);
      $("#dateFin").val(this.dateDuJour.format("DD/MM/YYYY"));
      console.log(
        "EA19 - Date de fin = date du jour",
        this.dateDuJour.format("DD/MM/YYYY")
      );
      estDateFinModifiee = true;
    }
    if (!this.filtresForm.controls["dateDebut"].value) {
      const newDateDebut: moment.Moment = this.getDateMoinsLimite(
        this.filtresForm.controls["dateFin"].value
      );
      this.filtresForm.controls["dateDebut"].setValue(newDateDebut);
      $("#dateDebut").val(newDateDebut.format("DD/MM/YYYY"));
      this.recalculerLimiteIntervalle();
      console.log(
        "EA19 - Date de début = date de fin - 14 mois",
        newDateDebut.format("DD/MM/YYYY")
      );
      estDateDebutModifiee = true;
    }
    if (estDateDebutModifiee) {
      this.filtresForm.controls["dateDebut"].updateValueAndValidity();
    }
    if (estDateFinModifiee) {
      this.filtresForm.controls["dateFin"].updateValueAndValidity();
    }
    console.log("EA19 - Done");
  }

  public lancerRecherche(): boolean {
    console.log(
      `[StatistiquesComponent] Lancement de la recherche`,
      this.filtresForm.valid,
      this.filtresForm.value
    );

    this.filtresForm.controls["typeStatistiques"].markAsTouched();

    if (this.filtresForm.valid) {
      sessionStorage.setItem(
        this.getFiltreKey(),
        JSON.stringify(this.filtresForm.value)
      );

      if (
        this.filtresForm.controls["typeStatistiques"].value ===
        "STATUT_DEMANDES"
      ) {
        this.razResultats();
        this.statistiquesService
          .statutDemandeTitre()
          .then((reponse) => {
            this.statutDemandesTitre = reponse;

            if (this.statutDemandesTitre === null) {
              this.statutDemandesTitre = new Array<StatutDemandesTitreData>();
            } else {
              console.log(
                "[StatistiquesComponent] statutDemandeTitre",
                this.statutDemandesTitre
              );

              // Tri des lignes suivant un ordre prédéfini
              this.statutDemandesTitre.sort(function (a, b) {
                const ordreFront = [
                  "Demande de fabrication de CMI effectuée",
                  "Attente confirmation doublon",
                  "Attente confirmation de doublon",
                  "Demande annulée - doublon",
                  "Poursuivre la demande - soupçon doublon écarté",
                  "Courrier de demande de photo envoyé - en attente photo",
                  "Courrier de demande de complétude photo envoyé - en attente photo",
                  "Photo invalide - demande de fabrication de la CMI ne peut pas être traitée",
                  "Photo invalide - en attente photo (par téléversement)",
                  "CMI en cours de fabrication",
                  "CMI expédiée",
                  "PND appel photo",
                  "PND titre",
                ];
                const idxA = ordreFront.indexOf(a.statut);
                const idxB = ordreFront.indexOf(b.statut);
                if (idxA < idxB) {
                  return -1;
                }
                if (idxA > idxB) {
                  return 1;
                }
                return 0;
              });

              console.log(
                "[StatistiquesComponent] statutDemandeTitre trie",
                this.statutDemandesTitre
              );
            }
          })
          .catch((err) => {
            console.log(
              "[StatistiquesComponent] Erreur statutDemandeTitre",
              err
            );
          });
      } else if (
        this.filtresForm.controls["typeStatistiques"].value === "SUIVI_TITRE"
      ) {
        this.razResultats();

        // EA19: Valeurs par défaut si aucune date renseignée
        this.updateDatesDebutFinEA19();

        if (this.filtresForm.valid) {
          this.statistiquesService
            .suiviTitre(
              this.filtresForm.controls["dateDebut"].value,
              this.filtresForm.controls["dateFin"].value
            )
            .then((reponse) => {
              if (
                this.filtresForm.controls["dateDebut"].value &&
                this.filtresForm.controls["dateFin"].value
              ) {
                this.periodeSuiviTitre = `- Période du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")} au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateDebut"].value) {
                this.periodeSuiviTitre = `- A partir du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateFin"].value) {
                this.periodeSuiviTitre = `- Jusqu'au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              }

              this.suiviTitre = reponse;
              if (this.suiviTitre === null) {
                this.suiviTitre = new SuiviTitreAnswer();
                this.suiviTitre.estDisponible = true;
                this.suiviTitre.data = new Array<SuiviTitreData>();
              } else {
                console.log(
                  "[StatistiquesComponent] suiviTitre",
                  this.suiviTitre
                );

                // Tri des lignes suivant un ordre prédéfini
                this.suiviTitre.data.sort(function (a, b) {
                  const ordreFront = [
                    "Nombre demandes réceptionnées (organisme)",
                    "Nombre expédiées",
                    "Nombre de duplicata expédiés (bénéficiaire)",
                    "Nombre de 2e exemplaire expédiés (bénéficiaire)",
                  ];
                  const idxA = ordreFront.indexOf(a.statut);
                  const idxB = ordreFront.indexOf(b.statut);
                  if (idxA < idxB) {
                    return -1;
                  }
                  if (idxA > idxB) {
                    return 1;
                  }
                  return 0;
                });

                console.log(
                  "[StatistiquesComponent] suiviTitre trie",
                  this.suiviTitre
                );
              }
            })
            .catch((err) => {
              console.log("[StatistiquesComponent] Erreur suiviTitre", err);
              if (err.status === 400) {
                this.suiviTitre = err.json();
              }
            });
        }
      } else if (
        this.filtresForm.controls["typeStatistiques"].value ===
        "SUIVI_DEMANDE_TITRE_VALIDE"
      ) {
        this.razResultats();

        if (this.filtresForm.valid) {
          this.statistiquesService
            .suiviDemandeTitreValide()
            .then((reponse) => {
              this.suiviDemandeTitreValide = reponse;
              if (this.suiviDemandeTitreValide === null) {
                this.suiviDemandeTitreValide =
                  new SuiviDemandeTitreValideAnswer();
                this.suiviDemandeTitreValide.estDisponible = true;
                this.suiviDemandeTitreValide.data =
                  new Array<SuiviDemandeTitreValideData>();
              } else {
                console.log(
                  "[StatistiquesComponent] suiviDemandeTitreValide",
                  this.suiviDemandeTitreValide
                );

                // Tri des lignes suivant code postal croissant
                this.suiviDemandeTitreValide.data.sort(function (a, b) {
                  const idxA = a.codePostal;
                  const idxB = b.codePostal;

                  // Etranger en premier
                  if (idxA === "Etranger") {
                    return -1;
                  }
                  if (idxB === "Etranger") {
                    return 1;
                  }
                  if (idxA < idxB) {
                    return -1;
                  }
                  if (idxA > idxB) {
                    return 1;
                  }
                  return 0;
                });

                console.log(
                  "[StatistiquesComponent] suiviDemandeTitreValide trie",
                  this.suiviDemandeTitreValide
                );
              }
            })
            .catch((err) => {
              console.log(
                "[StatistiquesComponent] Erreur suiviDemandeTitreValide",
                err
              );
              if (err.status === 400) {
                this.suiviDemandeTitreValide = err.json();
              }
            });
        }
      } else if (
        this.filtresForm.controls["typeStatistiques"].value ===
        "STATUT_APPEL_PHOTO"
      ) {
        this.razResultats();

        // EA19: Valeurs par défaut si aucune date renseignée
        this.updateDatesDebutFinEA19();

        if (this.filtresForm.valid) {
          this.statistiquesService
            .statutAppelPhoto(
              this.filtresForm.controls["dateDebut"].value,
              this.filtresForm.controls["dateFin"].value
            )
            .then((reponse) => {
              if (
                this.filtresForm.controls["dateDebut"].value &&
                this.filtresForm.controls["dateFin"].value
              ) {
                this.periodeStatutAppelPhoto = `- Période du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")} au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateDebut"].value) {
                this.periodeStatutAppelPhoto = `- A partir du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateFin"].value) {
                this.periodeStatutAppelPhoto = `- Jusqu'au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              }

              this.statutAppelPhoto = reponse;
              if (this.statutAppelPhoto === null) {
                this.statutAppelPhoto = new StatutAppelPhotoAnswer();
                this.statutAppelPhoto.estDisponible = true;
                this.statutAppelPhoto.data = new Array<StatutAppelPhotoData>();
              } else {
                console.log(
                  "[StatistiquesComponent] statutAppelPhoto",
                  this.statutAppelPhoto
                );

                // Tri des lignes suivant un ordre prédéfini
                this.statutAppelPhoto.data.sort(function (a, b) {
                  const ordreFront = [
                    'Réception "appel photo" par courrier',
                    'Réception "appel photo" dématérialisé',
                    "Nombre de demandes en attente photo",
                  ];
                  const idxA = ordreFront.indexOf(a.statut);
                  const idxB = ordreFront.indexOf(b.statut);
                  if (idxA < idxB) {
                    return -1;
                  }
                  if (idxA > idxB) {
                    return 1;
                  }
                  return 0;
                });

                console.log(
                  "[StatistiquesComponent] statutAppelPhoto trie",
                  this.statutAppelPhoto
                );
              }
            })
            .catch((err) => {
              console.log(
                "[StatistiquesComponent] Erreur statutAppelPhoto",
                err
              );
              if (err.status === 400) {
                this.statutAppelPhoto = err.json();
              }
            });
        }
      } else if (
        this.filtresForm.controls["typeStatistiques"].value ===
        "SUIVI_CONNEXIONS"
      ) {
        this.razResultats();

        // EA19: Valeurs par défaut si aucune date renseignée
        this.updateDatesDebutFinEA19();

        if (this.filtresForm.valid) {
          this.statistiquesService
            .suiviConnexions(
              this.filtresForm.controls["dateDebut"].value,
              this.filtresForm.controls["dateFin"].value
            )
            .then((reponse) => {
              if (
                this.filtresForm.controls["dateDebut"].value &&
                this.filtresForm.controls["dateFin"].value
              ) {
                this.periodeSuiviConnexions = `- Période du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")} au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateDebut"].value) {
                this.periodeSuiviConnexions = `- A partir du ${this.filtresForm.controls[
                  "dateDebut"
                ].value.format("DD/MM/YYYY")}`;
              } else if (this.filtresForm.controls["dateFin"].value) {
                this.periodeSuiviConnexions = `- Jusqu'au ${this.filtresForm.controls[
                  "dateFin"
                ].value.format("DD/MM/YYYY")}`;
              }

              this.suiviConnexions = reponse;
              if (this.suiviConnexions === null) {
                this.suiviConnexions = new SuiviConnexionsAnswer();
                this.suiviConnexions.estDisponible = true;
                this.suiviConnexions.codeErreur = "NO_DATA";
              } else {
                console.log(
                  "[StatistiquesComponent] suiviConnexions",
                  this.suiviConnexions
                );
              }
            })
            .catch((err) => {
              console.log(
                "[StatistiquesComponent] Erreur suiviConnexions",
                err
              );
              if (err.status === 400) {
                this.suiviConnexions = err.json();
              }
            });
        }
      } else if (
        this.filtresForm.controls["typeStatistiques"].value === "DELAIS_MOYENS"
      ) {
        this.razResultats();

        if (this.filtresForm.valid) {
          this.statistiquesService
            .delaisMoyens()
            .then((reponse) => {
              this.delaisMoyens = reponse;
              if (this.delaisMoyens === null) {
                this.delaisMoyens = new DelaisMoyensAnswer();
                this.delaisMoyens.estDisponible = true;
                this.delaisMoyens.data = new Array<DelaisMoyensData>();
              } else {
                console.log(
                  "[StatistiquesComponent] delaisMoyens",
                  this.delaisMoyens
                );
              }
            })
            .catch((err) => {
              console.log("[StatistiquesComponent] Erreur delaisMoyens", err);
              if (err.status === 400) {
                this.delaisMoyens = err.json();
              }
            });
        }
      }
    }

    return false;
  }

  public print(): void {
    window.print();
  }

  public downloadStatutDemandesTitre(): void {
    const data: any[][] = [];

    if (this.utilisateur && this.utilisateur.personnePhysique) {
      data.push([
        "",
        "Stationnement",
        "Priorité",
        "Invalidité",
        "Invalidité Besoin accompagnement",
        "Invalidité Besoin accompagnement cécité",
        "TOTAL",
      ]);
      for (const stat of this.statutDemandesTitre) {
        data.push([
          stat.statut,
          stat.valeurStationnement,
          stat.valeurPriorite,
          stat.valeurInvaliditeSeule,
          stat.valeurInvaliditeAccomp,
          stat.valeurInvaliditeCecite,
          stat.valeurTotal,
        ]);
      }
    } else {
      data.push(["", "Stationnement"]);
      for (const stat of this.statutDemandesTitre) {
        data.push([stat.statut, stat.valeurStationnement]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `statutDemandesTitre_${horodateFichierCsv}.csv`
    );
  }

  public downloadSuiviTitre(): void {
    const data: any[][] = [];

    if (this.utilisateur && this.utilisateur.personnePhysique) {
      data.push([
        "",
        "Stationnement",
        "Priorité",
        "Invalidité",
        "Invalidité Besoin accompagnement",
        "Invalidité Besoin accompagnement cécité",
        "TOTAL",
      ]);
      for (const stat of this.suiviTitre.data) {
        data.push([
          stat.statut,
          stat.valeurStationnement,
          stat.valeurPriorite,
          stat.valeurInvaliditeSeule,
          stat.valeurInvaliditeAccomp,
          stat.valeurInvaliditeCecite,
          stat.valeurTotal,
        ]);
      }
    } else {
      data.push(["", "Stationnement"]);
      for (const stat of this.suiviTitre.data) {
        data.push([stat.statut, stat.valeurStationnement]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `suiviTitre_${horodateFichierCsv}.csv`
    );
  }

  public downloadSuiviDemandeTitreValide(): void {
    const data: any[][] = [];

    if (this.utilisateur && this.utilisateur.personnePhysique) {
      data.push([
        "Code postal",
        "Stationnement",
        "Priorité",
        "Invalidité",
        "Invalidité Besoin accompagnement",
        "Invalidité Besoin accompagnement cécité",
        "TOTAL",
      ]);
      for (const stat of this.suiviDemandeTitreValide.data) {
        data.push([
          new CodePostal(stat.codePostal),
          stat.valeurStationnement,
          stat.valeurPriorite,
          stat.valeurInvaliditeSeule,
          stat.valeurInvaliditeAccomp,
          stat.valeurInvaliditeCecite,
          stat.valeurTotal,
        ]);
      }
    } else {
      data.push(["Code postal", "Stationnement"]);
      for (const stat of this.suiviDemandeTitreValide.data) {
        data.push([new CodePostal(stat.codePostal), stat.valeurStationnement]);
      }
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `suiviDemandeTitreValide_${horodateFichierCsv}.csv`
    );
  }

  public downloadStatutAppelPhoto(): void {
    const data: any[][] = [];

    data.push(["", "TOTAL"]);

    for (const stat of this.statutAppelPhoto.data) {
      data.push([stat.statut, stat.valeurTotal]);
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `statutAppelPhoto_${horodateFichierCsv}.csv`
    );
  }

  public downloadSuiviConnexions(): void {
    const data: any[][] = [];

    data.push([
      "",
      "Nombre de connexions organisme",
      "Nombre de connexions bénéficiaire",
    ]);

    data.push([
      "Total",
      this.suiviConnexions.nbConnexionsOrganisme,
      this.suiviConnexions.nbConnexionsBeneficiaire,
    ]);

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `suiviConnexions_${horodateFichierCsv}.csv`
    );
  }

  public downloadDelaisMoyens(): void {
    const data: any[][] = [];

    data.push(["", "Délai moyen", "Délai médian"]);

    for (const stat of this.delaisMoyens.data) {
      data.push([stat.traitement, stat.delaiMoyen, stat.delaiMedian]);
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `delaisMoyens_${horodateFichierCsv}.csv`
    );
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
