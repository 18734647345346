import { AfterViewChecked, Component, OnInit } from "@angular/core";
import { FormBuilder } from "@angular/forms";
import { Router } from "@angular/router";

import * as moment from "moment";

import { ListeFiltreeComponent } from "../../../commons/components/liste-filtree.component";
import { listeDroits } from "../../../commons/configuration";
import { CsvService } from "../../../commons/csv.service";
import { Groupe } from "./groupe";
import { GroupeService } from "./groupe.service";

declare var $: any;
//
@Component({
  selector: "in-groupes",
  templateUrl: "./groupes.component.html",
})
export class GroupesComponent
  extends ListeFiltreeComponent
  implements AfterViewChecked, OnInit
{
  public groupes: Groupe[] = [];
  groupesOrder = [];
  public rechercheSoumise: boolean = false;
  public soumis: boolean = false;
  public succes: boolean = true;
  listeDroits = listeDroits;

  public constructor(
    private formBuilder: FormBuilder,
    private groupeService: GroupeService,
    private router: Router,
    private csvService: CsvService
  ) {
    super();
  }

  ngOnInit() {
    super.ngOnInit();
    // RAZ messages
    window.addEventListener("keydown", (e) => {
      this.soumis = false;
    });
    window.addEventListener("click", (e) => {
      this.soumis = false;
    });
  }

  public extraireDonnees(e, settings, json) {
    // This is intentional
  }

  public onPreDrawCallback(settings) {
    this.groupesOrder = Array();
  }

  public onRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
    this.groupesOrder[this.groupesOrder.length] = aData[0];
  }

  public initialiserFiltre(): void {
    // On va chercher les valeurs des filtres (en session par exemple)
    const values: any = this.getFiltreValues();

    // On rempli les valeurs par défaut
    const nomValue: string = values && values.nom ? values.nom : null;

    // On créé le formulaire
    this.filtresForm = this.formBuilder.group({
      nom: [nomValue],
    });
  }

  public initialiserColumnsOrder(): void {
    this.optionsListeFiltree.columnDefs = [{ orderable: false, targets: 3 }];
  }

  public lancerRecherche(): void {
    console.log(
      `[GroupesComponent] Lancement de la recherche`,
      this.filtresForm.value
    );

    this.groupeService.groupes(this.filtresForm.value).subscribe((groupes) => {
      // On reinitialise le tableau
      this.reinitDataTable();

      // On met à jour les groupes
      this.groupes = groupes;
      this.rechercheSoumise = true;
    });
  }

  public creer(): void {
    this.router.navigate(["/administrateur/groupes/creer-groupe"]);
  }

  public modifier(groupe: Groupe): void {
    this.router.navigate(["/administrateur/groupes/" + groupe.numeroId]);
  }

  public supprimer(groupe: Groupe): void {
    const composant: GroupesComponent = this;
    $("#popinConfirmerSuppression").dialog({
      resizable: false,
      height: "auto",
      width: 400,
      modal: true,
      buttons: {
        Annuler: function () {
          $(this).dialog("close");
        },
        "Supprimer le groupe": function () {
          $(this).dialog("close");
          composant.lancerSuppression(groupe);
        },
      },
    });
  }

  private lancerSuppression(groupe: Groupe): void {
    console.log(
      "[GroupesComponent] lancement suppression du groupe " + groupe.nom
    );
    this.groupeService
      .deleteGroupe(groupe)
      .then((succes) => {
        this.soumis = true;
        this.succes = succes;
        if (succes) {
          this.groupes = this.groupes.filter(
            (g) => groupe.numeroId !== g.numeroId
          );
          this.reinitDataTable();
          this.atteindreMessages();
        }
      })
      .catch((err) => {
        this.soumis = true;
        this.succes = false;
      });
  }

  atteindreMessages() {
    const element = document.querySelector("#messages");
    element.scrollIntoView(true);
  }

  public listeFonctionnalites(e: string[]): string {
    let resListe = "";
    if (e) {
      let first = true;
      for (let i = 0; i < e.length; i++) {
        let trouve = false;
        for (let j = 0; j < this.listeDroits.length; j++) {
          if (this.listeDroits[j].code === e[i]) {
            if (this.listeDroits[j].visible) {
              resListe += !first
                ? ", " + this.listeDroits[j].libelle
                : this.listeDroits[j].libelle;
              first = false;
            }
            trouve = true;
          }
        }
        if (!trouve) {
          resListe += !first ? ", undefined" : "undefined";
          first = false;
        }
      }
    }
    return resListe;
  }

  public print(): void {
    window.print();
  }

  public download(): void {
    const orderedGroupes: Groupe[] = Array();

    const data: any[][] = [];

    for (const og of this.groupesOrder) {
      for (const gp of this.groupes) {
        if ("" + og === "" + gp.numeroId) {
          orderedGroupes[orderedGroupes.length] = gp;
        }
      }
    }

    data.push(["Nom", "Fonctionnalités"]);

    for (const groupe of orderedGroupes) {
      data.push([groupe.nom, this.listeFonctionnalites(groupe.droits)]);
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(data, `groupes_${horodateFichierCsv}.csv`);
  }
}
