import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

/*
 * Méthode de validation.
 */
export function dateBeforeValidator(dateBefore: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = <moment.Moment>c.value;
    const e = c.root.get(dateBefore);

    if (e && e.value && v && !v.isBefore(e.value)) {
      return {
        dateBefore: false
      };
    }
    return null;
  };
}

@Directive({
  selector: '[dateBefore][formControlName],[dateBefore][formControl],[dateBefore][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateBeforeValidator), multi: true }
  ]
})
export class DateBeforeValidator implements Validator {
  constructor( @Attribute('dateBefore') public dateBefore: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return dateBeforeValidator(this.dateBefore)(c);
  }
}
