import { Component, Input, forwardRef, ViewChild, ElementRef, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import * as moment from 'moment';

declare var $: any;

@Component({
	selector: 'in-datepicker',
	template: `
		<div [ngClass]="{ 'input-group': !desactive }">
			<input #element type="text" [name]="id" [id]="id" placeholder="jj/mm/aaaa" [value]="valueString" [disabled]="desactive" (change)="onChange($event.target.value)" class="form-control" />
			<div *ngIf="!desactive" (click)="toggle()" class="input-group-addon">
				<span aria-hidden="true" class="cmipicto picto-calendar"></span>
			</div>
		</div>
	`,
	styles: ['.visibleCalendar { position: absolute; display: block; z-index: 1000; }', '.invisibleCalendar { position: absolute; display: none; z-index: 1000; }'],
	providers: [
		{
			provide: NG_VALUE_ACCESSOR,
			useExisting: forwardRef(() => DatepickerComponent),
			multi: true,
		},
	],
})
export class DatepickerComponent implements ControlValueAccessor, OnInit {
	@ViewChild('element')
	public element: ElementRef;

	@Input('inID')
	public id: string;

	@Input('inDisabled')
	public desactive: boolean = false;

	public toggled: boolean = false;

	private value: moment.Moment;
	private valueDate: Date;
	public valueString: string = '';

	public propagateChange = (_: any) => {
		// This is intentional
	};

	public ngOnInit(): void {
		setTimeout(this.initDatepicker.bind(this), 0);
	}

	private initDatepicker(): void {
		$(this.element.nativeElement).datepicker({
			dateFormat: 'dd/mm/yy',
			onSelect: (value) => {
				this.onChange(value);
			},
		});
	}

	toggle(): void {
		this.toggled = !this.toggled;
		$(this.element.nativeElement).datepicker(this.toggled ? 'show' : 'hide');
	}

	onChange(value: string) {
		this.toggled = false;
		if (!value) {
			this.value = null;
			this.valueString = '';
		} else {
			// match 8 decimal number for the string AKA DDMMYYYY
			if (value.match(/^\d{8}$/)) {
				value = value.slice(0, 2) + '/' + value.slice(2, 4) + '/' + value.slice(4, 8);
				this.element.nativeElement.value = value;
			}
			this.value = moment(value, 'DD/MM/YYYY', true);
			if (this.value.isValid()) {
				this.valueDate = this.value.toDate();
				this.valueString = this.value.format('DD/MM/YYYY');
			} else {
				this.valueDate = undefined;
				this.valueString = value;
			}
		}
		this.propagateChange(this.value);
	}

	writeValue(value: moment.Moment) {
		this.value = value;
		if (value) {
			this.valueDate = value.toDate();
			this.valueString = value.format('DD/MM/YYYY');
		} else {
			this.valueDate = undefined;
			this.valueString = '';
		}
	}

	registerOnChange(fn: any) {
		this.propagateChange = fn;
	}

	registerOnTouched() {
		// This is intentional
	}
}
