import { AfterViewChecked, Component, OnInit, ViewChild } from "@angular/core";

import { TabsComponent } from "../commons/components/tabs.component";
import { FaqService } from "./faq.service";
import { Question } from "./question";

declare var $: any;

@Component({
  selector: "in-faq",
  templateUrl: "./faq.component.html",
})
export class FaqComponent implements OnInit, AfterViewChecked {
  @ViewChild(TabsComponent) tabs;
  public questionsPP: Question[] = [];
  public questionsPM: Question[] = [];
  private faqChargee: boolean = false;

  public constructor(private faqService: FaqService) {}

  public ngOnInit(): void {
    this.faqService.questions().subscribe((data) => {
      this.questionsPP = data["questionfaqs"].filter(
        (q) => q.categorie === "PERSONNE_PHYSIQUE"
      );
      this.questionsPM = data["questionfaqs"].filter(
        (q) => q.categorie === "PERSONNE_MORALE"
      );
      this.faqChargee = true;
    });
  }

  public ngAfterViewChecked(): void {
    if (this.faqChargee) {
      this.faqChargee = false;
      $(".js-accordion").accordion();
    }
  }

  activerTab(lien: any, tabFAQ: string): void {
    this.tabs.activeOrCloseTabByName(tabFAQ);
    $(".tabs-liens-faq").find("a").removeClass("active");
    $("#" + lien).addClass("active");
  }
}
