import { Component, OnDestroy, OnInit } from "@angular/core";

import { Subscription } from "rxjs";

import { AuthentificationService } from "../authentification/authentification.service";
import { Utilisateur } from "../authentification/utilisateur";

@Component({
  selector: "in-plan",
  templateUrl: "./plan.component.html",
})
export class PlanComponent implements OnInit, OnDestroy {
  public utilisateur: Utilisateur = null;

  utilisateurSubscription: Subscription;

  constructor(private authentificationService: AuthentificationService) {}

  ngOnInit() {
    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });
  }

  afficherBoutonConnexion(): boolean {
    return this.utilisateur === null;
  }

  afficherBoutonDeconnexion(): boolean {
    return !this.afficherBoutonConnexion();
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
