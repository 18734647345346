import {Pipe, PipeTransform} from '@angular/core';
import * as moment from 'moment';

@Pipe({
    name: 'moment'
})
export class MomentPipe implements PipeTransform {
  transform(value: any, formatInput: string, formatOutput: string): any {
    if (!value) return '';

    if (value instanceof Date && !isNaN(value.valueOf())) {
        return moment(value).format(formatOutput.toUpperCase());
    } else if (!isNaN(value - parseFloat(value))) {
        return moment(value).format(formatOutput.toUpperCase());
    } else if (typeof value === 'string') {
      // 'dd/MM/yyyy HH:mm:ss'
      if (formatInput === 'dd/MM/yyyy HH:mm:ss' && value.length === 19) {
        if (formatOutput === 'yyyy-MM-dd') {
          return value.slice(6,10) + '-' + value.slice(3,5) + '-' + value.slice(0,2);
        } else if (formatOutput === 'yyyy-MM-dd HH:mm:ss') {
          return value.slice(6,10) + '-' + value.slice(3,5) + '-' + value.slice(0,2) + ' ' + value.slice(11,19);
        } else if (formatOutput === 'dd/MM/yyyy') {
          return value.slice(0,10);
        }
      }
      // 'dd/MM/yyyy'
      if (formatInput === 'dd/MM/yyyy' && value.length === 10) {
        if (formatOutput === 'yyyy-MM-dd') {
          return value.slice(6,10) + '-' + value.slice(3,5) + '-' + value.slice(0,2);
        } else if (formatOutput === 'dd/MM/yyyy') {
          return value;
        }
      }
    }

    return '';
  }
}
