import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

/*
 * Méthode de validation.
 */
export function telephoneValidator(): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    if (v && !v.match(/^[0-9]*$/)) {

      return {
        validateTelephone: false,
      };
    }
    return null;
  };
}

@Directive({
  selector: '[telephone][formControlName],[telephone][formControl],[telephone][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => TelephoneValidator), multi: true }
  ]
})
export class TelephoneValidator implements Validator {
  constructor( @Attribute('equals') public equals: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return telephoneValidator()(c);
  }
}
