import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '../../commons/commons.module';
import { UtilisateursRoutingModule } from './utilisateurs.routing';
import { GroupesModule } from './groupes/groupes.module';

import { GroupeService } from './groupes/groupe.service';
import { ProfilService } from './profils/profil.service';

import { UtilisateursComponent } from './utilisateurs.component';

@NgModule({
	declarations: [UtilisateursComponent],
	exports: [UtilisateursComponent],
	imports: [CommonModule, UtilisateursRoutingModule, CommonsModule, FormsModule, ReactiveFormsModule, GroupesModule],
	providers: [GroupeService, ProfilService],
	bootstrap: [],
})
export class UtilisateursModule {}
