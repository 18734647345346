import { Injectable } from '@angular/core';
import { Http, URLSearchParams, RequestOptions } from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Utilisateur } from '../../../authentification/utilisateur';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

export class RechercheProfil {
    identifiant: string;
}

@Injectable()
export class ProfilService {

  constructor(private http: Http) {
  }

  profils(recherche: RechercheProfil): Observable<Utilisateur[]> {
    console.log(`Recherche des utilisateurs`);

    const parameters: URLSearchParams = new URLSearchParams();

    if (recherche.identifiant) {
      parameters.set('identifiant', recherche.identifiant);
    }

    return this.http.get('/api/authentification/utilisateur/admin/users', {
      search: parameters,
    }).map(r => r.json() as Utilisateur[]);
  }

  public detailProfil(identifiant: string): Observable<Utilisateur> {
    console.log(`[ProfilService] Lecture de l'utilisateur identifiant `, identifiant);
    return this.http.get(`/api/authentification/utilisateur/${identifiant}`)
      .map(r => r.json() as Utilisateur);
  }

  public createProfil(profil: Utilisateur): Promise<boolean> {
    console.log(`[ProfilService] Creation de l'utilisateur `, profil);
    return new Promise((resolve, reject) => {
      this.http
        .post('/api/authentification/utilisateur/admin/addUser', profil)
        .toPromise()
        .then((response) => resolve(response.status === 200 || response.status === 201))
        .catch(reject);
    });
  }

  public modifyProfil(profil: Utilisateur): Promise<boolean> {
    console.log(`[ProfilService] Modification de l'utilisateur ${profil.identifiant}`, profil);
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/authentification/utilisateur/admin/modifierUtilisateur/${profil.identifiant}`, profil)
        .subscribe(response => {
            resolve(response.status === 200);
      }, err => {
          reject(err);
      });
        
    });
  }
  
   public modifierProfil(profil: Utilisateur): Promise<boolean> {
    console.log(`[ProfilService] Modification de l'utilisateur ${profil.identifiant}`, profil);
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/authentification/utilisateur/admin/updateUser/${profil.identifiant}`, profil)
        .subscribe(response => {
            resolve(response.status === 200);
      }, err => {
          reject(err);
      });
        
    });
  }

  public deleteProfil(profil: Utilisateur): Promise<boolean> {
    console.log(`[ProfilService] Suppression de l'utilisateur ${profil.identifiant}`, profil);
    return new Promise((resolve, reject) => {
      const options = new RequestOptions({ body: profil });
      this.http
        .delete(`/api/authentification/utilisateur/admin/delUser/${profil.identifiant}`, options)
        .toPromise()
        .then((response) => resolve(response.status === 200 || response.status === 204))
        .catch(reject);
    });
  }
}
