import { Component } from "@angular/core";
import { FormBuilder, Validators } from "@angular/forms";

import * as moment from "moment";

import { ListeFiltreeComponent } from "../../commons/components/liste-filtree.component";
import { CsvService } from "../../commons/csv.service";
import { MomentPipe } from "../../commons/pipes/moment.pipe";
import { dateAfterOrEqualsValidator } from "../../commons/validators/date-after-or-equals.validator";
import { validDate } from "../../commons/validators/valid-date.validator";
import { Commande } from "./commande";
import { CommandeService } from "./commande.service";
import { libellesStatutCommande } from "./commandes.constants";

@Component({
  selector: "in-commandes",
  templateUrl: "./commandes.component.html",
})
export class CommandesComponent extends ListeFiltreeComponent {
  public libellesStatutCommande = libellesStatutCommande;
  public commandes: Commande[] = [];
  /* commandesOrder sert a mémoriser l'ordre des commandes sur la page du tableau pour l'export CSV */
  public commandesOrder = [];

  public valeurAction: string = "";
  public rechercheSoumise: boolean = false;

  public constructor(
    private commandeService: CommandeService,
    private csvService: CsvService,
    private formBuilder: FormBuilder,
    private momentPipe: MomentPipe
  ) {
    super();
  }

  public extraireDonnees(e, settings, json) {
    // This is intentional
  }

  /* Avant que le tableau soit re géréné on réinitialise l'ordre des éléments (car peut potenciellement changer) */
  public onPreDrawCallback(settings) {
    this.commandesOrder = Array();
  }

  /* A chaque ligne générée on stocke comme élément suivant le numéro de commande de la ligne (on connait l'ordre des commandes comme ça ) */
  public onRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
    this.commandesOrder[this.commandesOrder.length] = +aData[1]["@data-sort"];
  }

  public initialiserFiltre(): void {
    // Au moins un filtre est requis
    this.filtreRequis = true;

    // On va chercher les valeurs des filtres (en session par exemple)
    const values: any = this.getFiltreValues();

    // On rempli les valeurs par défaut
    const commandeValue: string = values ? values.commande : null;
    const dateIntegrationDebutValue: moment.Moment =
      values && values.dateIntegrationDebut
        ? moment(values.dateIntegrationDebut)
        : null;
    const dateIntegrationFinValue: moment.Moment =
      values && values.dateIntegrationFin
        ? moment(values.dateIntegrationFin)
        : null;
    const statutValue: string = values ? values.statut : null;
    const traitementValue: string = values ? values.traitement : null;

    // On créé le formulaire
    this.filtresForm = this.formBuilder.group({
      commande: [commandeValue],
      dateIntegrationDebut: [
        dateIntegrationDebutValue,
        Validators.compose([validDate]),
      ],
      dateIntegrationFin: [
        dateIntegrationFinValue,
        Validators.compose([
          validDate,
          dateAfterOrEqualsValidator("dateIntegrationDebut"),
        ]),
      ],
      statut: [statutValue],
      traitement: [traitementValue],
    });

    // Rafraichissement pour les validations entre 2 champs
    this.filtresForm.controls["dateIntegrationDebut"].valueChanges.subscribe(
      (data) =>
        this.filtresForm.controls["dateIntegrationFin"].updateValueAndValidity()
    );
  }

  public initialiserColumnsOrder(): void {
    this.optionsListeFiltree.order = [[2, "desc"]];
    this.optionsListeFiltree.columnDefs = [{ orderable: false, targets: 0 }];
  }

  public switchFiltreVisible() {
    this.filtreVisible = !this.filtreVisible;
  }

  public lancerRecherche(): void {
    console.log(
      `[CommandesComponent] Lancement de la recherche`,
      this.filtresForm.value
    );

    this.commandeService
      .commandes(this.filtresForm.value)
      .subscribe((commandes) => {
        // On reinitialise le tableau
        this.reinitDataTable();

        // On met à jour les commandes
        this.commandes = commandes;
        this.rechercheSoumise = true;
      });
  }

  public tout(traite: boolean): void {
    for (const co of this.commandesOrder) {
      for (const commande of this.commandes) {
        if (co === commande.numeroCommande) {
          commande.selectionne = traite;
        }
      }
    }
  }

  public valider() {
    for (const commande of this.commandes) {
      if (commande.selectionne) {
        if (this.valeurAction === "OUI") {
          this.traiterCommande(commande, true);
        } else {
          this.traiterCommande(commande, false);
        }
        commande.selectionne = false;
      }
    }
  }

  public traiterCommande(commande: Commande, traite: boolean): void {
    console.log(
      `[CommandesComponent] Changement d'état d'une commande.`,
      commande,
      traite
    );
    commande.traite = traite;
    this.commandeService.saveCommande(commande);

    this.refreshDataTableSorts();
  }

  public print(): void {
    window.print();
  }

  public download(): void {
    /* Tableau temporaire des commandes triées, pour ne pas toucher a this.commandes */
    const orderedCommandes: Commande[] = Array();

    const data: any[][] = [];

    /* On parcours les numéros de commande dans l'ordre ou elles sont affichées */
    for (const oc of this.commandesOrder) {
      for (const com of this.commandes) {
        /* On retrouve la commande correspondante pour la placer comme élément suivant de notre tableau ordonné */
        if (com.numeroCommande === oc) {
          orderedCommandes[orderedCommandes.length] = com;
        }
      }
    }

    data.push([
      "Commande",
      "Date Intégration",
      "Résultat intégration fichier",
      "Motif",
      "Nombre lignes OK",
      "Nombre lignes KO",
      "Traité",
    ]);

    /* On utilise le tableau ordonné pour générer le CSV */
    for (const commande of orderedCommandes) {
      data.push([
        commande.nomFichierIntegration,
        commande.dateIntegration === null
          ? ""
          : this.momentPipe.transform(
              commande.dateIntegration,
              "dd/MM/yyyy HH:mm:ss",
              "yyyy-MM-dd"
            ),
        libellesStatutCommande[commande.statutCommande],
        commande.motif,
        commande.nombreLignesOK,
        commande.nombreLignesKO,
        commande.traite ? "O" : "N",
      ]);
    }

    const horodateFichierCsv: string = moment().format("YYYYMMDD_HHmmss");
    this.csvService.creerTelecharger(
      data,
      `commandes_${horodateFichierCsv}.csv`
    );
  }
}
