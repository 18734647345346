import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';

import { StatistiquesNationalesComponent } from '../statnationales/statistiques-nationales.component';

import { StatistiquesNationalesService } from './statistiques-nationales.service';

@NgModule({
  declarations: [
    StatistiquesNationalesComponent
  ],
  exports: [
    StatistiquesNationalesComponent
  ],
  imports: [
    CommonModule
  ],
  providers: [
    StatistiquesNationalesService
  ],
})
export class StatistiquesNationalesModule {  }
