import { NgModule }      from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AlertModule } from 'ngx-bootstrap';
import { HttpModule, Http, XHRBackend, RequestOptions } from '@angular/http';
import { Router } from '@angular/router';

import { AuthentificationModule } from './authentification/authentification.module';
import { DataTablesModule } from 'angular-datatables'; 
import { TransfertModule }  from './transfert/transfert.module';
import { StatistiquesModule }  from './statistiques/statistiques.module';
import { StatistiquesNationalesModule } from './statnationales/statistiques-nationales.module';
import { SuiviModule }  from './suivi/suivi.module';
import { AppRoutingModule } from './organisme.routing';
import { CommonsModule }     from './commons/commons.module';
import { FaqModule } from './faq/faq.module';
import { MentionsModule } from './mentions/mentions.module';
import { CguModule } from './cgu/cgu.module';
import { CgiModule } from './cgi/cgi.module';
import { PlanModule } from './plan/plan.module';
import { AccessibiliteModule } from './accessibilite/accessibilite.module';
import { ContactModule } from './contact/contact.module';
import { AideModule } from './aide/aide.module';
import { UtilisateursModule } from './administrateur/utilisateurs/utilisateurs.module';
import { DonneesModule } from './administrateur/donnees/donnees.module';
import { AdministrateurModule } from './administrateur/administrateur.module';

import { OrganismeComponent }   from './organisme.component';
import { HomeComponent }        from './home.component';
import { SpinnerGeneralComponent } from './spinner-general.component';
import { ErreurServiceComponent }       from './erreur-service.component';
import { SecuredHttpService }   from './authentification/secured-http.service';
import { BreadcrumbsModule, BreadcrumbsService } from 'ng2-breadcrumbs';
import { SpinnerGeneralService } from './spinner-general.service';
import { DummyRouteChangeComponent }    from './components/dummy-route-change.component';

import './commons/liensaccessibles.include';

@NgModule({
  imports: [
    BrowserModule,
    AlertModule.forRoot(),
    AppRoutingModule,
    AuthentificationModule,
    DataTablesModule,
    CommonsModule,
    TransfertModule.forRoot(),
    SuiviModule,
    HttpModule,
    StatistiquesModule,
    StatistiquesNationalesModule,
    FaqModule,
    MentionsModule,
    CguModule,
    CgiModule,
    PlanModule,
    AccessibiliteModule,
    ContactModule,
    AideModule,
    UtilisateursModule,
    DonneesModule,
    AdministrateurModule,
    BreadcrumbsModule,
  ],
  declarations: [
    OrganismeComponent,
    HomeComponent,
    ErreurServiceComponent,
    SpinnerGeneralComponent,
    DummyRouteChangeComponent,
  ],
  bootstrap: [
    OrganismeComponent,
  ],
  providers: [
    BreadcrumbsService,
    SpinnerGeneralService,
    {
      provide: Http,
      useFactory: (backend: XHRBackend, defaultOptions: RequestOptions, router: Router) => {
        return new SecuredHttpService(backend, defaultOptions, router);
      },
      deps: [
        XHRBackend,
        RequestOptions,
        Router,
      ],
    },
  ],
})
export class OrganismeModule {
}
