import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '../commons/commons.module';
import { AdministrateurRoutingModule } from './administrateur.routing';
import { UtilisateursModule } from './utilisateurs/utilisateurs.module';
import { DonneesModule } from './donnees/donnees.module';
import { RessourcesModule } from './ressources/ressources.module';

import { AuthentificationToolsModule } from '../authentification/authentification-tools.module';

import { AdministrateurService } from './administrateur.service';
import { ImageService } from '../commons/image.service';

import { AdministrateurComponent } from './administrateur.component';

@NgModule({
	declarations: [AdministrateurComponent],
	exports: [AdministrateurComponent],
	imports: [CommonModule, AdministrateurRoutingModule, CommonsModule, FormsModule, ReactiveFormsModule, UtilisateursModule, DonneesModule, RessourcesModule, AuthentificationToolsModule],
	providers: [AdministrateurService, ImageService],
	bootstrap: [],
})
export class AdministrateurModule {}
