import { NgModule }      from '@angular/core';
import { AideComponent } from './aide.component';
import { AideService } from './aide.service';

import { CommonModule }       from '@angular/common';

@NgModule({
  declarations: [AideComponent],
  exports: [AideComponent],
  imports: [
    CommonModule,
  ],
  bootstrap: [],
    providers: [AideService],
})
export class AideModule {  }
