export const DROITS = {
  TRANSFERT: 'transfert',
  COMMANDES: 'commandes',
  DOUBLONS: 'doublons',
  DEMANDES: 'demandes',
  STATISTIQUES: 'statistiques',
  STATNATIONALES: 'statnationales',
  ADMINISTRATEUR: 'administrateur',
  MESDONNEES: 'mesdonnees',
  CONTACT: 'contact',
  AIDE: 'aide',
  FAQ: 'faq',
  BENEFICIAIRE: 'beneficiaire'
};
