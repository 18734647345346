import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";

import { AuthentificationService } from "./authentification.service";
import { Utilisateur } from "./utilisateur";

@Component({
  selector: "in-login-oublie",
  templateUrl: "./mot-de-passe-oublie.component.html",
})
export class MotDePasseOublieComponent implements OnInit {
  public utilisateur: Utilisateur;

  public soumis: boolean = false;
  public succes: boolean = true;
  public compteBloque: boolean = false;

  public motDePasseForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder
  ) {}

  ngOnInit(): void {
    this.motDePasseForm = this.formBuilder.group({
      login: ["", Validators.compose([Validators.required])],
    });
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();

    if (this.motDePasseForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.motDePasseForm.controls) {
        if (typeof control === "string") {
          this.motDePasseForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  valider(): void {
    this.compteBloque = false;

    this.authentificationService
      .motDePasseOublie(this.motDePasseForm.controls["login"].value)
      .then((succes) => {
        this.soumis = true;
        this.succes = succes;
      })
      .catch((err) => {
        this.soumis = true;
        this.succes = false;
        if (err.status === 401 && err._body === "COMPTE_BLOQUE") {
          this.compteBloque = true;
        }
      });
  }
}
