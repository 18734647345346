import { Injectable } from '@angular/core';
import { Http } from '@angular/http';

import { Organisme } from './organisme';
import { AuthentificationService } from '../authentification/authentification.service';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class AdministrateurService {

  constructor(private http: Http,
              private authentificationService: AuthentificationService) {
  }

    /**
     * Méthode a appeler pour récupérer l'identifiant de l'organisme
     */
     
     identifiantOrganisme(): string {
         if(this.authentificationService.hasPayload()) {
             return this.authentificationService.getPayload().organisme;
         }else {
             return null;
         }
     }

  /**
  * Méthode à appeler pour récupérer les informations de l'organisme
  */
  informationsOrganisme(): Promise<Organisme> {

    // identifiant de l'organisme présent dans le payload du token
    if (this.authentificationService.hasPayload()) {
      const payload: any = this.authentificationService.getPayload();
      const identifiantOrganisme: string = payload.organisme;
      console.log('[AdministrateurService] identifiantOrganisme', identifiantOrganisme);
      return this.http.get(`/api/organismeBeneficiaire/organisme/code/${identifiantOrganisme}`)
        .toPromise()
        .then(response => response.json() as Organisme);
    } else {

      const organismeParDefaut: Organisme = {
        id: 0,
        nom: 'MDPH59',
        adresse: {
          destinataire: 'MDPH59',
          adresse1: '11 RUE DU PARC',
          adresse2: 'PARC SAINT SULPICE',
          adresse3: '',
          adresse4: '',
          commune: 'SECLIN',
          codePostal: '59113',
          departement: 'Pas de calais',
          pays: 'FRANCE',
        },
        signature: '',
        logo: '',
        recevoirNotification: false,
      };

      console.log('[AdministrateurService] Payload indéterminé.');
      return Promise.resolve(organismeParDefaut);
    }
  }

  public modifyOrganisme(organisme: Organisme): Promise<boolean> {
    console.log(`[AdministrateurService] Modification de l'organisme ${organisme.nom}`, organisme);
    return new Promise((resolve, reject) => {
        this.http
            .put(`/api/organismeBeneficiaire/organisme/${organisme.id}`, organisme)
            .toPromise()
            .then((response) => resolve(response.status === 200))
            .catch(reject);
    });
  }

  public controleAntivirus(dataUnsafe: string): Promise<string> {
    return new Promise((resolve, reject) => {
      this.http.post('/api/antivirus', {
        'data': dataUnsafe,
      }).subscribe(response => {
        if ( response.status !== 200 ) {
          reject('Contrôle antivirus échoué');
        } else {
          resolve(response.json());
        }
      }, err => reject(err));
    });
  }
}
