import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

/*
 * Méthode de validation.
 */
export function dateAfterOrEqualsValidator(dateAfter: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = <moment.Moment>c.value;
    const e = c.root.get(dateAfter);

    if (e && e.value && v && v.isBefore(e.value, 'day')) {
      return {
        dateAfterOrEquals: false
      };
    }
    return null;
  };
}

@Directive({
  selector: '[dateAfterOrEquals][formControlName],[dateAfterOrEquals][formControl],[dateAfterOrEquals][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateAfterOrEqualsValidator), multi: true }
  ]
})
export class DateAfterOrEqualsValidator implements Validator {
  constructor(@Attribute('dateAfterOrEquals') public dateAfter: string) {}

  validate(c: AbstractControl): { [key: string]: boolean } {
    return dateAfterOrEqualsValidator(this.dateAfter)(c);
  }
}
