import { NgModule }                     from '@angular/core';
import { Routes, RouterModule }         from '@angular/router';

import { GroupesComponent }             from './groupes.component';
import { CreerModifierGroupeComponent } from './creer-modifier-groupe.component';

import { CanActivateADroitGuard }       from '../../../authentification/a-droit.guard';
import { DROITS }                       from '../../../authentification/droits.constants';

export const routes: Routes = [
  {
    path: '',
    component: GroupesComponent
  },
  {
    path: 'creer-groupe',
    component: CreerModifierGroupeComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Création de groupe',
      breadcrumb: 'Création d\'un groupe',
      menu: 'creergroupe',
      droit: DROITS.ADMINISTRATEUR,
    },
  },
  {
    path: ':id',
    component: CreerModifierGroupeComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Modification d\'un groupe',
      menu: 'modifgroupe',
      droit: DROITS.ADMINISTRATEUR,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class GroupesRoutingModule { }
