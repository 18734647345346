import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MenuComponent }        from './menu.component';
import { CommandesComponent }   from './commandes/commandes.component';
import { DetailComponent }      from './commandes/detail.component';
import { DoublonsComponent }    from './doublons/doublons.component';
import { DemandesComponent }    from './demandes/demandes.component';
import { DetailDemandeComponent } from './demandes/detaildemande.component';

import { CanActivateADroitGuard } from '../authentification/a-droit.guard';
import { DROITS }                 from '../authentification/droits.constants';

export const routes: Routes = [
  {
    path: '',
    component: MenuComponent
  },
  {
    path: 'commandes',
    component: CommandesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Suivi des commandes',
      breadcrumb: 'Suivi des commandes',
      menu: 'suivicommandes',
      droit: DROITS.COMMANDES,
    },
  },
  {
    path: 'commandes/:id',
    component: DetailComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Détail \'une commande',
      menu: 'suivicommandes',
      droit: DROITS.COMMANDES,
    },
  },
  {
    path: 'doublons',
    component: DoublonsComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Gestion des doublons',
      breadcrumb: 'Gestion des doublons',
      menu: 'suividemandes',
      droit: DROITS.DOUBLONS,
    },
  },
  {
    path: 'demandes',
    component: DemandesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Gestion des demandes',
      breadcrumb: 'Gestion des demandes',
      menu: 'suividemandes',
      droit: DROITS.DEMANDES,
    },
  },
  {
    path: 'demandes/:id',
    component: DetailDemandeComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      title: 'Détail \'une demande',
      menu: 'suividemandes',
      droit: DROITS.DEMANDES,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SuiviRoutingModule { }
