import { Injectable } from '@angular/core';
import { Http }       from '@angular/http';
import { Observable } from 'rxjs/Observable';

@Injectable()
export class PropertiesService {

  constructor(private http: Http) {
  }

  public getValeur(cle: string): Observable<string> {
    return this.http.get('/api/properties')
                    .map(r => r.json()[cle]);
  }
}
