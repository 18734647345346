import { Injectable } from '@angular/core';
import { Http, URLSearchParams }       from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Doublon  } from './doublon';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

export class RechercheDoublon {
  commande: string;
  statut: string;
  typeTitre: string;
  action: string;
  raisonSociale: string;
}

@Injectable()
export class DoublonService {

  constructor(private http: Http) {
  }

  doublons(recherche: RechercheDoublon): Observable<Doublon[]> {
    console.log(`[CommandeService] Recherche des commandes`);

    const parameters: URLSearchParams = new URLSearchParams();
    if (recherche.commande) {
      parameters.set('commande', recherche.commande);
    }
    if (recherche.typeTitre) {
      parameters.set('typeTitre', recherche.typeTitre);
    }
    if (recherche.action) {
      parameters.set('action', recherche.action);
    }
    if (recherche.raisonSociale) {
      parameters.set('raisonSociale', recherche.raisonSociale);
    }

    return this.http.get('/api/commandeDemande/doublon', {
        search: parameters,
      })
      .map(r => r.json() as Doublon[]);
  }

  public saveDoublon(doublon: Doublon): Promise<boolean> {
    console.log(`[CommandeService] Enregistrement de ${doublon.idDemande}`);
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/commandeDemande/doublon/${doublon.idDemande}`, doublon)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

}
