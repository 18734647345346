import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Subscription } from "rxjs";

import { AdministrateurService } from "../../administrateur/administrateur.service";
import { Organisme } from "../../administrateur/organisme";
import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/utilisateur";
import { emailValidator } from "../../commons/validators/email.validator";
import { equalsValidator } from "../../commons/validators/equals.validator";
import { passwordValidator } from "../../commons/validators/password.validator";
import { ProfilService } from "../utilisateurs/profils/profil.service";

declare var $: any;

@Component({
  selector: "in-donnees",
  templateUrl: "./donnees.component.html",
})
export class DonneesComponent implements OnInit, OnDestroy {
  public utilisateur: Utilisateur = null;
  public utilisateurRequete: Utilisateur = null;
  public organisme: Organisme = null;
  public modifierAdresseEmailForm: FormGroup;
  public modifierMotDePasseForm: FormGroup;
  public modifierCoordonneesTelephoniquesForm: FormGroup;
  public soumisAdresseEmail: boolean = false;
  public succesAdresseEmail: boolean = true;
  public soumisMotDePasse: boolean = false;
  public succesMotDePasse: boolean = true;
  public ancienIncorrect: boolean = false;
  public emailExistant: boolean = false;
  public adresseMailInvalide: boolean = false;
  public soumisCoordonneesTelephoniques: boolean = false;
  public succesCoordonneesTelephoniques: boolean = true;
  public showPassword = { oldPass: false, newPass: false, newPassBis: false };

  utilisateurSubscription: Subscription;

  public constructor(
    private profilService: ProfilService,
    private authentificationService: AuthentificationService,
    private administrateurService: AdministrateurService
  ) {}

  public ngOnInit(): void {
    this.modifierAdresseEmailForm = new FormGroup({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          emailValidator(),
        ])
      ),
      emailBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("email")])
      ),
    });
    this.modifierAdresseEmailForm.controls["email"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseEmailForm.controls[
          "emailBis"
        ].updateValueAndValidity()
    );

    this.modifierMotDePasseForm = new FormGroup({
      oldPass: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      newPass: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
        ])
      ),
      newPassBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")])
      ),
    });
    this.modifierMotDePasseForm.controls["oldPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls["newPass"].updateValueAndValidity()
    );
    this.modifierMotDePasseForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );

    this.modifierCoordonneesTelephoniquesForm = new FormGroup({
      telephone: new FormControl(
        "",
        Validators.compose([Validators.maxLength(19)])
      ),
      mobile: new FormControl(
        "",
        Validators.compose([Validators.maxLength(19)])
      ),
    });

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        if (this.utilisateur != null) {
          this.initSoumissionForm();
        }

        this.administrateurService.informationsOrganisme().then((organisme) => {
          this.organisme = organisme;
        });
      });
  }

  private initSoumissionForm(): void {
    this.soumisAdresseEmail = false;
    this.soumisMotDePasse = false;
    this.soumisCoordonneesTelephoniques = false;
    this.utilisateurRequete = Object.assign({}, this.utilisateur);

    this.modifierAdresseEmailForm = new FormGroup({
      email: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          emailValidator(),
        ])
      ),
      emailBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("email")])
      ),
    });
    this.modifierAdresseEmailForm.markAsPristine();
    this.modifierAdresseEmailForm.controls["email"].valueChanges.subscribe(
      (data) =>
        this.modifierAdresseEmailForm.controls[
          "emailBis"
        ].updateValueAndValidity()
    );

    this.modifierMotDePasseForm = new FormGroup({
      oldPass: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.minLength(1)])
      ),
      newPass: new FormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          passwordValidator(),
        ])
      ),
      newPassBis: new FormControl(
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")])
      ),
    });
    this.modifierMotDePasseForm.markAsPristine();
    this.modifierMotDePasseForm.controls["oldPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls["newPass"].updateValueAndValidity()
    );
    this.modifierMotDePasseForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.modifierMotDePasseForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );
    this.showPassword = { oldPass: false, newPass: false, newPassBis: false };

    this.modifierCoordonneesTelephoniquesForm = new FormGroup({
      telephone: new FormControl(
        this.utilisateur.telephone,
        Validators.compose([Validators.maxLength(19)])
      ),
      mobile: new FormControl(
        this.utilisateur.mobile,
        Validators.compose([Validators.maxLength(19)])
      ),
    });
    this.modifierCoordonneesTelephoniquesForm.markAsPristine();
  }

  public modifierAdresseEmail(): void {
    $("#mon-adresse-email-affichee").addClass("undisplayable");
    $("#mon-adresse-email-modifiable").removeClass("undisplayable");
    this.initSoumissionForm();
  }

  onSubmitAdresseEmailForm(event) {
    event.stopPropagation();

    if (this.modifierAdresseEmailForm.valid) {
      this.utilisateurRequete.adresseMail =
        this.modifierAdresseEmailForm.value.email;

      this.enregistrerDonneesUtilisateur("AdresseEmail");

      $("#mon-adresse-email-affichee").removeClass("undisplayable");
      $("#mon-adresse-email-modifiable").addClass("undisplayable");
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierAdresseEmailForm.controls) {
        if (typeof control === "string") {
          this.modifierAdresseEmailForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  public modifierMotDePasse(): void {
    $("#mon-mot-de-passe-affiche").addClass("undisplayable");
    $("#mon-mot-de-passe-modifiable").removeClass("undisplayable");
    this.initSoumissionForm();
  }

  onSubmitMotDePasse(event) {
    event.stopPropagation();

    if (this.modifierMotDePasseForm.valid) {
      this.enregistrerMotDePasse();

      $("#mon-mot-de-passe-affiche").removeClass("undisplayable");
      $("#mon-mot-de-passe-modifiable").addClass("undisplayable");
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierMotDePasseForm.controls) {
        if (typeof control === "string") {
          this.modifierMotDePasseForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  public modifierCoordonneesTelephoniques(): void {
    $("#mes-coordonnees-telephoniques-affichees").addClass("undisplayable");
    $("#mes-coordonnees-telephoniques-modifiables").removeClass(
      "undisplayable"
    );
    this.initSoumissionForm();
  }

  onSubmitCoordonneesTelephoniquesForm(event) {
    event.stopPropagation();

    if (this.modifierCoordonneesTelephoniquesForm.valid) {
      this.utilisateurRequete.telephone =
        this.modifierCoordonneesTelephoniquesForm.value.telephone;
      this.utilisateurRequete.mobile =
        this.modifierCoordonneesTelephoniquesForm.value.mobile;

      this.enregistrerDonneesUtilisateur("CoordonneesTelephoniques");

      $("#mes-coordonnees-telephoniques-affichees").removeClass(
        "undisplayable"
      );
      $("#mes-coordonnees-telephoniques-modifiables").addClass("undisplayable");
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierCoordonneesTelephoniquesForm
        .controls) {
        if (typeof control === "string") {
          this.modifierCoordonneesTelephoniquesForm.controls[
            control
          ].markAsTouched();
        }
      }
    }

    return false;
  }

  atteindreMessages(ancre: string) {
    const element = document.querySelector(ancre);
    element.scrollIntoView(true);
  }

  enregistrerDonneesUtilisateur(nomFormulaire: string): void {
    this.adresseMailInvalide = false;

    this.profilService
      .modifyProfil(this.utilisateurRequete)
      .then((succes) => {
        if (nomFormulaire === "AdresseEmail") {
          this.soumisAdresseEmail = true;
          this.succesAdresseEmail = succes;
        } else {
          this.soumisCoordonneesTelephoniques = true;
          this.succesCoordonneesTelephoniques = succes;
        }
        this.atteindreMessages("#messages" + nomFormulaire);
        if (succes) {
          this.utilisateur = this.utilisateurRequete;
          this.authentificationService.pushUtilisateurConnecte(
            this.utilisateurRequete
          );
        }
      })
      .catch((err) => {
        if (nomFormulaire === "AdresseEmail") {
          this.soumisAdresseEmail = true;
          this.succesAdresseEmail = false;
          if (err.status === 406) {
            if (err["_body"] === "ADRESSE_MAIL_INVALIDE") {
              this.adresseMailInvalide = true;
            } else {
              this.adresseMailInvalide = false;
            }
          } else if (err.status === 409) {
            this.emailExistant = true;
          } else {
            this.emailExistant = false;
          }
        } else {
          this.soumisCoordonneesTelephoniques = true;
          this.succesCoordonneesTelephoniques = false;
        }
        this.atteindreMessages("#messages" + nomFormulaire);
      });
  }

  enregistrerMotDePasse(): void {
    this.authentificationService
      .modifMotDePasse(
        this.modifierMotDePasseForm.controls["oldPass"].value,
        this.modifierMotDePasseForm.controls["newPass"].value,
        this.modifierMotDePasseForm.controls["newPassBis"].value
      )
      .then((succes) => {
        this.soumisMotDePasse = true;
        this.succesMotDePasse = true;

        this.atteindreMessages("#messagesMotDePasse");
      })
      .catch((err) => {
        if (err.status === 406) {
          this.ancienIncorrect = true;
        } else {
          this.ancienIncorrect = false;
        }

        this.soumisMotDePasse = true;
        this.succesMotDePasse = false;
        this.atteindreMessages("#messagesMotDePasse");
      });
  }

  toggleShowPassword(password: "oldPass" | "newPass" | "newPassBis"): void {
    this.showPassword[password] = !this.showPassword[password];
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
