import { Component } from "@angular/core";

import { ListeFiltreeComponent } from "../../commons/components/liste-filtree.component";
import { TransfertService } from "../transfert.service";
import { TransfertResultDTO } from "./dtos/transfert-result-dto";

@Component({
  selector: "in-resultat-controle-transfert",
  templateUrl: "./resultat-controle-transfert.component.html",
})
export class ResultatControleTransfertComponent extends ListeFiltreeComponent {
  transfertResult: TransfertResultDTO;

  public constructor(private transfertService: TransfertService) {
    super();

    this.transfertService.getTransfertResult().subscribe((obj) => {
      this.transfertResult = obj;
    });
  }

  public initialiserFiltre(): void {
    // This is intentional
  }

  public initialiserColumnsOrder(): void {
    this.optionsListeFiltree.order = [];
  }

  // This is intentional
  public extraireDonnees(e, settings, json) {
    // This is intentional
  }

  public lancerRecherche(): void {
    // This is intentional
  }
}
