import { NgModule }             from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthentificationComponent }    from './authentification/authentification.component';
import { PremiereConnexionComponent }   from './authentification/premiere-connexion.component';
import { NouveauMotPasseComponent }     from './authentification/nouveau-mot-passe.component';
import { LoginOublieComponent }     from './authentification/login-oublie.component';
import { MotDePasseOublieComponent }     from './authentification/mot-de-passe-oublie.component';
import { StatistiquesComponent}         from './statistiques/statistiques.component';
import { StatistiquesNationalesComponent } from './statnationales/statistiques-nationales.component';
import { AideComponent }                from './aide/aide.component';
import { ContactComponent }             from './contact/contact.component';
import { FaqComponent }                 from './faq/faq.component';
import { MentionsLegalesComponent }     from './mentions/mentions-legales.component';
import { CguComponent }                 from './cgu/cgu.component';
import { CgiComponent }                 from './cgi/cgi.component';
import { PlanComponent }                from './plan/plan.component';
import { AccessibiliteComponent }       from './accessibilite/accessibilite.component';

import { HomeComponent }                from './home.component';
import { ErreurServiceComponent }       from './erreur-service.component';
import { DummyRouteChangeComponent }    from './components/dummy-route-change.component';

import { CanActivateADroitGuard }       from './authentification/a-droit.guard';
import { DROITS }                       from './authentification/droits.constants';

export const routes: Routes = [
  {
    path: '',
    component: HomeComponent
  },
  {
    path: 'dummyRouteChange',
    component: DummyRouteChangeComponent,
    data: {
      breadcrumb: 'Chargement en cours...'
    }
  },
  {
    path: 'erreurService',
    component: ErreurServiceComponent,
    data: {
      breadcrumb: 'Service indisponible'
    }
  },
  {
    path: 'authentification',
    component: AuthentificationComponent,
    data: {
      breadcrumb: 'Authentification'
    }
  },
  {
    path: 'authentification/expired',
    component: AuthentificationComponent,
    data: {
      breadcrumb: ''
    }
  },
  {
    path: 'authentification/nouveauMotDePasse',
    component: NouveauMotPasseComponent,
    data: {
      breadcrumb: 'Nouveau mot de passe'
    }
  },
  {
    path: 'authentification/loginOublie',
    component: LoginOublieComponent,
    data: {
      breadcrumb: 'Identifiant oublié'
    }
  },
  {
    path: 'authentification/motDePasseOublie',
    component: MotDePasseOublieComponent,
    data: {
      breadcrumb: 'Mot de passe oublié'
    }
  },
  {
    path: 'statistiques',
    component: StatistiquesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      menu: 'statistiques',
      droit: DROITS.STATISTIQUES,
      breadcrumb: 'Statistiques'
    },
  },
  {
    path: 'statnationales',
    component: StatistiquesNationalesComponent,
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      menu: 'statnationales',
      droit: DROITS.STATNATIONALES,
      breadcrumb: 'Statistiques Nationales'
    },
  },
  {
    path: 'suivi',
    loadChildren: './suivi/suivi.module#SuiviModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      breadcrumb: 'Suivi des demandes'
    }
  },
  {
    path: 'beneficiaire',
    loadChildren: './beneficiaire/beneficiaire.module#BeneficiaireModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      menu: 'beneficiaire',
      droit: DROITS.BENEFICIAIRE,
      breadcrumb: 'Données Bénéficiaires'
    },
  },
  {
    path: 'demande',
    loadChildren: './demande/demande.module#DemandeModule',
    data: {
      menu: 'demande',
      breadcrumb: 'Demande'
    },
  },
  {
    path: 'transfert',
    loadChildren: './transfert/transfert.module#TransfertModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      menu: 'transfert',
      droit: DROITS.TRANSFERT,
      breadcrumb: 'Transfert'
    },
  },
  {
    path: 'administrateur',
    loadChildren: './administrateur/administrateur.module#AdministrateurModule',
    canActivate: [
      CanActivateADroitGuard,
    ],
    data: {
      menu: 'administrateur',
      breadcrumb: 'Administration'
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Contact'
    }
  },
  {
    path: 'aide',
    component: AideComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Aide'
    }
  },
  {
    path: 'faq',
    component: FaqComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'FAQ'
    }
  },
  {
    path: 'mentionsLegales',
    component: MentionsLegalesComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Mentions légales'
    }
  },
  {
    path: 'cgu',
    component: CguComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Conditions Générales d\'Utilisation'
    }
  },
  {
    path: 'cgi',
    component: CgiComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Conditions Générales d\'Instruction'
    }
  },
  {
    path: 'plan',
    component: PlanComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Plan du site'
    }
  },
  {
    path: 'accessibilite',
    component: AccessibiliteComponent,
    canActivate: [
      CanActivateADroitGuard
    ],
    data: {
      breadcrumb: 'Accessibilité'
    }
  },
  {
    path: 'premiereConnexion',
    component: PremiereConnexionComponent,
    data: {
      breadcrumb: 'Première connexion'
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule { }
