import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { environment } from "src/environments/environment";
import { AdministrateurService } from "../administrateur/administrateur.service";
import { Organisme } from "../administrateur/organisme";
import { AuthentificationService } from "../authentification/authentification.service";
import { Droit } from "../authentification/droit";
import { Utilisateur } from "../authentification/utilisateur";
import { ImageService } from "../commons/image.service";

@Component({
  selector: "in-header",
  templateUrl: "./header.component.html",
})
export class HeaderComponent implements OnInit {
  public utilisateur: Utilisateur = null;
  public organisme: Organisme = null;
  public imageLogoOrganisme: string;
  public droits: Droit[] = [];
  public menuActif: string;
  public urlContenu: string;
  public urlMenu: string;
  public titreSite: string;

  constructor(
    private authentificationService: AuthentificationService,
    private administrateurService: AdministrateurService,
    private router: Router,
    private imageService: ImageService
  ) {}

  ngOnInit() {
    this.titreSite = environment.title;

    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.router.routerState.root.firstChild)
      .flatMap((r) => r.data)
      .subscribe((data) => {
        this.menuActif = data["menu"];
        this.urlContenu = this.router.url.replace(/#.*/, "") + "#contenu";
        this.urlMenu = this.router.url.replace(/#.*/, "") + "#menu";
        console.log("[HeaderComponent] Menu actif : ", this.menuActif);
      });

    this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
        this.administrateurService.informationsOrganisme().then((organisme) => {
          this.organisme = organisme;
          if (this.organisme) {
            this.imageLogoOrganisme = this.imageService.addPrefixeImage(
              this.organisme.logo
            );
          }
        });
      });

    this.authentificationService
      .droitsUtilisateur()
      .subscribe((droits) => (this.droits = droits));
  }

  deconnecter(): void {
    this.authentificationService.deconnecter();
    this.router.navigate(["/authentification"]);
  }

  afficherBoutonConnexion(): boolean {
    return this.utilisateur === null;
  }

  afficherBoutonDeconnexion(): boolean {
    return !this.afficherBoutonConnexion();
  }

  afficherBoutonSeConnecter(): boolean {
    const route: string = this.router.url.split("#")[0];
    return !route.endsWith("/authentification");
  }
}
