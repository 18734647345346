import { NgModule }        from '@angular/core';
import { CguComponent }    from './cgu.component';
import { CommonModule }       from '@angular/common';

@NgModule({
  declarations: [CguComponent],
  exports: [CguComponent],
  imports: [
    CommonModule
  ],
  providers: [ ],
})
export class CguModule { }
