import { Component, OnInit } from "@angular/core";
import { FormGroup } from "@angular/forms";
import { Router } from "@angular/router";

import { PropertiesService } from "../commons/properties.service";
import { SpinnerGeneralService } from "../spinner-general.service";
import { TransfertService } from "./transfert.service";

declare var $: any;

const templateMessageErreur =
  "Le fichier n'a pas pu être importé suite __MOTIF__, veuillez recommencer votre transfert";

@Component({
  selector: "in-transfert",
  templateUrl: "./transfert.component.html",
})
export class TransfertComponent implements OnInit {
  transfertForm: FormGroup;
  maxSizeUploadFichierCommande: string;

  constructor(
    private router: Router,
    private props: PropertiesService,
    private transfertService: TransfertService,
    private spinnerService: SpinnerGeneralService
  ) {
    props
      .getValeur("transfert.validator.maxSizeUploadFichierCommande")
      .subscribe((valeur) => (this.maxSizeUploadFichierCommande = valeur));

    this.transfertService.reset();
    this.transfertService.transfertResult.subscribe((result) => {
      if (result != null) {
        this.spinnerService.hide();
        this.router.navigate(["/transfert/resultatsControle"]);
      }
    });
  }

  ngOnInit() {
    $("#parcourir").on("change", function () {
      const fileSplit = $("#parcourir").val().toString().split("\\");
      $("#fichier").val(fileSplit[fileSplit.length - 1]);
    });
    $("#parcourir").on("focus", function () {
      $("#btnParcourir").addClass("focus");
    });
    $("#parcourir").on("blur", function () {
      $("#btnParcourir").removeClass("focus");
    });
  }

  onSubmit(me: MouseEvent) {
    me.stopPropagation();
    console.log("envoi en cours");

    const fileInput = <HTMLInputElement>$("#parcourir")[0];

    const fileSplit = fileInput.value.split("\\");

    $("#fichier").val(fileSplit[fileSplit.length - 1]);
    if (fileInput.files.length > 0) {
      $("#progressBar").removeClass("invisible");
      $("#messageEnCours").removeClass("undisplayable");
      $("#messageErreur").addClass("undisplayable");
      $("#messageFinOk").addClass("undisplayable");
      $("#progressBar")
        .find(".progress-bar")
        .css("width", "0")
        .attr("aria-valuenow", 0);

      const filesize = fileInput.files[0].size;
      let fileMaxsize = 81920; // Valeur par defaut 80 Ko
      if (this.maxSizeUploadFichierCommande) {
        fileMaxsize = parseInt(this.maxSizeUploadFichierCommande, 0);
      }
      console.log(
        "verification filesize=" + filesize + " <= fileMaxsize=" + fileMaxsize
      );
      if (filesize > fileMaxsize) {
        const motif = "à un fichier trop volumineux";
        $("#messageErreur").text(
          templateMessageErreur.replace("__MOTIF__", motif)
        );
        $("#messageErreur").removeClass("undisplayable");
        $("#progressBar").addClass("invisible");
        $("#messageEnCours").addClass("undisplayable");
      }

      this.transfertService.sendFileBis();
    } else {
      $("#messageErreur").text("Aucun fichier sélectionné");
      $("#messageErreur").removeClass("undisplayable");
    }

    return false;
  }
}
