import { Component } from '@angular/core';
import { BreadcrumbsService }        from 'ng2-breadcrumbs';

@Component({
  selector: 'in-dummy-route-change',
  template: ''
})
export class DummyRouteChangeComponent {

  public constructor(private breadcrumbService: BreadcrumbsService) {
    breadcrumbService.store([{label: 'Chargement en cours...', url: '/dummyRouteChange', params: []}]);
  }
}
