import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RessourcesComponent } from './ressources.component';
import { ProfilService } from '../utilisateurs/profils/profil.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '../../commons/commons.module';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
	declarations: [RessourcesComponent],
	exports: [RessourcesComponent],
	imports: [CommonModule, CommonsModule, FormsModule, ReactiveFormsModule, ComponentsModule],
	providers: [ProfilService],
	bootstrap: [],
})
export class RessourcesModule {}
