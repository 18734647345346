import { Component, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";

import { Subscription } from "rxjs";

import { AdministrateurService } from "../../administrateur/administrateur.service";
import { Organisme } from "../../administrateur/organisme";
import { AuthentificationService } from "../../authentification/authentification.service";
import { Utilisateur } from "../../authentification/utilisateur";
import { ImageService } from "../../commons/image.service";

const templateMessageErreur =
  "L'image doit respecter la règle « __MOTIF__ », veuillez selectionner une autre image.";

declare var $: any;

@Component({
  selector: "in-ressources",
  templateUrl: "./ressources.component.html",
})
export class RessourcesComponent implements OnInit, OnDestroy {
  @ViewChild("selecteurParcourirSignature") selecteurParcourirSignature;
  @ViewChild("selecteurParcourirLogo") selecteurParcourirLogo;
  @ViewChild("selecteurImageSignature") selecteurImageSignature;
  @ViewChild("selecteurImageLogo") selecteurImageLogo;

  public utilisateur: Utilisateur = null;
  public organisme: Organisme = null;
  public organismeRequete: Organisme = null;
  public imageLogoOrganisme: string = "";
  public imageSignatureOrganisme: string = "";
  public modifierRessourcesOrganismeForm: FormGroup;
  public modifierImagesOrganismeForm: FormGroup;
  public soumis: boolean = false;
  public succes: boolean = true;
  public imageSignatureModifiee: boolean = false;
  public imageLogoModifiee: boolean = false;
  public codeRetourImages = null;

  fr: FileReader;
  private image: HTMLImageElement;

  utilisateurSubscription: Subscription;

  public constructor(
    private imageService: ImageService,
    private authentificationService: AuthentificationService,
    private administrateurService: AdministrateurService
  ) {}

  public ngOnInit(): void {
    this.initFocusParcourir("SignatureImage");
    this.initFocusParcourir("LogoImage");

    this.modifierRessourcesOrganismeForm = new FormGroup({
      nom: new FormControl("", Validators.compose([Validators.maxLength(64)])),
    });

    this.modifierImagesOrganismeForm = new FormGroup({
      signatureImage: new FormControl("", null),
      logoImage: new FormControl("", null),
    });

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;

        this.administrateurService.informationsOrganisme().then((organisme) => {
          this.organisme = organisme;
          this.organisme.id =
            this.authentificationService.getIdentifiantOrganismeUtilisateur();
          this.imageLogoOrganisme = this.imageService.addPrefixeImage(
            this.organisme.logo
          );
          this.imageSignatureOrganisme = this.imageService.addPrefixeImage(
            this.organisme.signature
          );
          if (this.organisme != null) {
            this.initSoumissionForm();
          }
        });
      });
  }

  choisirSignature(e) {
    e.stopPropagation();
    e.preventDefault();
    $(this.selecteurParcourirSignature.nativeElement).click();
  }

  choisirLogo(e) {
    e.stopPropagation();
    e.preventDefault();
    $(this.selecteurParcourirLogo.nativeElement).click();
  }

  supprimerLogo(e) {
    e.stopPropagation();
    e.preventDefault();
    //this.modifierImagesOrganismeForm.controls.logoImage.value = '';
    this.selecteurImageLogo.nativeElement.src = "";
    this.imageLogoModifiee = true;
  }

  onImageSelectSignature() {
    const input = this.selecteurParcourirSignature.nativeElement;
    const image = this.selecteurImageSignature.nativeElement;
    const minwidth = 394;
    const minheight = 189;
    const resolution = 400;
    const file = input.files[0];
    if (file === undefined) {
      return;
    }
    this.imageSignatureModifiee = this.onImageSelect(file, 30); // 30 Ko maxi., définition 189x394 pixels mini, proportionnelle.
    if (this.imageSignatureModifiee) {
      const that = this;
      this.imageService.getTypeMIME(file).then((mime) => {
        if (mime !== "image/jpeg") {
          console.log("format image MIME jpeg requis");
          const motif = "format JPEG";
          $("#messageErreur").text(
            templateMessageErreur.replace("__MOTIF__", motif)
          );
          $("#messageErreur").removeClass("undisplayable");
          return false;
        }

        const third = that;
        that.fr = new FileReader();
        that.fr.onload = function () {
          third.image = new Image();
          third.image.onload = function () {
            const img = <HTMLImageElement>this;
            if (img.width < minwidth || img.height < minheight) {
              console.log(
                "définition " + img.width + " x " + img.height + " trop faible"
              );
              const motif =
                "la définition doit être supérieure ou égale à " +
                minwidth +
                "x" +
                minheight +
                " pixels pour " +
                resolution +
                " DPI";
              $("#messageErreur").text(
                templateMessageErreur.replace("__MOTIF__", motif)
              );
              $("#messageErreur").removeClass("undisplayable");
              third.imageSignatureModifiee = false;
              return false;
            }
            /* multiplie et divise par 100 pour avoir le ratio arrondi sur 2 décimales */
            const ratio = Math.round((minheight / minwidth) * 100) / 100;
            const ratioNouvelleImage =
              Math.round((img.height / img.width) * 100) / 100;
            console.log(
              "définition " +
                img.width +
                " x " +
                img.height +
                ", ratio = " +
                ratioNouvelleImage
            );
            if (ratio > ratioNouvelleImage) {
              const motif =
                "la définition doit être supérieure ou égale à " +
                minwidth +
                "x" +
                minheight +
                " pixels pour " +
                resolution +
                " DPI";
              $("#messageErreur").text(
                templateMessageErreur.replace("__MOTIF__", motif)
              );
              $("#messageErreur").removeClass("undisplayable");
              third.imageLogoModifiee = false;
              return false;
            }
            image.src = img.src;
            let newWidth = img.width;
            let newHeight = img.height;
            if (newWidth > 200) {
              newHeight = newHeight * (200 / newWidth);
              newWidth = 200;
            }
            if (newHeight > 150) {
              newWidth = newWidth * (150 / newHeight);
              newHeight = 150;
            }
            image.width = newWidth;
            image.height = newHeight;
          };
          that.image.src = that.fr.result;
        };
        that.fr.readAsDataURL(file);
        $("#messageErreur").addClass("undisplayable");
      });
    }
  }

  onImageSelectLogo() {
    const input = this.selecteurParcourirLogo.nativeElement;
    const image = this.selecteurImageLogo.nativeElement;
    const minwidth = 402;
    const minheight = 331;
    const resolution = 300;
    const file = input.files[0];
    if (file === undefined) {
      return;
    }
    this.imageLogoModifiee = this.onImageSelect(file, 40); // 40 Ko maxi., définition 402x331 pixels mini, non proportionnelle.
    if (this.imageLogoModifiee) {
      const that = this;
      this.imageService.getTypeMIME(file).then((mime) => {
        if (mime !== "image/jpeg") {
          console.log("format image MIME jpeg requis");
          const motif = "format JPEG";
          $("#messageErreur").text(
            templateMessageErreur.replace("__MOTIF__", motif)
          );
          $("#messageErreur").removeClass("undisplayable");
          return false;
        }

        const third = that;
        that.fr = new FileReader();
        that.fr.onload = function () {
          third.image = new Image();
          third.image.onload = function () {
            const img = <HTMLImageElement>this;
            if (img.width < minwidth && img.height < minheight) {
              console.log(
                "définition " + img.width + " x " + img.height + " trop faible"
              );
              const motif =
                "la définition doit être supérieure ou égale à " +
                minwidth +
                "x" +
                minheight +
                " pixels pour " +
                resolution +
                " DPI";
              $("#messageErreur").text(
                templateMessageErreur.replace("__MOTIF__", motif)
              );
              $("#messageErreur").removeClass("undisplayable");
              third.imageLogoModifiee = false;
              return false;
            }
            image.src = img.src;
            let newWidth = img.width;
            let newHeight = img.height;
            if (newWidth > 200) {
              newHeight = newHeight * (200 / newWidth);
              newWidth = 200;
            }
            if (newHeight > 150) {
              newWidth = newWidth * (150 / newHeight);
              newHeight = 150;
            }
            image.width = newWidth;
            image.height = newHeight;
          };
          that.image.src = that.fr.result;
        };
        that.fr.readAsDataURL(file);
        $("#messageErreur").addClass("undisplayable");
      });
    }
  }

  private onImageSelect(file: any, maxsize: number): boolean {
    console.log("change ", file);
    const imageMaxSize = maxsize * 1024;
    console.log(
      "verification imagesize=" + file.size + " <= imageMaxsize=" + imageMaxSize
    );
    if (file.size > imageMaxSize) {
      console.log("image trop grande");
      const motif = "taille maximale du fichier <= " + maxsize + " Ko";
      $("#messageErreur").text(
        templateMessageErreur.replace("__MOTIF__", motif)
      );
      $("#messageErreur").removeClass("undisplayable");
      return false;
    }

    if (file.type !== "image/jpeg") {
      console.log("format image jpeg requis");
      const motif = "format JPEG";
      $("#messageErreur").text(
        templateMessageErreur.replace("__MOTIF__", motif)
      );
      $("#messageErreur").removeClass("undisplayable");
      return false;
    }

    return true;
  }

  private initFocusParcourir(idInputFile: string): void {
    $("#parcourir" + idInputFile).on("focus", function () {
      $("#btnParcourir" + idInputFile).addClass("focus");
    });
    $("#parcourir" + idInputFile).on("blur", function () {
      $("#btnParcourir" + idInputFile).removeClass("focus");
    });
  }

  private initSoumissionForm(): void {
    this.soumis = false;
    this.organismeRequete = Object.assign({}, this.organisme);

    this.modifierRessourcesOrganismeForm = new FormGroup({
      nom: new FormControl(
        this.organisme.nom,
        Validators.compose([Validators.maxLength(64)])
      ),
      signatureImage: new FormControl("", null),
      logoImage: new FormControl("", null),
    });
    this.modifierRessourcesOrganismeForm.markAsPristine();
  }

  public modifierRessourcesOrganisme(e): void {
    e.preventDefault();
    e.stopPropagation();
    $("#ressources-organisme-affichees").addClass("undisplayable");
    $("#ressources-organisme-modifiables").removeClass("undisplayable");
  }

  onSubmitRessourcesOrganismeForm(event) {
    event.stopPropagation();

    if (this.modifierRessourcesOrganismeForm.valid) {
      this.organismeRequete.nom =
        this.modifierRessourcesOrganismeForm.value.nom;

      if (this.imageSignatureModifiee || this.imageLogoModifiee) {
        if (this.imageSignatureModifiee) {
          this.organismeRequete.signature =
            this.selecteurImageSignature.nativeElement.src.split(",")[1];
          // Exemple image avec virus
          // this.organismeRequete.signature = 'data:image/jpg;base64,WDVPIVAlQEFQWzRcUFpYNTQoUF4pN0NDKTd9JEVJQ0FSLVNUQU5EQVJELUFOVElWSVJVUy1URVNULUZJTEUhJEgrSCoK';
        }

        if (this.imageLogoModifiee) {
          this.organismeRequete.logo =
            this.selecteurImageLogo.nativeElement.src.split(",")[1];
          if (this.organismeRequete.logo === undefined) {
            this.organismeRequete.logo = "";
          }
        }
      }

      this.enregistrerDonneesOrganisme();

      $("#ressources-organisme-affichees").removeClass("undisplayable");
      $("#ressources-organisme-modifiables").addClass("undisplayable");
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.modifierRessourcesOrganismeForm.controls) {
        if (typeof control === "string") {
          this.modifierRessourcesOrganismeForm.controls[
            control
          ].markAsTouched();
        }
      }
    }

    return false;
  }

  atteindreMessages(ancre: string) {
    const element = document.querySelector(ancre);
    element.scrollIntoView(true);
  }

  erreurEnregistrer(): void {
    this.soumis = true;
    this.succes = false;

    this.atteindreMessages("#messages");
  }

  enregistrerDonneesOrganisme(): void {
    console.log(`[RessourcesComponent] Controle antivirus du logo`);
    this.administrateurService
      .controleAntivirus(this.organismeRequete.logo)
      .then((reponse1) => {
        console.log(
          `[RessourcesComponent] Controle antivirus du logo `,
          reponse1
        );
        if (!this.traiterRetourAntivirus(reponse1)) {
          this.erreurEnregistrer();
          return;
        }

        console.log(`[RessourcesComponent] Controle antivirus de la signature`);
        this.administrateurService
          .controleAntivirus(this.organismeRequete.signature)
          .then((reponse2) => {
            console.log(
              `[RessourcesComponent] Controle antivirus de la signature `,
              reponse2
            );
            if (!this.traiterRetourAntivirus(reponse2)) {
              this.erreurEnregistrer();
              return;
            }

            this.administrateurService
              .modifyOrganisme(this.organismeRequete)
              .then((succes) => {
                this.soumis = true;
                this.succes = succes;

                this.atteindreMessages("#messages");
                if (succes) {
                  this.organisme = this.organismeRequete;
                  this.imageSignatureModifiee = false;
                  this.imageLogoModifiee = false;
                }
              })
              .catch((err) => {
                this.erreurEnregistrer();
              });
          })
          .catch((err) => {
            this.erreurEnregistrer();
          });
      })
      .catch((err) => {
        this.erreurEnregistrer();
      });
  }

  private traiterRetourAntivirus(codeRetourAV: string): boolean {
    let estFichierSain = false;
    this.codeRetourImages = codeRetourAV;
    switch (codeRetourAV) {
      case "OK":
        estFichierSain = true;
        break;
      case "FICHIER_MANQUANT":
        estFichierSain = true;
        break;
      case "ERREUR_PREFIXE_IMAGE":
        estFichierSain = false;
        break;
      case "VIRUS_DETECTE":
        estFichierSain = false;
        break;
      case "ERREUR_ANTIVIRUS":
        estFichierSain = false;
        break;
      default:
        estFichierSain = false;
        break;
    }
    return estFichierSain;
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
