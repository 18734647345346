import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { HttpModule }         from '@angular/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { CommonsModule }      from '../commons/commons.module';

import { StatistiquesComponent } from './statistiques.component';
import { DemandeTitreValideComponent } from './pagination/demande-titre-valide.component';

import { StatistiquesRoutingModule } from './statistiques.routing';

import { StatistiquesService } from './statistiques.service';

@NgModule({
  declarations: [
    StatistiquesComponent,
    DemandeTitreValideComponent
  ],
  exports: [
    StatistiquesComponent,
    DemandeTitreValideComponent
  ],
  imports: [
    CommonModule,
    HttpModule,
    CommonsModule,
    FormsModule,
    ReactiveFormsModule,
    StatistiquesRoutingModule
  ],
  providers: [
    StatistiquesService
  ],
})
export class StatistiquesModule {  }
