import { Injectable } from '@angular/core';
import { Http, URLSearchParams }       from '@angular/http';

import * as moment from 'moment';

import { StatutDemandesTitreData } from './dto/statut-demandes-titre-data';
import { SuiviTitreAnswer } from './dto/suivi-titre-answer';
import { SuiviDemandeTitreValideAnswer } from './dto/suivi-demande-titre-valide-answer';
import { StatutAppelPhotoAnswer } from './dto/statut-appel-photo-answer';
import { SuiviConnexionsAnswer } from './dto/suivi-connexions-answer';
import { DelaisMoyensAnswer } from './dto/delais-moyens-answer';

@Injectable()
export class StatistiquesService {

  constructor(private http: Http) {
  }

  /**
  * Méthode à appeler pour les statistiques StatutDemandeTitre
  */
  statutDemandeTitre(): Promise<StatutDemandesTitreData[]> {
    console.log('[StatistiquesService] statutDemandeTitre');
    return this.http.get(`/api/cmi-statistics/statsorganisme/statutdemandetitre`)
      .toPromise()
      .then(response => response.json() as StatutDemandesTitreData[]);
  }

  /**
  * Méthode à appeler pour les statistiques SuiviTitre
  */
  suiviTitre(dateDebut: moment.Moment, dateFin: moment.Moment): Promise<SuiviTitreAnswer> {
    console.log('[StatistiquesService] suiviTitre', dateDebut, dateFin);

    const parameters: URLSearchParams = new URLSearchParams();
    if (dateDebut) {
      parameters.set('dateIntegrationDebut', dateDebut.format('YYYY-MM-DD'));
    }
    if (dateFin) {
      parameters.set('dateIntegrationFin', dateFin.format('YYYY-MM-DD'));
    }
    return this.http.get(`/api/cmi-statistics/statsorganisme/suivititre`, {
        search: parameters,
      }).toPromise()
        .then(response => response.json() as SuiviTitreAnswer);
  }

  /**
  * Méthode à appeler pour les statistiques SuiviDemandeTitreValide
  */
  suiviDemandeTitreValide(): Promise<SuiviDemandeTitreValideAnswer> {
    console.log('[StatistiquesService] suiviDemandeTitreValide');
    return this.http.get(`/api/cmi-statistics/statsorganisme/suividemandetitrevalide`)
        .toPromise()
        .then(response => response.json() as SuiviDemandeTitreValideAnswer);
  }

  /**
  * Méthode à appeler pour les statistiques StatutAppelPhoto
  */
  statutAppelPhoto(dateDebut: moment.Moment, dateFin: moment.Moment): Promise<StatutAppelPhotoAnswer> {
    console.log('[StatistiquesService] statutAppelPhoto', dateDebut, dateFin);

    const parameters: URLSearchParams = new URLSearchParams();
    if (dateDebut) {
      parameters.set('dateDebut', dateDebut.format('YYYY-MM-DD'));
    }
    if (dateFin) {
      parameters.set('dateFin', dateFin.format('YYYY-MM-DD'));
    }
    return this.http.get(`/api/cmi-statistics/statsorganisme/statutappelphoto`, {
        search: parameters,
      }).toPromise()
        .then(response => response.json() as StatutAppelPhotoAnswer);
  }

  /**
  * Méthode à appeler pour les statistiques SuiviConnexions
  */
  suiviConnexions(dateDebut: moment.Moment, dateFin: moment.Moment): Promise<SuiviConnexionsAnswer> {
    console.log('[StatistiquesService] suiviConnexions', dateDebut, dateFin);

    const parameters: URLSearchParams = new URLSearchParams();
    if (dateDebut) {
      parameters.set('dateDebut', dateDebut.format('YYYY-MM-DD'));
    }
    if (dateFin) {
      parameters.set('dateFin', dateFin.format('YYYY-MM-DD'));
    }
    return this.http.get(`/api/cmi-statistics/statsorganisme/suiviconnexions`, {
        search: parameters,
      }).toPromise()
        .then(response => response.json() as SuiviConnexionsAnswer);
  }

  /**
  * Méthode à appeler pour les statistiques DelaisMoyens
  */
  delaisMoyens(): Promise<DelaisMoyensAnswer> {
    console.log('[StatistiquesService] delaisMoyens');
    return this.http.get(`/api/cmi-statistics/statsorganisme/delaismoyens`)
        .toPromise()
        .then(response => response.json() as DelaisMoyensAnswer);
  }

}
