import { Injectable } from '@angular/core';
import { Http, URLSearchParams }       from '@angular/http';
import { Observable } from 'rxjs/Observable';
import * as moment from 'moment';

import { Commande } from './commande';
import { Erreur } from './erreur';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

export class RechercheCommande {
  commande: string;
  dateIntegrationDebut: moment.Moment;
  dateIntegrationFin: moment.Moment;
  statut: string;
  traitement: string;
}

@Injectable()
export class CommandeService {

  constructor(private http: Http) {
  }

  public commandes(recherche: RechercheCommande): Observable<Commande[]> {
    console.log(`[CommandeService] Recherche des commandes`);

    const parameters: URLSearchParams = new URLSearchParams();
    if (recherche.commande) {
      parameters.set('commande', recherche.commande);
    }
    if (recherche.dateIntegrationDebut) {
      parameters.set('dateIntegrationDebut', recherche.dateIntegrationDebut.format('YYYY-MM-DD'));
    }
    if (recherche.dateIntegrationFin) {
      parameters.set('dateIntegrationFin', recherche.dateIntegrationFin.format('YYYY-MM-DD'));
    }
    if (recherche.statut) {
      parameters.set('statut', recherche.statut);
    }
    if (recherche.traitement) {
      parameters.set('traitement', recherche.traitement);
    }

    return this.http.get('/api/commandeDemande/commande', {
        search: parameters,
      })
      .map(r => r.json() as Commande[]);
  }

  public detail(id: string): Observable<Commande> {
    console.log(`[CommandeService] Recherche du détail de ${id}`);
    return this.http.get(`/api/commandeDemande/commande/${id}`)
      .map(r => r.json() as Commande);
  }

  public erreurs(id: string): Observable<Erreur[]> {
    console.log(`[CommandeService] Recherche des erreurs de ${id}`);
    return this.http.get(`/api/commandeDemande/commande/${id}/erreur`)
      .map(r => r.json() as Erreur[]);
  }

  public saveCommande(commande: Commande): Promise<boolean> {
    console.log(`[CommandeService] Enregistrement de ${commande.numeroCommande}`);
    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/commandeDemande/commande/${commande.numeroCommande}`, commande)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }
}
