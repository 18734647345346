import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

/*
 * Méthode de validation.
 */
export function adressePostaleValidator(pays: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    
    const domcom = ['','dom/com','france','guadeloupe','mayotte','la réunion','martinique','guyane','saint barthélémy','saint martin','saint-pierre et miquelon'];

    if (pays && pays !== '') {
      const e = c.root.get(pays);
      if (e && (e.value === '' || e.value) && domcom.indexOf(e.value.toLowerCase()) > -1) {
        if (!v || v === '' || !v.match(/^[0-9]{5}$/)) {
          return {
            validateAdresse: false,
          };
        }
      } else if (v && v !== '') {
          return {
            validateCodePostalVide: false,
          };
      }
    } else {
      if (v && !v.match(/^[a-zA-Z0-9! ""$%&'()*+,-.\/:<=>?@\[\]^_{|}~ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖ×ØÙÚÛÜÝÞßŠŒŸŽàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿšœž€‚ƒ„…†‡ˆ‰‹‘’“”•–—˜™›¡¢£¤¥¦§¨©ª«¬¬®¯°±²³´µ¶•¸¹º»¼½¾¿÷]+$/)) {
        return {
          validateAdresse: false,
        };
      }
    }
    return null;
  };
}

@Directive({
  selector: '[adressePostale][formControlName],[adressePostale][formControl],[adressePostale][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => AdressePostaleValidator), multi: true }
  ]
})
export class AdressePostaleValidator implements Validator {
  constructor( @Attribute('adressePostale') public adressePostale: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return adressePostaleValidator(this.adressePostale)(c);
  }
}
