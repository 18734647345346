import { Injectable, OnDestroy } from '@angular/core';

import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { TransfertResultDTO } from './resultats/dtos/transfert-result-dto';

import { AuthentificationService } from '../authentification/authentification.service';
import { Utilisateur } from '../authentification/utilisateur';

import { SpinnerGeneralService } from '../spinner-general.service';

import { Http } from '@angular/http';
import { Subscription } from 'rxjs';

const templateMessageErreur = "Le fichier n'a pas pu être importé suite __MOTIF__, veuillez recommencer votre transfert";

declare var $: any;

@Injectable()
export class TransfertService implements OnDestroy {
	utilisateur: Utilisateur;
	transfertResult: BehaviorSubject<TransfertResultDTO> = new BehaviorSubject<TransfertResultDTO>(null);
	private fileInput: HTMLInputElement;

	utilisateurSubscription: Subscription;

	constructor(private http: Http, private authentificationService: AuthentificationService, private spinnerService: SpinnerGeneralService) {
		this.utilisateurSubscription = this.authentificationService.utilisateurConnecte().subscribe((u) => {
			this.utilisateur = u;
		});
	}

	getTransfertResult(): Observable<TransfertResultDTO> {
		return this.transfertResult.asObservable();
	}

	reset(): void {
		this.transfertResult = new BehaviorSubject<TransfertResultDTO>(null);
	}

	sendFileBis(): Observable<TransfertResultDTO> {
		const that = this;
		const tmp = new Observable<TransfertResultDTO>((obs) => {
			this.fileInput = <HTMLInputElement>$('#parcourir')[0];
			const xhr = new XMLHttpRequest();

			xhr.open('POST', '/api/transfert');

			// On récupère le token
			const token: string = sessionStorage.getItem('token');
			xhr.setRequestHeader('Authorization', 'Bearer ' + token);
			xhr.setRequestHeader('Login', this.utilisateur.identifiant);
			if (this.authentificationService.hasPayload()) {
				const payload: any = this.authentificationService.getPayload();
				const identifiantOrganisme: string = payload.organisme;
				xhr.setRequestHeader('Emetteur', identifiantOrganisme);
			}

			xhr.upload.addEventListener('progress', function (pe: ProgressEvent) {
				const val = ((pe.loaded / pe.total) * 100 + '').split('.')[0];
				$('#progressBar')
					.find('.progress-bar')
					.css('width', val + '%')
					.attr('aria-valuenow', val);
				$('#pourcentageAvancement').text(val);

				if ((pe.loaded / pe.total) * 100 === 100) {
					$('#phraseAvancement').text('Contrôle du fichier en cours...');
					that.spinnerService.show();
				}
			});

			xhr.addEventListener('load', function (e) {
				const event = <XMLHttpRequest>e.target;
				$('#progressBar').addClass('invisible');
				$('#messageEnCours').addClass('undisplayable');

				if (event.status === 200) {
					const res = JSON.parse(event.response) as TransfertResultDTO;
					console.log('Transfert de fichier terminé avec succès. Enchainement avec page résultat.');
					$('#messageFinOk').removeClass('undisplayable');
					obs.next(res);
				} else if (event.status === 202) {
					const res = JSON.parse(event.response) as TransfertResultDTO;
					const tabResultat = res.resultat.split(';');
					const codeResultat = tabResultat[0];
					let messageResultat = '';
					if (tabResultat.length > 1) {
						messageResultat = tabResultat[1];
					}
					if (codeResultat === 'UPLOAD_OK' || codeResultat === '000') {
						console.log('Transfert de fichier terminé avec succès. Message sur la page transfert, pas de page résultat.');
						$('#messageErreur').addClass('undisplayable');
						$('#messageFinOk').removeClass('undisplayable');
					} else {
						console.log('Transfert de fichier terminé en échec. Resultat=[' + codeResultat + ']. Message sur la page transfert, pas de page résultat.');
						let motif = 'indéterminé';
						switch (codeResultat) {
							case '900':
								motif = 'à une erreur système';
								break;
							case '901':
								motif = 'à un nommage incorrect du fichier';
								break;
							case '902':
								motif = 'à un fichier trop volumineux';
								break;
							case '903':
								motif = 'à un virus détecté';
								break;
							case '904':
								motif = 'à un code émetteur incohérent';
								break;
							case '905':
								motif = 'à un nombre de lignes trop important';
								break;
							case 'UPLOAD_KO':
								motif = 'à une erreur lors du transfert';
								break;
							case 'UPLOAD_UNACCOMPLISHED':
								motif = 'à un transfert non réalisé';
								break;
							case 'UPLOAD_DISABLED':
								motif = 'à un transfert désactivé';
								break;
							default:
								motif = 'indéterminé';
								break;
						}
						// Surcharge éventuelle par un motif remonté du controller
						if (messageResultat !== '') {
							motif = messageResultat;
						}
						$('#messageFinOk').addClass('undisplayable');
						$('#messageErreur').text(templateMessageErreur.replace('__MOTIF__', motif));
						$('#messageErreur').removeClass('undisplayable');
					}
					that.spinnerService.hide();
				} else {
					const motif = 'à une erreur système';
					$('#messageFinOk').addClass('undisplayable');
					$('#messageErreur').text(templateMessageErreur.replace('__MOTIF__', motif));
					$('#messageErreur').removeClass('undisplayable');
					that.spinnerService.hide();
				}
			});

			xhr.addEventListener('error', function () {
				alert('erreur');
				$('#messageEnCours').addClass('undisplayable');
			});

			const form = new FormData();
			form.append('file', this.fileInput.files[0]);

			xhr.send(form);
		});

		tmp.subscribe((res) => this.transfertResult.next(res));

		return this.transfertResult;
	}

	ngOnDestroy() {
		this.utilisateurSubscription.unsubscribe();
	}
}
