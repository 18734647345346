import { NgModule }      from '@angular/core';

import { CommonModule }       from '@angular/common';
import { MentionsLegalesComponent } from './mentions-legales.component';
import { MentionsService } from './mentions.service';

@NgModule({
  declarations: [MentionsLegalesComponent],
  exports: [MentionsLegalesComponent],
  imports: [
    CommonModule
  ],
  providers: [MentionsService]
})
export class MentionsModule {  }
