import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute } from "@angular/router";

import { Utilisateur } from "../../authentification/utilisateur";
import { DemandeTitre } from "./demande-titre";

import { BreadcrumbsService } from "ng2-breadcrumbs";
import { Subscription } from "rxjs";
import { AuthentificationService } from "../../authentification/authentification.service";
import { DemandeService } from "./demande.service";

@Component({
  selector: "in-detail-demande",
  templateUrl: "./detaildemande.component.html",
})
export class DetailDemandeComponent implements OnInit, OnDestroy {
  public numeroDemande: string;
  public demande: DemandeTitre;
  public demandeForm: FormGroup;
  public soumis: boolean = false;
  public succes: boolean = false;
  public inactive: boolean = false;
  public erreurMotifCarteInactiveOblig: boolean = false;
  public utilisateur: Utilisateur = null;

  utilisateurSubscription: Subscription;

  public constructor(
    private demandeService: DemandeService,
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private authentificationService: AuthentificationService,
    private title: Title
  ) {}

  public ngOnInit(): void {
    this.numeroDemande = this.route.snapshot.params["id"];

    this.demande = new DemandeTitre();

    this.utilisateurSubscription = this.authentificationService
      .utilisateurConnecte()
      .subscribe((utilisateur) => {
        this.utilisateur = utilisateur;
      });

    this.getDetailDemande(this.numeroDemande);

    const title = this.getNameForNumberDemande(this.numeroDemande);

    this.breadcrumbService.store([
      { label: "Suivi des demandes", url: "/suivi", params: [] },
      { label: "Gestion des demandes", url: "/suivi/demandes", params: [] },
      { label: title, url: "/suivi/demandes/[0-9]*", params: [] },
    ]);

    this.demandeForm = new FormGroup({
      inactive: new FormControl(),
      motif: new FormControl(),
      PNDPhoto: new FormControl(),
      PNDTitre: new FormControl(),
    });
  }

  private getDetailDemande(numDemande: string) {
    this.demandeService.detail(numDemande).subscribe((demande) => {
      this.demande = demande;
      this.inactive = demande.inactive;
    });
  }

  private controleDemandeForm(): boolean {
    this.erreurMotifCarteInactiveOblig = false;
    if (this.demandeForm.value.inactive && !this.demandeForm.value.motif) {
      this.erreurMotifCarteInactiveOblig = true;
      return false;
    }
    return true;
  }

  public enregistrerModification(event: MouseEvent): void {
    if (this.controleDemandeForm()) {
      if (this.demandeForm.value.PNDTitre) {
        this.demande.traitePNDTitre = true;
      }

      if (this.demandeForm.value.PNDPhoto) {
        this.demande.traitePNDPhoto = true;
      }

      if (this.demandeForm.value.inactive) {
        this.demande.inactive = true;
        this.demande.motif = this.demandeForm.value.motif;

        this.demandeService.revoquerTitre(this.demande).then((succes) => {
          if (this.succes) {
            this.inactive = this.demande.inactive;
          }
        });
      }

      this.demandeService
        .saveDemande(this.demande)
        .then((succes) => {
          this.soumis = true;
          this.succes = succes;
          this.atteindreMessages();
        })
        .catch((err) => {
          this.soumis = true;
          this.succes = false;
          this.atteindreMessages();
        });

      setTimeout(() => this.getDetailDemande(this.numeroDemande), 500);
    }
  }

  private getNameForNumberDemande(id: string): string {
    return "Détail de la demande numéro " + id;
  }

  atteindreMessages() {
    const element = document.querySelector("#messages");
    element.scrollIntoView(true);
  }

  ngOnDestroy() {
    this.utilisateurSubscription.unsubscribe();
  }
}
