import { Injectable } from '@angular/core';
import { Http }       from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { Question  } from './question';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class FaqService {

  constructor(private http: Http) {
  }

  questions(): Observable<Question[]> {
    console.info(`Recherche des questions de la faq`);
    return this.http.get('/api/questionfaqs').map(r => r.json() as Question[]);
  }
}
