import { NgModule }        from '@angular/core';
import { CgiComponent }    from './cgi.component';
import { CommonModule }       from '@angular/common';
import { RouterModule }       from '@angular/router';

@NgModule({
  declarations: [CgiComponent],
  exports: [CgiComponent],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [ ],
})
export class CgiModule { }
