import { Injectable } from '@angular/core';
import { Http, URLSearchParams }       from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { DemandeTitre  } from './demande-titre';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

import * as moment from 'moment';

export class RechercheDemande {
    numeroDemande: string;
    nom: string;
    typeTitre: string;
    statutDemande: string;
    statutTitre: string;
    finValidite: moment.Moment;
}

@Injectable()
export class DemandeService {

  constructor(private http: Http) {
  }

  public detail(id: string): Observable<DemandeTitre> {
    console.log(`[DemandeService] Recherche du détail de ${id}`);
    return this.http.get(`/api/organismeBeneficiaire/titre/demandeTitre/${id}`)
      .map(r => r.json() as DemandeTitre);
  }

  demandes(recherche: RechercheDemande): Observable<DemandeTitre[]> {
    console.info(`Recherche des demandes`);

    const parameters: URLSearchParams = new URLSearchParams();

    if (recherche.numeroDemande) {
      parameters.set('demande', recherche.numeroDemande);
    }
    if (recherche.nom) {
      parameters.set('nom', recherche.nom);
    }
    if (recherche.typeTitre) {
      parameters.set('typeTitre', recherche.typeTitre);
    }
    if (recherche.statutDemande) {
      parameters.set('statutDemande', recherche.statutDemande);
    }
    if (recherche.statutTitre) {
      parameters.set('statutTitre', recherche.statutTitre);
    }
    if (recherche.finValidite) {
      parameters.set('finValidite', recherche.finValidite.format('YYYY-MM-DD'));
    }

    return this.http.get('/api/organismeBeneficiaire/titre/demandeTitre', {
      search: parameters,
    }).map(r => r.json() as DemandeTitre[]);
  }

  public saveDemande(demande: DemandeTitre): Promise<boolean> {
    console.log(`[DemandeService] Enregistrement de ${demande.numeroTitre}`);

    return new Promise((resolve, reject) => {
      this.http
        .put(`/api/organismeBeneficiaire/titre/demandeTitre/${demande.numeroTitre}`, demande)
        .toPromise()
        .then((response) => resolve(response.status === 200))
        .catch(reject);
    });
  }

  public revoquerTitre(titre: DemandeTitre): Promise<boolean> {
        return new Promise((resolve, reject) => {
          this.http.put('/api/organismeBeneficiaire/titre/revocation', titre)
          .toPromise()
          .then((response) => resolve(response.status === 200))
          .catch(reject);
        });
  }



}
