import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";

import * as moment from "moment";

import { StatNationale } from "./dto/stat-nationale";
import { StatistiquesNationalesService } from "./statistiques-nationales.service";

@Component({
  selector: "in-statistiques-nationales",
  templateUrl: "./statistiques-nationales.component.html",
  styleUrls: ["./statistiques-nationales.component.css"],
})
export class StatistiquesNationalesComponent implements OnInit {
  public anneeActuelle: moment.Moment;
  public anneeEnCours: moment.Moment;
  public desactiverBoutonAnneePrecedente: boolean;
  public desactiverBoutonAnneeSuivante: boolean;
  public listestatnat: StatNationale[] = [];

  public constructor(
    private router: Router,
    private statistiquesNationalesService: StatistiquesNationalesService
  ) {
    this.statistiquesNationalesService
      .disponibilite()
      .then((statsnat) => {
        this.listestatnat = statsnat;
        if (this.listestatnat != null) {
          console.log(
            "[StatistiquesNationalesComponent] listestatnat",
            this.listestatnat
          );
        }
      })
      .catch((err) => {
        console.log(
          "[StatistiquesNationalesComponent] Erreur liste rapports disponibles",
          err
        );
        this.router.navigate(["/erreurService"]);
      });
  }

  public ngOnInit(): void {
    this.anneeActuelle = moment();
    this.anneeEnCours = moment();
    this.verifierActivationBoutonsAnneePrecedenteEtSuivante();
  }

  changerAnnee(increment: number): void {
    this.anneeEnCours = this.anneeEnCours.add(increment, "year").clone();
    this.verifierActivationBoutonsAnneePrecedenteEtSuivante();
  }

  verifierActivationBoutonsAnneePrecedenteEtSuivante(): void {
    const anneeLimite = this.anneeActuelle.clone().add(-2, "year");
    this.desactiverBoutonAnneePrecedente =
      this.anneeEnCours.isSameOrBefore(anneeLimite);
    this.desactiverBoutonAnneeSuivante = this.anneeEnCours.isSameOrAfter(
      this.anneeActuelle
    );
  }

  disponible(type: string, mois: number): boolean {
    const ls = this.listestatnat.filter(
      (s) =>
        s.annee === this.anneeEnCours.get("year") &&
        s.type === type &&
        ((s.frequence === "ANNUEL" && mois === 0) ||
          (s.frequence === "MENSUEL" && s.mois === mois))
    );
    return ls && ls.length > 0;
  }

  telecharger(type: string, mois: number): void {
    const freq = mois >= 1 && mois <= 12 ? "MENSUEL" : "ANNUEL";
    const rapportsAnnuel = this.listestatnat.filter(
      (s) =>
        s.annee === this.anneeEnCours.get("year") &&
        s.type === type &&
        s.frequence === "ANNUEL"
    );
    const moisAnnuel =
      rapportsAnnuel && rapportsAnnuel.length > 0
        ? rapportsAnnuel[0].mois.toString()
        : "0";
    const moisStat = freq === "MENSUEL" ? mois.toString() : moisAnnuel;
    const fm = freq + "_" + moisStat;

    this.statistiquesNationalesService
      .getRapport(
        this.anneeEnCours.get("year").toString(),
        moisStat,
        type,
        freq
      )
      .then((reponseRapport) => {
        console.log(
          "[StatistiquesNationalesComponent] reponseRapport",
          reponseRapport
        );
        const fileName = "statistiquesNationales_" + type + "_" + fm + ".xlsx";
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(reponseRapport.blob(), fileName);
        } else {
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(reponseRapport.blob());
          link.download = fileName;
          document.body.appendChild(link);
          link.click();

          // On libère la mémoire après 1s
          setTimeout(() => {
            document.body.removeChild(link);
            window.URL.revokeObjectURL(link.href);
          }, 1000);
        }
      });
  }
}
