import { Adresse } from '../../commons/dtos/adresse';

export class Enumeration {
  valeur: string;
  libelleCourt: string;
}

export class DemandeTitre {
  identifiantBeneficiaire: number;
  nom: string;
  prenom: string;
  dateNaissance: string;
  lieuNaissance: string;
  raisonSociale: string;
  immatriculation: string;
  adresseLivraison: Adresse;
  mention: Enumeration;
  sousMention: Enumeration;
  statutProduction: Enumeration;
  dateStatutProduction: string;
  statutTitre: Enumeration;
  dateStatutTitre: string;
  statutDemande: Enumeration;
  dateDebutValidite: string;
  dateFinValidite: string;
  dateFinValiditeReelle: string;
  dateAttribution: string;
  inactive: boolean;
  permanent: boolean;
  motif: string;
  numeroTitre: number;
  commentaire: string;
  traitePNDPhoto: boolean;
  traitePNDTitre: boolean;
}
