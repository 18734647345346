import { Injectable } from '@angular/core';
import { Http } from '@angular/http';
import { Router } from '@angular/router';
import { Subject } from 'rxjs/Subject';
import { Observable } from 'rxjs/Observable';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';

import { Droit } from './droit';
import { Utilisateur } from './utilisateur';
import { PremierConnexion } from './premier-connexion';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';
import 'rxjs/add/operator/toPromise';

@Injectable()
export class AuthentificationService {
	private listeDroits: Subject<Droit[]> = new BehaviorSubject<Droit[]>([]);
	private utilisateur: Subject<Utilisateur> = new BehaviorSubject<Utilisateur>(null);

	constructor(private http: Http, private router: Router) {
		this.initialiserDroits();
	}

	/**
	 * Initialise la connexion à partir du WebToken en session.
	 * À lancer au chargement de l'application.
	 */
	initialiserDroits(): void {
		if (this.hasPayload()) {
			const payload: any = this.getPayload();

			// On va recherche l'utilisateur actuellement connecté
			this.getUtilisateur(payload.sub)
				.then((utilisateur) => {
					this.utilisateur.next(utilisateur);
					this.chargerDroits();
				})
				.catch(() => {
					this.chargerDroitsParDefaut();
				});
		} else {
			this.chargerDroitsParDefaut();
		}
	}

	nouveauMotDePasse(token: string, nouveauMotDePasse: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.http
				.post('/api/authentification/authentification/nouveauMotDePasse', {
					token,
					nouveauMotDePasse,
				})
				.subscribe((response) => {
					if (response.status !== 200) {
						reject('Echec du changement de mot de passe.');
					} else {
						resolve(true);
					}
				});
		});
	}

	validerTokenActif(redirectPageExpiredOnError: boolean): Promise<boolean> {
		console.log('[AuthentificationService] validerTokenActif - Verification token');
		return new Promise((resolve, reject) => {
			this.http.post('/api/authentification/authentification/validateToken', {}).subscribe(
				(response) => {
					if (response.status !== 200) {
						this.expirer();
						reject('Le token est invalide.');
					} else {
						resolve(true);
					}
				},
				() => {
					console.error('[AuthentificationService] validerTokenActif - Le token est invalide');
					sessionStorage.removeItem('token');
					if (redirectPageExpiredOnError) {
						this.expirer();
						this.router.navigate(['/authentification/expired']);
						return resolve(true);
					} else {
						return resolve(false);
					}
				},
			);
		});
	}

	validerTokenPeriodique(): Promise<boolean> {
		const token: string = sessionStorage.getItem('token');
		if (token != null) {
			console.log('[AuthentificationService] validerTokenPeriodique - Token present');
			return this.validerTokenActif(true);
		} else {
			console.log('[AuthentificationService] validerTokenPeriodique - Token absent');
			return new Promise((resolve) => {
				return resolve(true);
			});
		}
	}

	validateToken(token: string): Promise<boolean> {
		// On stocke le token
		sessionStorage.setItem('token', token);

		return this.validerTokenActif(false);
	}

	modifMotDePasse(ancienMotDePasse: string, nouveauMotDePasse: string, confirmMotDePasse: string): Promise<string | boolean> {
		return new Promise((resolve, reject) => {
			this.http
				.post('/api/authentification/authentification/modifMotDePasse', {
					ancienMotDePasse,
					nouveauMotDePasse,
					confirmMotDePasse,
				})
				.subscribe(
					(response) => {
						if (response.status !== 200) {
							reject(response);
						} else {
							if (response['_body'] === 'INVALIDE_ANCIEN_MOT_PASSE') {
								resolve('INVALIDE_ANCIEN_MOT_PASSE');
							} else {
								resolve(true);
							}
						}
					},
					(err) => reject(err),
				);
		});
	}

	loginOublie(adresseEmail: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.http
				.post('/api/authentification/authentification/loginOublie', {
					adresseEmail: adresseEmail,
					profile: 'organisme',
				})
				.subscribe(
					(response) => {
						if (response.status !== 200) {
							reject('Echec de la requête de login oublié.');
						} else {
							resolve(true);
						}
					},
					(err) => {
						console.error('[AuthentificationService] loginOublie - Service authentification indisponible');
						return reject(err);
					},
				);
		});
	}

	motDePasseOublie(identifiant: string): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.http
				.post('/api/authentification/authentification/motDePasseOublie', {
					identifiant: identifiant,
					profile: 'organisme',
				})
				.subscribe(
					(response) => {
						if (response.status !== 200) {
							reject('Echec de la requête de mot de passe oublié.');
						} else {
							resolve(true);
						}
					},
					(err) => {
						console.error('[AuthentificationService] motDePasseOublie - Service authentification indisponible');
						return reject(err);
					},
				);
		});
	}

	/**
	 * Méthode à appeler lors du changement d'informations de la première connexion
	 */
	premiereConnexion(premierConnexion: PremierConnexion): Promise<boolean> {
		console.log('premiereConnexion ', premierConnexion);
		return new Promise((resolve, reject) => {
			this.http.post('/api/authentification/utilisateur', premierConnexion).subscribe(
				(response) => {
					console.log('status premiereConnexion ', response.status);
					if (response.status !== 200) {
						reject('Enregistrement première connexion échoué');
					} else {
						resolve(true);
					}
				},
				(err) => reject(err),
			);
		});
	}

	/**
	 * Methode à appeler pour la connexion d'un utilisateur.
	 */
	connecter(login: string, motDePasse: string): Promise<Utilisateur> {
		return new Promise((resolve, reject) => {
			sessionStorage.removeItem('token');

			this.http
				.post('/api/authentification/authentification/connexion', {
					login: login,
					motDePasse: motDePasse,
					profil: 'organisme',
				})
				.subscribe(
					(response) => {
						if (response.status !== 200) {
							reject('Authentification échouée');
						} else {
							// On récupère le token
							const token: string = response.text();
							const parts: string[] = token.split(/\./g);
							const payload: any = JSON.parse(atob(parts[1]));
							console.log(JSON.parse(atob(parts[1])));

							// On stocke le token
							sessionStorage.setItem('token', token);

							// On va recherche l'utilisateur
							this.getUtilisateur(payload.sub)
								.then((utilisateur) => {
									this.utilisateur.next(utilisateur);
									resolve(utilisateur);
								})
								.catch((erreur) => {
									reject('Authentification échouée - utilisateur inconnu');
								});
						}
					},
					(err) => reject(err),
				);
		});
	}

	getUtilisateur(identifiant: string): Promise<Utilisateur> {
		return new Promise((resolve, reject) => {
			this.http
				.get(`/api/authentification/utilisateur/${identifiant}`)
				.toPromise()
				.then((response) => {
					const user: Utilisateur = response.json() as Utilisateur;
					user.personnePhysique = user.identifiant.startsWith('0');
					user.personneMorale = user.identifiant.startsWith('1');
					resolve(user);
				})
				.catch((erreur) => {
					reject(erreur);
				});
		});
	}

	utilisateurConnecte(): Observable<Utilisateur> {
		return this.utilisateur;
	}

	pushUtilisateurConnecte(utilisateur: Utilisateur): void {
		this.utilisateur.next(utilisateur);
	}

	droit(droit: string): Observable<boolean> {
		const droitTab: string[] = droit.split(',');

		return this.droitsUtilisateur().map((droits) => this.aDroit(droits, droitTab));
	}

	droitsUtilisateur(): Observable<Droit[]> {
		return this.listeDroits;
	}

	aDroit(droits: Droit[], droit: string[]): boolean {
		const ds = droits.filter((d) => droit.indexOf(d.nom) > -1).filter((d) => d.attribue === true);

		if (ds.length === 0) {
			return false;
		}
		return ds[0].attribue;
	}

	public hasPayload(): boolean {
		return !!sessionStorage.getItem('token');
	}

	public getPayload(): any {
		const token: string = sessionStorage.getItem('token');
		if (!token) {
			return {};
		}
		const parts: string[] = token.split(/\./g);
		return JSON.parse(atob(parts[1]));
	}

	getIdentifiantOrganismeUtilisateur(): number {
		if (this.hasPayload()) {
			return this.getPayload().organisme;
		} else {
			return null;
		}
	}

	getDroitSync(droit): boolean {
		for (const d of this.getDroitsSync()) {
			if (d.nom === droit) {
				return d.attribue;
			}
		}
		return false;
	}

	getDroitsSync(): Droit[] {
		const payload: any = this.getPayload();
		const droits: Droit[] = Object.keys(payload)
			.filter((i: string) => i.startsWith('d.'))
			.map((d: string) => d.substr(2))
			.map((nom: string) => {
				const attribue: boolean = payload[`d.${nom}`];
				return {
					nom,
					attribue,
				};
			});
		return droits;
	}

	/**
	 * Charche les droits à partir du token.
	 */
	chargerDroits(): Promise<Droit[]> {
		// Si pas de token
		if (!this.hasPayload()) {
			return this.chargerDroitsParDefaut();
		} else {
			const payload: any = this.getPayload();
			const droits: Droit[] = Object.keys(payload)
				.filter((i: string) => i.startsWith('d.'))
				.map((d: string) => d.substr(2))
				.map((nom: string) => {
					const attribue: boolean = payload[`d.${nom}`];
					return {
						nom,
						attribue,
					};
				});

			this.listeDroits.next(droits);
			return Promise.resolve(droits);
		}
	}

	/**
	 * Charge la liste de droits par défaut, quand l'utilisateur n'est pas connecté.
	 */
	chargerDroitsParDefaut(): Promise<Droit[]> {
		const droitsParDefaut: Droit[] = [
			{
				nom: 'transfert',
				attribue: false,
			},
			{
				nom: 'suivi',
				attribue: false,
			},
			{
				nom: 'administrateur',
				attribue: false,
			},
			{
				nom: 'statistiques',
				attribue: false,
			},
			{
				nom: 'contact',
				attribue: true,
			},
			{
				nom: 'aide',
				attribue: true,
			},
			{
				nom: 'faq',
				attribue: true,
			},
		];

		this.listeDroits.next(droitsParDefaut);

		return Promise.resolve(droitsParDefaut);
	}

	deconnecter(): void {
		sessionStorage.clear();
		localStorage.clear();
		this.utilisateur.next(null);
		this.chargerDroitsParDefaut();
	}

	expirer(): void {
		sessionStorage.clear();
		localStorage.clear();
		this.utilisateur.next(null);
		this.chargerDroitsParDefaut();
		sessionStorage.removeItem('token');
	}
}
