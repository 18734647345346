import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DonneesComponent } from './donnees.component';
import { TelephonePipe } from '../../commons/pipes/telephone.pipe';
import { ProfilService } from '../utilisateurs/profils/profil.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonsModule } from '../../commons/commons.module';
import { ComponentsModule } from '../../components/components.module';

@NgModule({
	declarations: [DonneesComponent, TelephonePipe],
	exports: [DonneesComponent],
	imports: [CommonModule, CommonsModule, FormsModule, ReactiveFormsModule, ComponentsModule],
	providers: [ProfilService],
	bootstrap: [],
})
export class DonneesModule {}
