import {
  AfterContentChecked,
  Component,
  HostBinding,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { Title } from "@angular/platform-browser";
import { ActivatedRoute, NavigationEnd, Router } from "@angular/router";

import { Subscription } from "rxjs/Subscription";
import { TimerObservable } from "rxjs/observable/TimerObservable";

import { environment } from "./../environments/environment";
import { AuthentificationService } from "./authentification/authentification.service";

declare var $: any;

@Component({
  selector: "in-organisme-app",
  template: `
    <div class="container">
      <in-spinner-general role="presentation"></in-spinner-general>
      <in-header role="presentation"></in-header>
      <div id="divfilariane" class="row filariane no-print undisplayable">
        <breadcrumb
          role="presentation"
          [allowBootstrap]="true"
          [addClass]="'breadcrumb'"
        ></breadcrumb>
      </div>
      <router-outlet #routerOutler role="presentation"></router-outlet>
      <in-footer role="presentation"></in-footer>
    </div>
  `,
})
export class OrganismeComponent
  implements OnInit, OnDestroy, AfterContentChecked
{
  @ViewChild("routerOutler") routerOutler;

  @HostBinding("attr.production")
  production = environment.production;
  //setting attribute on : <in-beneficiaire-app role="presentation" ng-version="5.2.11" production="false">

  routerSubscription: Subscription;
  tokenSubscription: Subscription;

  constructor(
    private authentificationService: AuthentificationService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private title: Title
  ) {}

  ngOnInit(): void {
    this.authentificationService.initialiserDroits();

    // Vérification périodique de la validité du token présent en session
    // Toutes les minutes
    const timer = TimerObservable.create(60000, 60000);
    this.tokenSubscription = timer.subscribe((_t) => {
      this.authentificationService.validerTokenPeriodique();
    });

    this.routerSubscription = this.router.events
      .filter(
        (event) =>
          event instanceof NavigationEnd && event.url.split("#").length === 1
      )
      .subscribe((_event) => {
        window.scroll(0, 0);
        $("#liensEvitement").find("a").first().focus().blur();

        // Accessibilite: ajout du role presentation
        const composantActif = $(this.routerOutler.nativeElement).next();
        if (composantActif) {
          composantActif.attr("role", "presentation");
        }
      });

    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => {
        let route = this.activatedRoute.snapshot;

        while (route.firstChild) {
          route = route.firstChild;
        }

        return route.data["breadcrumb"];
      })
      .subscribe((title: string) => {
        if (title) this.title.setTitle(`${title} | ${environment.title}`);
      });
  }

  ngAfterContentChecked(): void {
    this.afficherFilAriane();
  }

  afficherFilAriane(): void {
    const route: string = this.router.url.split("#")[0];
    if (
      !route.endsWith("/authentification") &&
      route !== "/" &&
      !route.endsWith("/authentification/expired")
    ) {
      $("#divfilariane").removeClass("undisplayable");
    } else {
      $("#divfilariane").addClass("undisplayable");
    }
  }

  ngOnDestroy() {
    this.routerSubscription.unsubscribe();
    this.tokenSubscription.unsubscribe();
  }
}
