import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';
import * as moment from 'moment';

/*
 * Méthode de validation.
 */
export function dateAfterValidator(dateAfter: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = <moment.Moment>c.value;
    const e = c.root.get(dateAfter);

    if (e && e.value && v && !v.isAfter(e.value)) {
      return {
        dateAfter: false
      };
    }
    return null;
  };
}

@Directive({
  selector: '[dateAfter][formControlName],[dateAfter][formControl],[dateAfter][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => DateAfterValidator), multi: true }
  ]
})
export class DateAfterValidator implements Validator {
  constructor(@Attribute('dateAfter') public dateAfter: string) {}

  validate(c: AbstractControl): { [key: string]: boolean } {
    return dateAfterValidator(this.dateAfter)(c);
  }
}
