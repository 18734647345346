import { Injectable } from '@angular/core';

@Injectable()
export class ImageService {

  public addPrefixeImage(imagedata: string): string {
    let resultat = null;
    if (imagedata) {
      if (imagedata.startsWith('data:image')) {
        resultat = imagedata;
      } else {
        resultat = 'data:image/jpg;base64,' + imagedata;
      }
    }
    return resultat;
  }

  public verifierDimension(hauteur, largeur) {
    console.log('verification dimension mini image l=413 x H=531 largeur=' + largeur + ' hauteur=' + hauteur);
    return largeur >= 413 && hauteur >= 531;
  }

  public verifierPoidsLeger(file: any): boolean {
    const imageMinWeight = 50 * 1024; // 50 ko
    console.log('verification poids image=' + file.size + ' >= poids min=' + imageMinWeight);
    if (file.size < imageMinWeight) {
      console.log('image trop grande');
      return false;
    }
    return true;
  }

  public verifierPoidsLourd(file: any): boolean {
    const imageMaxWeight = 2 * 1024 * 1024; // 2 Mo
    console.log('verification poids image=' + file.size + ' <= poids max=' + imageMaxWeight);
    if (file.size > imageMaxWeight) {
      console.log('image trop grande');
      return false;
    }
    return true;
  }

  public verifierFormatImage(fileName: string) {
    return fileName.endsWith('jpg') || fileName.endsWith('jpeg') || fileName.endsWith('JPG') || fileName.endsWith('JPEG');
  }

  public getTypeMIME(blob: any): Promise<string> { 
    return new Promise((resolve, reject) => {

      const fileReader = new FileReader();
      fileReader.onloadend = function(e) {
        let type: string = '';
        const arr = (new Uint8Array(fileReader.result)).subarray(0, 4);
        let header = '';
        for (let i = 0; i < arr.length; i++) {
          header += arr[i].toString(16);
        }
        switch (header) {
          case '89504e47':
            type = 'image/png';
            break;
          case '47494638':
            type = 'image/gif';
            break;
          case 'ffd8ffdb':
          case 'ffd8ffe0':
          case 'ffd8ffe1':
          case 'ffd8ffe2':
          case 'ffd8ffe8':
          case 'ffd8ffee':
            type = 'image/jpeg';
            break;
          default:
            type = 'unknown';
            break;
        }
        resolve(type);
      };
      fileReader.readAsArrayBuffer(blob);
    });
  }
}
