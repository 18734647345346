import { Component, OnInit } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";

import { nomDomaineSite } from "../commons/configuration";

@Component({
  selector: "in-accessibilite",
  templateUrl: "./accessibilite.component.html",
})
export class AccessibiliteComponent implements OnInit {
  nomDomaineSite = nomDomaineSite;
  urlDeclarationConformite: string;

  constructor(private router: Router) {}

  ngOnInit() {
    this.router.events
      .filter((event) => event instanceof NavigationEnd)
      .map(() => this.router.routerState.root.firstChild)
      .flatMap((r) => r.data)
      .subscribe((data) => {
        this.urlDeclarationConformite =
          this.router.url.replace(/#.*/, "") + "#declaration-conformite";
      });
  }
}
