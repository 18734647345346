import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/Subject';

export interface SpinnerState {
  show: boolean;
}

@Injectable()
export class SpinnerService {
  private spinnerState = new Subject<SpinnerState>();

  visible = this.spinnerState.asObservable();

show() {
  this.spinnerState.next(<SpinnerState>{show : true});
}

hide() {
  this.spinnerState.next(<SpinnerState>{show : false});
}
}
