import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TransfertRoutingModule } from './transfert.routing';

import { TransfertComponent } from './transfert.component';
import { ResultatControleTransfertComponent } from './resultats/resultat-controle-transfert.component';
import { TransfertService } from './transfert.service';

@NgModule({
	imports: [CommonModule, TransfertRoutingModule],
	declarations: [TransfertComponent, ResultatControleTransfertComponent],
	exports: [TransfertComponent, ResultatControleTransfertComponent],
})
export class TransfertModule {
	static forRoot(): ModuleWithProviders {
		return {
			ngModule: TransfertModule,
			providers: [TransfertService],
		};
	}
}
