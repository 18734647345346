import { NgModule }      from '@angular/core';
import { ContactComponent } from './contact.component';
import { Routes, RouterModule } from '@angular/router';

@NgModule({
  declarations: [ContactComponent],
  imports: [RouterModule],
  exports: [ContactComponent],
  bootstrap: []
})
export class ContactModule {  }
