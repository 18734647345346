import { Injectable } from '@angular/core';
import { Http }       from '@angular/http';
import { Observable } from 'rxjs/Observable';

import { LienAide } from './lienAide';

import 'rxjs/add/operator/map';
import 'rxjs/add/operator/mergeMap';

@Injectable()
export class AideService {

  constructor(private http: Http) {
  }

  liens(): Observable<LienAide[]> {
    console.info(`Recherche des liens de la page d'aide`);
    return this.http.get('/api/lienaides').map(r => r.json()['lienaides'] as LienAide[]);
  }
}
