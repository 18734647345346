import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute, Router } from "@angular/router";

import { SpinnerGeneralService } from "../spinner-general.service";
import { AuthentificationService } from "./authentification.service";

declare var $: any;

@Component({
  selector: "in-authentification",
  templateUrl: "./authentification.component.html",
})
export class AuthentificationComponent implements OnInit {
  loginIncorrect: boolean = false;
  sessionExpiree: boolean = false;
  compteBloque: boolean = false;
  showPassword: boolean = false;
  perteConnexion: boolean = false;
  unknownError: boolean = false;

  authentificationForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private spinner: SpinnerGeneralService
  ) {}

  ngOnInit(): void {
    this.authentificationForm = this.formBuilder.group({
      identifiant: ["", Validators.compose([Validators.required])],
      motDePasse: ["", Validators.required],
    });
    this.showPassword = false;

    this.sessionExpiree = this.route.snapshot.url
      .toString()
      .endsWith("expired");
    if (this.sessionExpiree) {
      this.authentificationService.expirer();
      this.spinner.hide();
    }
    this.authentificationForm.valueChanges.subscribe(
      (data) => (this.sessionExpiree = false)
    );
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();

    if (this.authentificationForm.valid) {
      if (this.authentificationForm.controls["identifiant"].value.length > 49) {
        this.loginIncorrect = true;
      } else {
        this.connecter();
      }
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.authentificationForm.controls) {
        if (typeof control === "string") {
          this.authentificationForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  connecter(): void {
    this.compteBloque = false;
    this.loginIncorrect = false;
    this.perteConnexion = false;
    this.unknownError = false;

    this.authentificationService
      .connecter(
        this.authentificationForm.value["identifiant"],
        this.authentificationForm.value["motDePasse"]
      )
      .then((utilisateur) => {
        console.log(
          "[AuthentificationComponent] Utilisateur connecté",
          utilisateur
        );

        // Premiere connexion
        if (utilisateur.premiereConnexion) {
          this.router.navigate(["/premiereConnexion"]);
        } else if (utilisateur.compteBloque) {
          this.compteBloque = true;
        } else {
          // On charge les droits
          this.authentificationService.chargerDroits().then((droits) => {
            console.log(
              `[AuthentificationComponent] Redirection vers la Home.`
            );
            this.router.navigate(["/"]);
          });
          this.authentificationService.chargerDroits();
        }
      })
      .catch((err) => {
        console.warn(
          "[AuthentificationComponent] Erreur d'authentification",
          err
        );
        if (err.status === 401 && err._body === "COMPTE_BLOQUE") {
          this.compteBloque = true;
        } else if (
          err.status === 401 &&
          err._body === "UTILISATEUR_MOT_PASSE_INCONNU"
        ) {
          this.loginIncorrect = true;
        } else if (!navigator.onLine) {
          this.perteConnexion = true;
        } else {
          this.unknownError = true;
        }
      });
  }

  showModalInfosLoginOublie(): boolean {
    $("#popinInfosLoginOublie").dialog({
      closeText: "Fermer",
      modal: true,
    });
    return false;
  }
  showModalInfosMotDePasseOublie(): boolean {
    $("#popinInfosMotDePasseOublie").dialog({
      closeText: "Fermer",
      modal: true,
    });
    return false;
  }

  toggleShowPassword(): void {
    this.showPassword = !this.showPassword;
  }
}
