import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { Router } from "@angular/router";

import { emailValidator } from "../commons/validators/email.validator";
import { equalsValidator } from "../commons/validators/equals.validator";
import { notEqualsValidator } from "../commons/validators/not-equals.validator";
import { passwordValidator } from "../commons/validators/password.validator";
import { AuthentificationService } from "./authentification.service";
import { PremierConnexion } from "./premier-connexion";
import { Utilisateur } from "./utilisateur";

@Component({
  selector: "in-premiereConnection",
  templateUrl: "./premiere-connexion.component.html",
})
export class PremiereConnexionComponent implements OnInit {
  erreurActivation: boolean = false;
  conflitDetecte: boolean = false;
  nonRespectReglesSecurite: boolean = false;
  motDePasseProvisoireIncorrect: boolean = false;
  showPassword = { tmpPass: false, newPass: false, newPassBis: false };

  public utilisateur: Utilisateur;

  authentificationForm: FormGroup;

  constructor(
    private authentificationService: AuthentificationService,
    private formBuilder: FormBuilder,
    private router: Router
  ) {
    authentificationService
      .utilisateurConnecte()
      .subscribe((u) => (this.utilisateur = u));
  }

  ngOnInit(): void {
    this.authentificationForm = this.formBuilder.group({
      tmpPass: [
        "",
        Validators.compose([Validators.required, Validators.minLength(1)]),
      ],
      newPass: [
        "",
        Validators.compose([
          Validators.required,
          Validators.minLength(12),
          notEqualsValidator("tmpPass"),
          passwordValidator(),
        ]),
      ],
      newPassBis: [
        "",
        Validators.compose([Validators.required, equalsValidator("newPass")]),
      ],
      email: [
        this.utilisateur.adresseMail,
        Validators.compose([
          Validators.required,
          Validators.maxLength(49),
          emailValidator(),
        ]),
      ],
      emailBis: [
        "",
        Validators.compose([Validators.required, equalsValidator("email")]),
      ],
    });

    this.authentificationForm.controls["tmpPass"].valueChanges.subscribe(
      (data) =>
        this.authentificationForm.controls["newPass"].updateValueAndValidity()
    );
    this.authentificationForm.controls["newPass"].valueChanges.subscribe(
      (data) =>
        this.authentificationForm.controls[
          "newPassBis"
        ].updateValueAndValidity()
    );
    this.authentificationForm.controls["email"].valueChanges.subscribe((data) =>
      this.authentificationForm.controls["emailBis"].updateValueAndValidity()
    );
  }

  onSubmit(event) {
    event.stopPropagation();

    if (this.authentificationForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.authentificationForm.controls) {
        if (typeof control === "string") {
          this.authentificationForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  valider(): void {
    const premierConnexion: PremierConnexion = new PremierConnexion();
    premierConnexion.ancienMotDePasse = this.authentificationForm.value.tmpPass;
    premierConnexion.nouveauMotDePasse =
      this.authentificationForm.value.newPass;
    premierConnexion.confirmMotDePasse =
      this.authentificationForm.value.newPassBis;
    premierConnexion.mail = this.authentificationForm.value.email;

    this.authentificationService
      .premiereConnexion(premierConnexion)
      .then((succes) => {
        console.log(
          "[PremiereConnexionComponent] Premiere connexion utilisateur OK"
        );

        // On charge les droits
        this.authentificationService.chargerDroits().then((droits) => {
          console.log(`[PremiereConnexionComponent] Redirection vers la Home.`);
          this.router.navigate(["/"]);
        });
      })
      .catch((reponse) => {
        console.warn(
          "[PremiereConnexionComponent] Erreur lors de la premiere connexion",
          reponse
        );
        if (reponse.status === 400) {
          this.motDePasseProvisoireIncorrect = true;
        } else if (reponse.status === 401) {
          this.nonRespectReglesSecurite = true;
        } else if (reponse.status === 409) {
          this.conflitDetecte = true;
        } else if (reponse.status === 406) {
          this.motDePasseProvisoireIncorrect = true;
        } else {
          this.erreurActivation = true;
        }
      });

    this.authentificationService.chargerDroits();
  }

  toggleShowPassword(password: "tmpPass" | "newPass" | "newPassBis"): void {
    this.showPassword[password] = !this.showPassword[password];
  }
}
