import { NgModule }           from '@angular/core';
import { CommonModule }       from '@angular/common';
import { RouterModule }       from '@angular/router';
import { DatepickerModule }   from 'ngx-bootstrap';

import { HeaderComponent } from './header.component';
import { NavigationComponent } from './navigation.component';
import { FooterComponent } from './footer.component';
import { DatepickerComponent } from './components/datepicker.component';
import { HautDePageComponent } from './components/haut-de-page.component';
import { SpinnerComponent } from './components/spinner.component';
import { TabsComponent } from './components/tabs.component';
import { TabComponent } from './components/tab.component';

import { ValidDateValidator } from './validators/valid-date.validator';
import { DateAfterValidator } from './validators/date-after.validator';
import { DateAfterOrEqualsValidator } from './validators/date-after-or-equals.validator';
import { DateBeforeValidator } from './validators/date-before.validator';
import { DateBeforeOrEqualsValidator } from './validators/date-before-or-equals.validator';
import { EqualsValidator } from './validators/equals.validator';
import { NotEqualsValidator } from './validators/not-equals.validator';
import { PasswordValidator } from './validators/password.validator';
import { EmailValidator } from './validators/email.validator';
import { TelephoneValidator } from './validators/telephone.validator';
import { AdressePostaleValidator } from './validators/adresse-postale.validator';

import { AuthentificationToolsModule } from '../authentification/authentification-tools.module';

import { CsvService } from './csv.service';
import { PropertiesService } from '../commons/properties.service';
import { ImageService } from '../commons/image.service';

import { MomentPipe } from '../commons/pipes/moment.pipe';

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    DatepickerModule,
    AuthentificationToolsModule,
  ],
  declarations: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    DatepickerComponent,
    HautDePageComponent,
    SpinnerComponent,
    ValidDateValidator,
    DateAfterValidator,
    DateBeforeValidator,
    DateAfterOrEqualsValidator,
    DateBeforeOrEqualsValidator,
    EqualsValidator,
    NotEqualsValidator,
    PasswordValidator,
    EmailValidator,
    TelephoneValidator,
    AdressePostaleValidator,
    TabsComponent,
    TabComponent,
    MomentPipe,
  ],
  exports: [
    HeaderComponent,
    NavigationComponent,
    FooterComponent,
    DatepickerComponent,
    HautDePageComponent,
    SpinnerComponent,
    ValidDateValidator,
    DateAfterValidator,
    DateBeforeValidator,
    DateAfterOrEqualsValidator,
    DateBeforeOrEqualsValidator,
    EqualsValidator,
    NotEqualsValidator,
    PasswordValidator,
    EmailValidator,
    TelephoneValidator,
    AdressePostaleValidator,
    TabsComponent,
    TabComponent,
    MomentPipe,
  ],
  providers: [
    CsvService,
    PropertiesService,
    ImageService,
    MomentPipe,
  ]
})
export class CommonsModule { }
