import { CommonModule } from "@angular/common";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";

import { ComponentsModule } from "../components/components.module";

import { AuthentificationComponent } from "./authentification.component";
import { AuthentificationService } from "./authentification.service";
import { LoginOublieComponent } from "./login-oublie.component";
import { MotDePasseOublieComponent } from "./mot-de-passe-oublie.component";
import { NouveauMotPasseComponent } from "./nouveau-mot-passe.component";
import { PremiereConnexionComponent } from "./premiere-connexion.component";

import { CanActivateADroitGuard } from "./a-droit.guard";

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
  ],
  declarations: [
    AuthentificationComponent,
    PremiereConnexionComponent,
    NouveauMotPasseComponent,
    LoginOublieComponent,
    MotDePasseOublieComponent,
  ],
  exports: [
    AuthentificationComponent,
    PremiereConnexionComponent,
    NouveauMotPasseComponent,
    LoginOublieComponent,
    MotDePasseOublieComponent,
  ],
  providers: [AuthentificationService, CanActivateADroitGuard],
})
export class AuthentificationModule {}
