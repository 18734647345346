import { Pipe, PipeTransform } from '@angular/core';
/*
 * Formatte un numéro de téléphone sur 10 caractères numérique
 * Complète avec un séparateur point pour grouper par 2 digits.
 * Usage:
 *   value | telephone
 * Example:
 *   {{ 345678901 | telephone }}
 *   formats to: 03.45.67.89.01
*/

@Pipe({name: 'telephone'})
export class TelephonePipe implements PipeTransform {
  transform(value: string): string {
    if (value === null || value === undefined || value === '0' || value === '') {
      return '';
    }
    let numero = '' + value;
    if (numero.length < 10) {
      numero = '0' + numero;
    }
    if (numero.length !== 10) {
      return numero;
    }
    const telephone = numero.slice(0, 2) + '.' + numero.slice(2, 4) + '.' +
     numero.slice(4, 6) + '.' + numero.slice(6, 8) + '.' + numero.slice(8, 10);
    return telephone;
  }
}
