import { NgModule }      from '@angular/core';
import { CommonModule }  from '@angular/common';
import { TabsModule } from 'ngx-bootstrap';
import { CommonsModule } from '../commons/commons.module';

import { FaqComponent } from './faq.component';
import { FaqService } from './faq.service';

@NgModule({
  declarations: [
    FaqComponent,
  ],
  exports: [
    FaqComponent,
  ],
  imports: [
    CommonModule,
    CommonsModule,
    TabsModule.forRoot(),
  ], 
  providers: [FaqService]
})
export class FaqModule {  }
