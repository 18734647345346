import { Injectable } from '@angular/core';
import { Http, URLSearchParams, ResponseContentType } from '@angular/http';

import * as moment from 'moment';

import { StatNationale } from './dto/stat-nationale';

@Injectable()
export class StatistiquesNationalesService {

  constructor(private http: Http) {
  }

  /**
  * Permet de récupérer la liste des rapports annuels et mensuels de statistiques nationales disponibles
  */
  disponibilite(): Promise<StatNationale[]> {
    console.log('[StatistiquesNationalesService] disponibilite');

    const parameters: URLSearchParams = new URLSearchParams();
    return this.http.get(`/api/cmi-statistics/stats/available`, {
        search: parameters,
      }).toPromise()
        .then(response => response.json() as StatNationale[]);
  }

  /**
  * Méthode à appeler pour récupèrer un rapport de statistiques nationales
  */
  getRapport(annee: string, mois: string, type: string, frequence: string) {
    console.log('[StatistiquesNationalesService] getRapport', annee, mois, type, frequence);
    const parameters: URLSearchParams = new URLSearchParams();
    if (annee) {
      parameters.set('annee', annee);
    }
    if (mois) {
      parameters.set('mois', mois);
    }
    if (type) {
      parameters.set('type', type);
    }
    if (frequence) {
      parameters.set('frequence', frequence);
    }
    return this.http.get(`/api/cmi-statistics/stats/download`, {
        search: parameters,
        responseType: ResponseContentType.Blob
      }).toPromise();
  }
}
