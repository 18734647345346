import { Injectable } from '@angular/core';

import { Subject } from 'rxjs/Subject';

export interface SpinnerGeneralState {
  show: boolean;
}

@Injectable()
export class SpinnerGeneralService {
  private spinnerGeneralState = new Subject<SpinnerGeneralState>();

  visible = this.spinnerGeneralState.asObservable();

show() {
  this.spinnerGeneralState.next(<SpinnerGeneralState>{show : true});
}

hide() {
  this.spinnerGeneralState.next(<SpinnerGeneralState>{show : false});
}
}
