import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

/*
 * Méthode de validation.
 */
export function notEqualsValidator(notequals: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    const e = c.root.get(notequals);

    if (e && v === e.value && !!v && !!e.value) {
      return {
        validateNotEqual: true,
      };
    }
    return null;
  };
}

@Directive({
  selector: '[notequals][formControlName],[notequals][formControl],[notequals][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => NotEqualsValidator), multi: true }
  ]
})
export class NotEqualsValidator implements Validator {
  constructor( @Attribute('notequals') public notequals: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return notEqualsValidator(this.notequals)(c);
  }
}
