import { Directive, forwardRef, Attribute } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

/*
 * Méthode de validation.
 */
export function equalsValidator(equals: string): ValidatorFn {
  return function(c: AbstractControl): { [key: string]: boolean } {
    const v = c.value;
    const e = c.root.get(equals);

    if (e && v !== e.value && !!v && !!e.value) {
      return {
        validateEqual: false,
      };
    }
    return null;
  };
}

@Directive({
  selector: '[equals][formControlName],[equals][formControl],[equals][ngModel]',
  providers: [
    { provide: NG_VALIDATORS, useExisting: forwardRef(() => EqualsValidator), multi: true }
  ]
})
export class EqualsValidator implements Validator {
  constructor( @Attribute('equals') public equals: string) { }

  validate(c: AbstractControl): { [key: string]: boolean } {
    return equalsValidator(this.equals)(c);
  }
}
