import { Component, OnInit } from "@angular/core";
import { FormArray, FormControl, FormGroup, Validators } from "@angular/forms";
import { ActivatedRoute } from "@angular/router";

import { BreadcrumbsService } from "ng2-breadcrumbs";
import { listeDroits } from "../../../commons/configuration";
import { Groupe } from "./groupe";
import { GroupeService } from "./groupe.service";

@Component({
  selector: "in-creer-modifier-groupe",
  templateUrl: "./creer-modifier-groupe.component.html",
})
export class CreerModifierGroupeComponent implements OnInit {
  listeDroits = listeDroits;
  public groupe: Groupe;

  public modeCreation: boolean = true;
  public soumis: boolean = false;
  public succes: boolean = true;
  public groupeExiste: boolean = false;

  public creerModifierGroupeForm: FormGroup;
  fonctionBoxArray: FormArray;

  constructor(
    private route: ActivatedRoute,
    private breadcrumbService: BreadcrumbsService,
    private groupeService: GroupeService
  ) {}

  ngOnInit(): void {
    const numeroGroupe = this.route.snapshot.params["id"];

    this.modeCreation = !numeroGroupe;

    // Create a custom Validator function for the fonctionnalites array
    function hasFonctionnalites(formArray: FormArray) {
      let valid = false;
      for (const c in formArray.controls) {
        if (formArray.controls[c].value === true) {
          valid = true;
        }
      }
      return valid === true ? null : { noFonctions: true };
    }

    // Par défaut en mode création, nom du groupe vide, et aucune fonctionnalité cochée
    this.fonctionBoxArray = new FormArray([], hasFonctionnalites);
    this.listeDroits.forEach((d) => {
      if (d.visible) {
        this.fonctionBoxArray.push(new FormControl(false));
      }
    });
    this.creerModifierGroupeForm = new FormGroup({
      nom: new FormControl(
        "",
        Validators.compose([Validators.required, Validators.maxLength(49)])
      ),
      fonctionnalites: this.fonctionBoxArray,
    });

    if (!this.modeCreation) {
      this.groupeService.detailGroupe(numeroGroupe).subscribe((groupe) => {
        this.groupe = groupe;

        const title = this.getNameForGroupe(groupe);
        this.breadcrumbService.store([
          { label: "Administration", url: "/administrateur", params: [] },
          {
            label: "Gestion des groupes",
            url: "/administrateur/groupes",
            params: [],
          },
          { label: title, url: "/administrateur/groupes/[0-9]*", params: [] },
        ]);

        this.fonctionBoxArray = new FormArray([], hasFonctionnalites);
        this.listeDroits.forEach((d) => {
          if (d.visible) {
            this.fonctionBoxArray.push(new FormControl(this.adroit(d.code)));
          }
        });
        console.log(
          "[creerModifierGroupeForm] mode modif ",
          this.groupe.nom,
          this.fonctionBoxArray.value
        );

        this.creerModifierGroupeForm = new FormGroup({
          nom: new FormControl(
            this.groupe.nom,
            Validators.compose([Validators.required, Validators.maxLength(49)])
          ),
          fonctionnalites: this.fonctionBoxArray,
        });
        this.creerModifierGroupeForm.markAsPristine();
      });
    }
  }

  onSubmit(event: MouseEvent) {
    event.stopPropagation();

    if (this.creerModifierGroupeForm.valid) {
      this.valider();
    } else {
      // Pour afficher les messages d'erreur en cas de formulaire vide
      for (const control in this.creerModifierGroupeForm.controls) {
        if (typeof control === "string") {
          this.creerModifierGroupeForm.controls[control].markAsTouched();
        }
      }
    }

    return false;
  }

  atteindreMessages() {
    const element = document.querySelector("#messages");
    element.scrollIntoView(true);
  }

  valider(): void {
    if (!this.groupe) {
      this.groupe = new Groupe();
    }
    const newDroits = new Array<string>();
    for (let i = 0; i < this.listeDroits.length; i++) {
      if (
        this.creerModifierGroupeForm.controls["fonctionnalites"].value[i] ===
          true ||
        (!this.listeDroits[i].defaut && !this.listeDroits[i].visible)
      ) {
        newDroits.push(this.listeDroits[i].code);
      }
    }
    this.groupe.nom = this.creerModifierGroupeForm.controls["nom"].value;
    this.groupe.droits = newDroits;

    if (this.modeCreation) {
      this.groupeService
        .createGroupe(this.groupe)
        .then((succes) => {
          this.soumis = true;
          this.succes = succes;
          this.atteindreMessages();
        })
        .catch((err) => {
          this.soumis = true;
          this.succes = false;
          if (err.status === 409 && err["_body"] === "GROUPE_EXISTE_DEJA") {
            this.groupeExiste = true;
          } else {
            this.groupeExiste = false;
          }
          this.atteindreMessages();
        });
    } else {
      this.groupeService
        .modifyGroupe(this.groupe)
        .then((succes) => {
          this.soumis = true;
          this.succes = succes;
          this.atteindreMessages();
        })
        .catch((err) => {
          this.soumis = true;
          this.succes = false;
          if (err.status === 409 && err["_body"] === "GROUPE_EXISTE_DEJA") {
            this.groupeExiste = true;
          } else {
            this.groupeExiste = false;
          }
          this.atteindreMessages();
        });
    }
  }

  private getNameForGroupe(groupe: Groupe): string {
    return "Modification du groupe " + groupe.nom;
  }

  public tout(coche: boolean): void {
    const newValeur: boolean[] = [];
    for (let i = 0; i < this.listeDroits.length; i++) {
      if (this.listeDroits[i].visible) {
        newValeur[i] = coche;
      }
    }
    this.creerModifierGroupeForm.controls["fonctionnalites"].setValue(
      newValeur
    );
    this.creerModifierGroupeForm.controls["fonctionnalites"].markAsPristine();
  }

  adroit(code: string): boolean {
    if (!this.groupe) {
      return false;
    }
    const gs = this.groupe.droits.filter(
      (d) => code.toLowerCase().indexOf(d.toLowerCase()) > -1
    );
    if (gs.length > 0) {
      return true;
    }
    return false;
  }
}
