import { OnInit, OnDestroy, AfterViewChecked, AfterViewInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { dataTablesOptions } from '../../commons/configuration';
import { Subject } from 'rxjs/Subject';

declare var $: any;

export abstract class ListeFiltreeComponent implements OnInit, OnDestroy, AfterViewChecked, AfterViewInit {
	public filtreVisible: boolean = true;
	public erreurFiltreVide: boolean = false;
	public filtreRequis: boolean = false;
	public filtresForm: FormGroup;
	public dataTable: DataTables.DataTables = null;
	public optionsListeFiltree: DataTables.Settings = null;
	dtTrigger: Subject<any> = new Subject();

	@ViewChild('resultatsRecherche') public table: ElementRef;
	abstract initialiserFiltre(): void;
	abstract initialiserColumnsOrder(): void;
	abstract lancerRecherche(): void;
	abstract extraireDonnees(e, settings, json): void;

	public ngOnInit(): void {
		this.initialiserFiltre();
		this.initOptions();
		this.initialiserColumnsOrder();
	}

	public onPreDrawCallback(settings) {
		// This is intentional
	}

	public onRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull) {
		// This is intentional
	}

	public onDrawCallback(settings) {
		// This is intentional
	}

	public initOptions() {
		const that = this;
		this.optionsListeFiltree = Object.assign({}, dataTablesOptions);

		this.optionsListeFiltree['fnRowCallback'] = function (nRow, aData, iDisplayIndex, iDisplayIndexFull) {
			that.onRowCallback(nRow, aData, iDisplayIndex, iDisplayIndexFull);
		};
		this.optionsListeFiltree['fnPreDrawCallback'] = function (settings) {
			that.onPreDrawCallback(settings);
		};
	}

	/**
	 * Vérifie que le filtre n'est pas rempli.
	 */
	public isFiltreEmpty(): boolean {
		for (const filtre in this.filtresForm.value) {
			if (this.filtresForm.value[filtre]) {
				return false;
			}
		}
		return true;
	}

	public ngAfterViewInit(): void {
		console.log(`[ListeFiltreeComponent] Initialisation de la vue, on lance la recherche.`);

		$(this.table.nativeElement).on('xhr.dt', (e, s, j) => {
			this.extraireDonnees(e, s, j);
		});
		$(this.table.nativeElement).on('draw.dt', (e, s, j) => {
			//this.dataTable.rows('.toDelete').remove();
			$('.toDelete').remove();
		});
	}

	public ngAfterViewChecked(): void {
		if (!this.dataTable) {
			console.log(`[ListeFiltreeComponent] On met à jour la table.`);
			// Initialisation d'un composant jQuery
			this.dataTable = $('#resultatsRecherche').DataTable(this.optionsListeFiltree);

			// Parcours les liens desactives pour forcer la non-tabulation
			console.log(`[ListeFiltreeComponent] On ne tabule pas sur les elements disabled de la table.`);
			setTimeout(() => this.forcerTabulation(), 400);
		}
	}

	public getFiltreKey(): string {
		return `filtres.${this.constructor.name}`;
	}

	public getFiltreValues(): any {
		return JSON.parse(sessionStorage.getItem(this.getFiltreKey()));
	}

	public rechercher(event: MouseEvent): boolean {
		event.stopPropagation();

		if (this.filtresForm.valid) {
			sessionStorage.setItem(this.getFiltreKey(), JSON.stringify(this.filtresForm.value));
			this.dataTable.state.clear();
			this.lancerRecherche();
			if (this.filtresForm.valid) {
				this.filtreVisible = false;
			}
		}

		return false;
	}

	/*
	 * Réinitialise le tableau, quand les données ont entièrement changé.
	 */
	public reinitDataTable(): void {
		// Il faut mettre à jour la table, donc on détruit
		if (this.dataTable) {
			this.dataTable.destroy();
			this.dataTable = null;
		}
	}

	/*
	 * Rafraichi le tableau, quand des données filtrées ont changée (changements mineurs).
	 */
	public refreshDataTableSorts(): void {
		// Mettre à jour les tris
		this.dtTrigger.next();
		// setTimeout(() => this.dataTable.rows().invalidate(), 100);
	}

	public forcerTabulation(): void {
		const pagination_links = $('#resultatsRecherche_paginate').find('a');
		$(pagination_links).each(function () {
			if ($(this).hasClass('disabled')) {
				$(this).attr('tabindex', -1);
			}
		});
	}

	public ngOnDestroy(): void {
		console.log(`[ListeFiltreeComponent] Destroy du composant liste filtree.`);
		this.reinitDataTable();
	}
}
