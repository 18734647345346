import { NgModule }      from '@angular/core';
import { CommonModule }       from '@angular/common';
import { PlanComponent } from './plan.component';
import { RouterModule }       from '@angular/router';
import { AuthentificationToolsModule } from '../authentification/authentification-tools.module';

@NgModule({
  declarations: [PlanComponent],
  exports: [PlanComponent],
  imports: [
    CommonModule,
    RouterModule,
    AuthentificationToolsModule
  ],
  bootstrap: []
})
export class PlanModule {  }
